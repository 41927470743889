import React, { useState } from "react";
import './ButtonWhatsapp.styles.css';
import { FaWhatsapp } from 'react-icons/fa';

export const ButtonWhatsapp = () => {

    const link = "https://api.whatsapp.com/send?phone=56934373912&text=Hola%20estoy%20comprando%20en%20CinesolQuilpue%20web%20y%20los%20contacto%20por%20lo%20siguiente,%20les%20cuento:";

    return (
        <a
            href={link}
            className="floating-button"
            target="_blank"
            rel="noopener noreferrer"
        >
            <FaWhatsapp size={30} color="#fff" />
        </a>
    )
}