import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";
import "./Billete.styles.css";
import { User } from "../../components/User";
import { api } from "../../services/api";
export const Billete = () => {
  const history = useHistory();
  const [filme, setFilme] = useState({});
  const [ingresso, setIngresso] = useState({});
  const [ingressoGerado, setIngressoGerado] = useState(0);
  const [letras, setLetras] = useState([
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "x",
    "z",
    "a1",
    "b1",
    "c1",
    "d1",
    "e1",
    "f1",
    "j1",
    "k1",
    "l1",
    "m1",
    "n1",
    "o1",
    "p1",
    "q1",
    "r1",
    "s1",
    "t1",
    "u1",
    "v1",
    "w1",
    "y1",
    "x1",
    "z1",
  ]);

  const finalizarCompra = function () {
    let ingresso = JSON.parse(localStorage.ingresso);

    let token = localStorage.token;
    if (!ingresso || !ingresso.horario_id) {
      history.push('"/"');
    }

    ingresso.formas_pagamento = JSON.parse(localStorage.formasPagamento);

    // if (JSON.parse(localStorage.formasPagamento).length === 0)
    //   ingresso.formas_pagamento = [
    //     { forma_pagamento: "Cupon", valor: "0", troco: 0 },
    //   ];

    api
      .post("/v1/ingresso/finalizar-compra", ingresso, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let params = new URLSearchParams(window.location.search);
        history.push("/finalizado?id=" + params.get("id"));
      })
      .catch(function (response) {
        let params = new URLSearchParams(window.location.search);
        history.push("/finalizado?id=" + params.get("id"));
        // alert('Erro inesperado')
        // window.localtion.reload();
      });
  };

  async function getFilme() {
    let params = new URLSearchParams(window.location.search);

    const response = await api.get("/filme/pesquisar-info/" + params.get("id"));

    setFilme(response.data.data);
  }

  const handleCancelar = (e) => {
    e.preventDefault();

    let ingressosIds = [];

    let ingressos = JSON.parse(localStorage.ingressos);

    ingressos.map((ingresso) => {
      ingressosIds.push(ingresso.id);
    });

    let token = localStorage.token;

    api
      .post(
        "/v1/ingresso/cancelar-ingressos",
        { ingressos: ingressosIds },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        let params = new URLSearchParams(window.location.search);
        localStorage.removeItem("ingressos");
        localStorage.removeItem("ingresso");
        history.push("/pelicula?id=" + params.get("id"));
      })
      .catch(function (response) {});
  };

  useEffect(() => {
    getFilme();

    let ingresso = JSON.parse(localStorage.ingresso);
    setIngresso(ingresso);
  }, []);

  useEffect(() => {
    //(ingresso);
  }, [ingresso]);

  return (
    <div className="container-home">
      <div className="container-capa">
        <img src={Photocapa} alt="photo-capa" className="photo-capa" />
        <User />
      </div>
      <Navbar />
      <div className="container-category-filme">
        <div className="container-pai">
          <div className="container-pai-filme">
            <div className="detalhes-filme">
              <img
                src={filme.link_img_capa}
                alt="foto=capa"
                className="img-capa"
              />
              <div className="infos-filme-carteira">
                <h2 className="title-sinopse">{filme.titulo}</h2>
                <p>2019 - Ação | Fantasia</p>
                <p>Diretor: {filme.diretor}</p>
                <p>
                  {" "}
                  <AiFillStar className="iconStar" />
                  7.5 (85,741)
                </p>
                <h6>
                  We all have a superhero inside us, it just takes a bit of
                  magic to bring it out. In Billy Batson's case, by shouting out
                  one word - SHAZAM. - this streetwise fourteen-year-old foster
                  kid can turn into the grown-up superhero Shazam
                </h6>
              </div>
            </div>
            <div className="informacoes-detalhes">
              <div className="detalhes-filme-direita">
                <div className="container-main-sesion">
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>01</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>02</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>03</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="main-bolinha1">
                      <span>04</span>
                    </div>
                    <hr className="container-line" />
                  </div>
                  <div className="container-sesion">
                    <div className="bolinha1">
                      <span>05</span>
                    </div>
                  </div>
                </div>
                <div className="description-bolinha">
                  <p>Selección</p>
                  <p>Butacas</p>
                  <p>Pago</p>
                  <p>Ticket</p>
                  <p>Finalizado</p>
                </div>
              </div>

              <div className="content-header-payment">
                <h1 className="title-billete">
                  Detalles de la entrada de cine
                </h1>

                <div className="description-first">
                  <p className="subtitle-first-text-billete">Sala</p>
                  {/* <p className="subtitle-first-text">{ingresso.horario.sala.nome}</p> */}
                </div>
                <div className="description-first">
                  <p className="subtitle-first-text-billete">Película</p>
                  <p className="subtitle-first-text">{filme.titulo}</p>
                </div>
                <div className="description-first">
                  <p className="subtitle-first-text-billete">Hora</p>
                  <p className="subtitle-first-text">
                    {ingresso.horario ? ingresso.horario.horario : ""}
                  </p>
                </div>
                <div className="description-first">
                  <p className="subtitle-first-text-billete">Sesión</p>
                  <p className="subtitle-first-text">
                    {ingresso.horario ? ingresso.horario.qualidade : ""}
                  </p>
                </div>
                <div className="description-first">
                  <p className="subtitle-first-text-billete">Butacas</p>
                  <div className="assentos-items">
                    {ingresso.assentos ? (
                      ingresso.assentos.map((assento) => (
                        <div className="assento-item">
                          {assento.assento_nome}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="footer-button-billete">
                  <button onClick={handleCancelar} className="button-cancel">
                    <span>Volver</span>
                  </button>
                  <button onClick={finalizarCompra} className="button-continue">
                    <span>Finalizar</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
