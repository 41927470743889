import React, { useEffect, useState } from "react";
// Componnets

import { ButtonSignIn } from '../../components/Forms/Button';


import { MdMarkunread } from 'react-icons/md';
import { FaAsterisk } from 'react-icons/fa';

import { api } from '../../services/api';

import './Signin.styles.css';
import logo from '../../assets/logo.png';

export const LoginModal = (props) => {


  const [messageError, setMessageError] = useState(false);

  const [userData, setUserData] = useState({});

  const handleLogin = (e) => {
    e.preventDefault();

    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const login = event => {

    event.preventDefault();

    api.post('login', userData).then((response) => {

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
      setMessageError(false);
      props.turnModal();

    }).catch(function () {

      setMessageError(true);
    })
  }

  useEffect(() => {
  }, []);

  return (
    <> <div style={{ marginTop: '-8vh' }}>
      <div className="logo-div">
        <img src={logo} alt="imagen-background" className="image-logo" />
      </div>

      <div className="container-modal">
        <h1 className="title-bemvindo">Bienvenidos a Cinesol</h1>
        <p className="subtitle" style={{ color: 'white' }}>Ingrese su nombre de usuario y contraseña <span style={{ color: 'white' }} >para acceder al sistema</span></p>

        {messageError && <p className="subtitle-error" style={{ color: "white" }}>Úsuario o contraseña inválidas</p>}

        <form className="form" onSubmit={login}>
          {/* <InputForm /> */}

          <div className="container-input">
            <input type="text" name="email" placeholder=" Usuario" className="Input-forms" onChange={handleLogin} />
            <MdMarkunread color="#CECECE" className="icon-message" />
            <input type="password" name="password" placeholder=" Contraseña" className="Input-forms" onChange={handleLogin} />
            <FaAsterisk color="#CECECE" className="icon-asteriks" />
          </div>
          <div className="button" >
            < ButtonSignIn title="Entrar" />
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export const LoginModalTelefone = (props) => {


  const [messageError, setMessageError] = useState(false);

  const [userData, setUserData] = useState({});

  const handleLogin = (e) => {
    e.preventDefault();

    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const login = event => {

    event.preventDefault();

    api.post('login', userData).then((response) => {

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
      setMessageError(false);
      props.turnModal();

    }).catch(function () {

      setMessageError(true);
    })
  }

  useEffect(() => {
  }, []);

  return (
    <> <div>
      <div className="logo-div">
        <img src={logo} alt="imagen-background" className="image-logo" />
      </div>

      <div className="container-modal" style={{ width: '70vw', backgroundColor: 'transparent', boxShadow: 'none' }} >
        <h1 className="title-bemvindo" style={{ width: '70vw' }}>Bienvenidos a Cinesol</h1>
        <p className="subtitle" style={{ color: 'white', width: '70vw' }} >Ingrese su nombre de usuario y contraseña <span style={{ color: 'white' }} >para acceder al sistema</span></p>

        {messageError && <p className="subtitle-error" style={{ color: "white", width: '70vw' }}>Úsuario o contraseña inválidas</p>}

        <form className="form" onSubmit={login} style={{ width: '70vw' }} >
          {/* <InputForm /> */}

          <div className="container-input" style={{ width: '70vw' }} >
            <input type="text" name="email" placeholder=" Usuario" className="Input-forms" onChange={handleLogin} style={{ width: '70vw' }} />
            <MdMarkunread color="#CECECE" className="icon-message" />
            <input type="password" name="password" placeholder=" Contraseña" className="Input-forms" onChange={handleLogin} style={{ width: '70vw' }} />
            <FaAsterisk color="#CECECE" className="icon-asteriks" />
          </div>
          <div className="button" >
            < ButtonSignIn title="Entrar" />
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

