import { Component } from "react";
import { MobileView } from "react-device-detect";
import { api } from "../../services/api";

export default class FinallyApp extends Component {

  constructor(props) {
    super(props)
    this.urlApi = "https://api-cinesol.busticket.cl/";
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    switch (Number(params.get('item'))) {
      case 1:
        this.setResponseFilme(params);
        break;
      case 2:
        this.setResponseEvent(params);
        break;
      case 3:
        this.setResponseCupon(params);
        break;
      default:
        break;
    }
  }

  async setResponseFilme(params) {
    let respVerify
    if (params.get('token_ws') !== undefined) {
      respVerify = await api.get("/app/webpay-transbank-confirm/" + params.get('token_ws'));
    } else {
      respVerify = await api.get("/webpay-transbank-oneclick/status/" + params.get('TBK_ORDEN_COMPRA'));
    }

    if (respVerify.data?.status === 1) {
      await api.post('/app/webpay-transbank-confirmar-pagamento/' + params.get('venda_id'))
      let dataMail = {
        link: this.urlApi + "ingresso/",
        venda_id: params.get('venda_id'),
        transbank: respVerify.data.dados,
      };
      await api.post('/web/mail-send-ticket', dataMail)

    } else {
      await api.get('/ingresso/descongelar_ingresos/' + params.get('venda_id'));
    }
  }

  async setResponseEvent(params) {
    const respVerify = await api.get('app/webpay-transbank-confirmar-pagamento-evento/' + params.get('token_ws') + '/' + params.get('TBK_ORDEN_COMPRA'))
    if (respVerify.data?.webpay?.status === 1) {
      await api.get('/web/mail-send-ticket-evento/' + params.get('token_ws') + '/' + params.get('TBK_ORDEN_COMPRA'))
    }
  }

  async setResponseCupon(params) {
    const respVerify = await api.get('/webpay-transbank-confirmar-pagamento-cupon/' + params.get('token_ws') + '/' + params.get('TBK_ORDEN_COMPRA'))
    if (respVerify.data?.webpay?.status === 1) {
      await api.get('/web/mail-send-cupon/' + params.get('token_ws') + '/' + params.get('TBK_ORDEN_COMPRA'))
    }
  }

  render() {
    return (
      <MobileView style={{ height: '100%' }}>
        <center>
          <div style={{
            width: '60%',
            justifyContent: 'center',
            marginTop: '50%',
            fontFamily: 'Poppins',
          }}>
            <h4 style={{ color: 'white' }}>Ahora debes volver a la aplicación de Cinesol</h4>
          </div>
        </center>
      </MobileView>
    );
  }
}
