import React, { useEffect, useState, useRef } from "react";
import { Navbar } from '../../components/Navbar';
import Photocapa from "../../assets/photo-capa.png";
import { User } from "../../components/User";
import './Ingresso.styles.css';
import QrReader from 'react-qr-reader';

const breakPoints = [
    { width: 2, itemsToShow: 1, }
];

export const Ingresso = () => {

    let params = new URLSearchParams(window.location.search);

    return (
        <div className="teste">

            <iframe src={"https://cinesol-homologacao.busticket.cl/" / "/ingresso/" + params.get('vid')} frameborder="0" style={{ overflow: 'hidden', height: '100%', width: '100%' }} />
        </div>
    );
};