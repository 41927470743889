import { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import "./Cupon.styles.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import background from "../../assets/background.png";

import { BrowserView, MobileView } from "react-device-detect";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { Box as Bx, Layer, Spinner, Text } from "grommet";

import { api } from "../../services/api";
import Stepper from "../../components/Stepper";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { NavigateNext, ArrowBackIos } from '@material-ui/icons';
import { Typography } from "@mui/material";


export const Cupon = () => {
    const { cuponId } = useParams();

    const [nome, setNome] = useState("");
    const [apelido, setApelido] = useState("");
    const [email, setEmail] = useState("");
    const [emailC, setEmailC] = useState("");
    const [tipoDocumento, setTipoDocumento] = useState("rut");
    const [documento, setDocumento] = useState("");
    const [documentoValido, setDocValid] = useState(false);
    const [loadingPay, setLP] = useState(false);
    const [total, setTotal] = useState(0);
    const [cantCupones, setCantCupones] = useState(1);
    const [url_wp, setUrl] = useState("");
    const [token_wp, setToken] = useState("");
    const [cupon, setCupon] = useState({});
    const [termos, setTermos] = useState(false);

    const history = useHistory();

    const styleboxfilme = {
        display: "flex",
        paddingTop: '4%',
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        backgroundImage: `url(${background})`,
        height: "110%",
    };

    const srow = {
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
    };

    const scolumn = {
        display: "flex",
        flexDirection: "column",
        marginRight: "2%",
    };

    const TermosCon = () => {
        return (
            <Stack direction="row" style={{ justifyContent: "center", alignItems: "center", marginTop: "10px", }} >
                <IOSSwitch sx={{ m: 1 }} onChange={() => setTermos(!termos)} />
                <p style={{ color: "white", fontSize: "0.8em" }}>
                    Para procesar este pago, declaro que he revisado el DETALLE DE LA
                    COMPRA, y que he leído y acepto los Términos y Condiciones, así como
                    la Política de Protección de Datos Personales.{" "}
                </p>
            </Stack>
        );
    };

    const IOSSwitch = styled((props) => (
        <Switch checked={termos} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor:
                        theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 22,
            height: 22,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    const validarDocumento = (e, form = true) => {
        let data = form ? e.target.value : e;
        if (data) {
            if (true /* validator.isPassportNumber(e.target.value, "IN") || validateRUT(e.target.value) */) {
                setDocValid(true);
            } else {
                alert("Erro Rut / Pasaporte invalido");
                setDocValid(false);
            }
        } else {
            setDocValid(false);
        }
    };

    async function getCupon() {
        const response = await api.get("/cupon/pesquisar/" + cuponId);
        setCupon(response.data.data);
    }

    useEffect(() => {
        if (url_wp && token_wp) {
            document.formulario.submit();
        } else {
            getCupon();
        }
    }, [url_wp, token_wp]);

    const handleCancelar = (e) => {
        history.push("");
    };

    async function handleNavigation(e) {
        try {
            e.preventDefault();
            setTotal(cupon.valor_venta * cantCupones)
            await validarDocumento(documento, false)
            setLP(true);

            let token = localStorage.token;

            if (!documentoValido) {
                setLP(false);
                alert("Documento inválido.");
            } else if (email !== emailC) {
                setLP(false);
                alert("Email de confirmación no coincide.");
            } else {
                if (total > 0) {

                    let dataVenta = {
                        valor_total: parseInt(total),
                        cantidad: cantCupones,
                        promocao_id: cuponId,
                        origem: 'online'
                    }

                    let dataTransbank = {
                        amount: parseInt(total) + 600,
                        url: "https://webcinesol.busticket.cl/cupon-finalizado?id=" + cuponId + "/",
                        nome: nome,
                        email: email,
                        sobrenome: apelido,
                        documento: documento,
                        venda_id: null,
                        venda_valor: parseInt(total),
                    };

                    await api.post('/venta-cupon', dataVenta, {
                        headers: { Authorization: "Bearer " + token }
                    }).then(async (resp) => {
                        if (resp.data.success) {
                            dataTransbank.venda_id = resp.data.venda_id;
                            webpayTransbank(dataTransbank, token)
                        } else {
                            setLP(false);
                            localStorage.clear();
                            alert(resp.data.msj);
                            history.push('');
                        }

                    }).catch(async (error) => {
                        console.error(error)
                        if (!error.response.data.success) {
                            setLP(false);
                            localStorage.clear();
                            alert(error.response.data.msj);
                            history.push('');
                        }
                    })

                } else {
                    setLP(false);
                }
            }
        } catch (error) {
            console.error(error)
        }

    }

    async function webpayTransbank(dataTransbank, token) {
        api.post("/webpay-transbank-cupon", dataTransbank, {
            headers: { Authorization: "Bearer " + token }
        }).then((response) => {
            localStorage.setItem("url", response.data.url);
            localStorage.setItem("token_webpay", response.data.token);

            setToken(response.data.token);
            setUrl(response.data.url);
        }).catch((error) => {
            console.error(error)
            alert("Error");
            setLP(false);
        });
    }

    // MOVIL
    function CuponImg(cuponData) {
        return (
            <>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }} >
                    <img src={api.defaults.baseURL.slice(0, -4) + "/assets/cupones/cupon.png"} style={{ marginTop: "4vh" }} />
                    <div>
                        <h3 className="title-sinopse" style={{ color: "white" }}>
                            {cuponData.nome}
                        </h3>
                    </div>
                </Box>
            </>
        );
    }

    return (
        <>
            {loadingPay && (
                <Layer responsive={false} pad="large" background={"transparent"}>
                    <Bx align="center" justify="center" pad={"xlarge"} background="white" style={{ borderRadius: "15px" }} gap="large" >
                        <Text style={{ maxWidth: "400px", textAlign: "center" }}>
                            Aguarde, estamos redireccionando a Transbank, favor no actualice la página .
                        </Text>
                        <Spinner size="large" />
                    </Bx>
                </Layer>
            )}

            <form method="post" action={url_wp} name="formulario">
                <input type="hidden" name="token_ws" value={token_wp} />
            </form>
            <BrowserView>
                <div className="container-capa" style={{ backgroundImage: `url(${background})` }} >
                    <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
                        <div>
                            <div className="container-pai">
                                <div className="container-pai-filme">
                                    <div className="detalhes-filme">
                                        <img src={api.defaults.baseURL.slice(0, -4) + "/assets/cupones/cupon.png"} className="img-capa" />
                                    </div>

                                    <div className="informacoes-detalhes">
                                        <Stepper active={2} />
                                        <div className="calendario-direitaaa">
                                            <Bx direction="row" align="center" margin={{ left: "2.2%" }}>
                                                <h4 style={{ color: "white", marginTop: '1rem' }}>
                                                    Detalle de la compra
                                                </h4>
                                            </Bx>

                                            <Bx direction="row" margin={{ left: "2.2%" }}>
                                                <div>
                                                    <div className="description-first">
                                                        <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                                            Cupón: <span style={{ fontWeight: 'normal' }}>{cupon.nome}</span>
                                                        </h4>
                                                    </div>
                                                    <div className="description-first">
                                                        <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                                            Descripción: <span style={{ fontWeight: 'normal' }}>{cupon.descripcion}</span>
                                                        </h4>
                                                    </div>
                                                    <div className="description-first">
                                                        <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                                            Fecha vencimiento: <span style={{ fontWeight: 'normal' }}>{cupon.data_fim}</span>
                                                        </h4>
                                                    </div>
                                                    <div className="description-first">
                                                        <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginBottom: "10px" }}>Cantidad</h4>
                                                        <div className="container-main-igresso-column01">
                                                            <ButtonGroup>
                                                                <Button style={{ backgroundColor: "white", color: "black" }}
                                                                    onClick={() => {
                                                                        if (cantCupones - 1 > 0) {
                                                                            setCantCupones(cantCupones - 1);
                                                                        }
                                                                    }}
                                                                >
                                                                    -
                                                                </Button>
                                                                <Button disable="true" variant="text" style={{ color: "white" }}>{cantCupones}</Button>
                                                                <Button style={{ backgroundColor: "white", color: "black" }}
                                                                    onClick={() => {
                                                                        if (cantCupones + 1 <= cupon.cant_limite_x_venta) {
                                                                            setCantCupones(cantCupones + 1);
                                                                        }
                                                                    }}
                                                                >
                                                                    +
                                                                </Button>
                                                            </ButtonGroup>
                                                            <p style={{ color: "white", fontFamily: "Poppins" }} className="description-igresso" >
                                                                Cupones ({cantCupones}x) <span style={{ fontWeight: "bold", fontSize: "16px" }}>${" "} {parseInt(cupon.valor_venta * cantCupones).toLocaleString("pt-br", { currency: "CLP" })}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="description-first" style={{ marginLeft: 'auto', marginRight: '0px' }}>
                                                    <Bx border={{ color: "#f26522", size: "large", style: "hidden", }}
                                                        round="medium" background="#f26522" pad="medium" style={{ width: "12vw" }} >
                                                        <h1 style={{ fontFamily: "Poppins", color: "white", textAlign: "center", fontSize: "22px", }} >
                                                            Total{" "}
                                                        </h1>

                                                        <h1 style={{ fontFamily: "Poppins", color: "white", textAlign: "center", fontSize: "22px", }} >
                                                            ${" "}{parseInt(cupon.valor_venta * cantCupones) > 0
                                                                ? (parseInt(cupon.valor_venta * cantCupones) + 600).toLocaleString("pt-br", { currency: "CLP", })
                                                                : "0"}
                                                        </h1>

                                                        {parseInt(cupon.valor_venta * cantCupones) > 0 && (
                                                            <h6 style={{ fontFamily: "Poppins", color: "white", textAlign: "center", fontSize: "12px", }} >
                                                                Incluye ${" "} {parseInt(600).toLocaleString("pt-br", { currency: "CLP" })}{" "} de cargo por servicio
                                                            </h6>
                                                        )}
                                                    </Bx>
                                                </div>
                                            </Bx>

                                            <Bx direction="column" gap="8%" margin={{ left: "2.2%", top: "2%" }} >
                                                <h4 style={{ color: "white", fontFamily: 'Poppins' }} >Método de pago disponible{" "}</h4>
                                                <Bx height="small">
                                                    <img src="https://www.transbankdevelopers.cl/public/library/img/svg/logo_webpay_plus.svg" style={{ width: "20%" }} />
                                                    <hr size={2} style={{ marginTop: "1%" }} />
                                                    <h4 style={{ color: "white", fontFamily: 'Poppins' }}> Acepta los <a href="https://www.cinesol.cl/terminosycondiciones" target="_blank">términos y condiciones</a></h4>
                                                    <TermosCon />
                                                    <hr size={2} style={{ marginTop: "1%", marginBottom: "1%" }} />

                                                    {termos ? (
                                                        <>
                                                            <h4 style={{ color: "white", fontFamily: 'Poppins' }} > Completa tus datos para continuar{" "} </h4>
                                                            <div>
                                                                <Box>
                                                                    <Box sx={srow}>
                                                                        <Box sx={scolumn}>
                                                                            <input required id={"nome"} key={"nome"} value={nome} placeholder="Nombre *"
                                                                                onChange={(e) => setNome(e.target.value.toUpperCase())} />
                                                                        </Box>
                                                                        <Box sx={scolumn}>
                                                                            <input required key={"apelido"} id={"apelido"} placeholder="Apellido" value={apelido}
                                                                                onChange={(e) => setApelido(e.target.value.toUpperCase())} />
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={srow}>
                                                                        <Box sx={scolumn}>
                                                                            <input required id={"email"} type="email" placeholder="Email *" value={email}
                                                                                onChange={(e) => setEmail(e.target.value.toUpperCase())} />
                                                                        </Box>
                                                                        <Box sx={scolumn}>
                                                                            <input required id={"email_c"} type="email" placeholder="Email confirmación *" value={emailC}
                                                                                onChange={(e) => setEmailC(e.target.value.toUpperCase())} />
                                                                        </Box>
                                                                    </Box>

                                                                    <Box sx={srow}>
                                                                        <Box sx={{ marginRight: '1.9%' }}>
                                                                            <RadioGroup row value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)}>
                                                                                <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Rut</Typography>} style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }} value="rut" control={<Radio style={{ color: 'white' }} />} />
                                                                                <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Pasaporte</Typography>} value="pasaporte" control={<Radio style={{ color: 'white' }} />} />
                                                                            </RadioGroup>
                                                                        </Box>
                                                                        <Box sx={scolumn}>
                                                                            <input required key={"documento"} id={"documento"} placeholder="Documento *" onBlur={validarDocumento} value={documento}
                                                                                onChange={(e) => setDocumento(e.target.value.toUpperCase())} />
                                                                        </Box>
                                                                    </Box>

                                                                </Box>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <> </>
                                                    )}
                                                    <div>
                                                        <Button variant="contained" style={{
                                                            width: '150px',
                                                            height: '40px',
                                                            backgroundColor: '#CCCCCC',
                                                            color: '#333333',
                                                            border: '1px solid #CCCCCC',
                                                            margin: '20px 0 20px 2px',
                                                            cursor: 'pointer',
                                                            fontFamily: 'Poppins',
                                                            textTransform: 'capitalize'
                                                        }} startIcon={<ArrowBackIos />} onClick={handleCancelar}>Volver</Button>
                                                        {termos ? (
                                                            <Button variant="contained" style={{
                                                                width: '150px',
                                                                height: '40px',
                                                                backgroundColor: '#00C455',
                                                                border: '1px solid #00C455',
                                                                margin: '20px 0 20px 10px',
                                                                cursor: 'pointer',
                                                                fontFamily: 'Poppins',
                                                                textTransform: 'capitalize'
                                                            }} endIcon={<NavigateNext />} onClick={handleNavigation}>Pagar</Button>
                                                        ) : (
                                                            <> </>
                                                        )}
                                                    </div>
                                                </Bx>
                                            </Bx>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </BrowserView>

            <MobileView>

                <Box sx={styleboxfilme}>
                    <Stepper active={0} />
                    <div className="detalhes-filme">
                        <CuponImg filme={cupon} />
                    </div>

                    <Box sx={{ width: "85%" }}>
                        <h4 style={{ color: "white", fontFamily: "Poppins" }}>
                            Detalle de la compra
                        </h4>
                        <div className="description-first">
                            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Cupón: <span style={{ fontWeight: 'normal' }}>{cupon.nome}</span>
                            </h4>
                        </div>
                        <div className="description-first">
                            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Fecha inicio: <span style={{ fontWeight: 'normal' }}>{cupon.data_inicio}</span>
                            </h4>
                        </div>
                        <div className="description-first">
                            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Fecha vencimiento: <span style={{ fontWeight: 'normal' }}>{cupon.data_fim}</span>
                            </h4>
                        </div>
                        <div className="description-first">
                            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Cantidad
                            </h4>
                            <div className="container-main-igresso-column01" style={{ marginBottom: '2%' }}>
                                <ButtonGroup>
                                    <Button style={{ backgroundColor: "white", color: "black" }}
                                        onClick={() => {
                                            if (cantCupones - 1 > 0) {
                                                setCantCupones(cantCupones - 1);
                                            }
                                        }}
                                    >
                                        -
                                    </Button>
                                    <Button disable="true" variant="text" style={{ color: "white" }}>{cantCupones}</Button>
                                    <Button style={{ backgroundColor: "white", color: "black" }} onClick={() => {
                                        if (cantCupones + 1 <= cupon.cant_limite_x_venta) {
                                            setCantCupones(cantCupones + 1);
                                        }
                                    }}>
                                        +
                                    </Button>
                                </ButtonGroup>
                                <p style={{ color: "white", fontFamily: "Poppins" }}>
                                    Cupones ({cantCupones}x) <span style={{ fontWeight: "bold", fontSize: "16px" }}>${" "} {parseInt(cupon.valor_venta * cantCupones).toLocaleString("pt-br", { currency: "CLP" })}</span>
                                </p>
                            </div>
                        </div>
                        <div className="description-first">
                            <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Total: <span
                                    style={{ fontWeight: 'normal' }}>
                                    ${" "}{parseInt(cupon.valor_venta * cantCupones) > 0
                                        ? (parseInt(cupon.valor_venta * cantCupones) + 600
                                        ).toLocaleString("pt-br", { currency: "CLP", })
                                        : "0"}
                                </span>
                                {parseInt(cupon.valor_venta * cantCupones) > 0 && (
                                    <span style={{ fontWeight: 'normal' }} >
                                        {" "}(Incluye ${" "} {parseInt(600).toLocaleString("pt-br", { currency: "CLP", })}{" "}
                                        de cargo por servicio)
                                    </span>
                                )}
                            </h4>
                        </div>
                        <Bx direction="column" style={{ marginTop: "2%" }}>
                            <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
                                Método de pago disponible{" "}
                            </h4>
                            <Bx height="small">
                                <img src="https://www.transbankdevelopers.cl/public/library/img/svg/logo_webpay_plus.svg"
                                    style={{ width: "50%" }} />
                                <hr size={2} style={{ marginTop: "1%" }} />
                                <h4 style={{ color: "white", fontFamily: "Poppins" }} > Acepta los Términos y condiciones{" "} </h4>
                                <TermosCon />
                                <hr size={2} style={{ marginTop: "1%" }} />
                                {termos ? (
                                    <>
                                        <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
                                            Completa tus datos para continuar{" "}
                                        </h4>
                                        <input style={{ marginTop: '2%' }} required id={"nome"} key={"nome"} value={nome} placeholder="Nombre *"
                                            onChange={(e) => setNome(e.target.value.toUpperCase())} />
                                        <input style={{ marginTop: '2%' }} required key={"apelido"} id={"apelido"} placeholder="Apellido" value={apelido}
                                            onChange={(e) => setApelido(e.target.value.toUpperCase())} />
                                        <input style={{ marginTop: '2%' }} required id={"email"} type="email" placeholder="Email *" value={email}
                                            onChange={(e) => setEmail(e.target.value.toUpperCase())} />
                                        <input style={{ marginTop: '2%' }} required id={"email_c"} type="email" placeholder="Email confirmación *" value={emailC}
                                            onChange={(e) => setEmailC(e.target.value.toUpperCase())} />
                                        <RadioGroup row style={{ marginTop: '2%' }} value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)}>
                                            <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Rut</Typography>} style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }} value="rut" control={<Radio style={{ color: 'white' }} />} />
                                            <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Pasaporte</Typography>} value="pasaporte" control={<Radio style={{ color: 'white' }} />} />
                                        </RadioGroup>
                                        <input style={{ marginTop: '2%' }} required key={"documento"} id={"documento"} placeholder="Documento *" onBlur={validarDocumento} value={documento}
                                            onChange={(e) => setDocumento(e.target.value.toUpperCase())} />
                                        <hr size={2} style={{ marginTop: "4%", marginBottom: '2%' }} />
                                    </>
                                ) : (
                                    <> </>
                                )}
                                <div className="footer-button" style={{ paddingBottom: '4%' }}>
                                    <Button variant="contained" style={{
                                        width: '100%',
                                        height: '40px',
                                        backgroundColor: '#CCCCCC',
                                        color: '#333333',
                                        border: '1px solid #CCCCCC',
                                        marginTop: '2%',
                                        cursor: 'pointer',
                                        fontFamily: 'Poppins',
                                        textTransform: 'capitalize'
                                    }} startIcon={<ArrowBackIos />} onClick={handleCancelar}>Volver</Button>
                                    {termos ? (
                                        <Button variant="contained" style={{
                                            width: '100%',
                                            height: '40px',
                                            backgroundColor: '#00C455',
                                            border: '1px solid #00C455',
                                            marginTop: '2%',
                                            cursor: 'pointer',
                                            fontFamily: 'Poppins',
                                            textTransform: 'capitalize'
                                        }} endIcon={<NavigateNext />} onClick={handleNavigation}>Pagar</Button>
                                    ) : (
                                        <> </>
                                    )}
                                </div>
                            </Bx>
                        </Bx>
                    </Box>
                </Box>
            </MobileView>
        </>
    );
};
