import { Box } from "grommet";
import React, { useEffect } from "react";
import userIcon from "../../assets/user-icon.png";
import Button from "@mui/material/Button";
import { NavigateNext, ArrowBackIos } from '@material-ui/icons';
import background from "../../assets/background.png";


export const EventoAssent = ({
  assentos,
  setSelected,
  selectedInfo,
  movil = false,
  nextStep,
  prevStep,
}) => {
  const [blocos, setBlocos] = React.useState([]);
  const [loading, setLoad] = React.useState(false);

  const handleAssentos = () => {
    let blc = [];

    assentos.forEach((assento) => {
      if (blc[assento.bloco] === undefined) blc[assento.bloco] = [];
      if (blc[assento.bloco][assento.linha] === undefined)
        blc[assento.bloco][assento.linha] = [];
      blc[assento.bloco][assento.linha].push(assento);
    });

    setBlocos(blc);
  };

  const styleboxfilme = {
    paddingTop: '4%',
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`,
    height: "110%",
  };

  useEffect(() => {
    if (assentos) handleAssentos();
  }, [assentos]);

  const handleSelectAssento = async (i1, i2, i3) => {
    let bloco = blocos[i1][i2][i3];

    let slct = selectedInfo.assentos;

    if (bloco.ocupado !== 1 && bloco.ocupado !== -1) {
      if (bloco.ocupado === 2) {
        bloco.ocupado = 0;
        slct = slct.filter((item) => item.id !== bloco.id);
      } else {
        if (
          slct.length + 1 <=
          selectedInfo.servico.n_ticket.reduce(
            (previousValue, currentValue) => previousValue + currentValue
          )
        ) {
          bloco.ocupado = 2;
          slct.push(bloco);
        }
      }

      setSelected({
        ...selectedInfo,
        assentos: slct,
      });
    }

    let newBloco = blocos;
    newBloco[i1][i2][i3] = bloco;

    setBlocos(newBloco);
    setLoad(false);
  };
  return (
    <>
      {!movil ? (
        <div className="content-payment">
          <div className="infos-compra">
            <h4 className="title-selecione-header">
              Selección de asientos: {selectedInfo.assentos.length} de{" "}
              {selectedInfo.servico.n_ticket.reduce(
                (previousValue, currentValue) => previousValue + currentValue
              )}
            </h4>
          </div>
          <p className="subtitle-header" style={{ color: "white" }}>Pantalla</p>
          <div className="pantalla"></div>
          {!loading && (
            <div className="sala">
              {blocos.map((bloco, i1) => (
                <div className="bloco">
                  <div className="linha">
                    {bloco.map((linhas, i2) => (
                      <Box direction="row">
                        {linhas.map((linha, i3) => (
                          <button
                            className={`assento 
                      ${assentos.length < 200 && "x15"} 
                      ${linha.ocupado === 2 ? "selecionado" : (linha.ocupado === 1 ? "bloqueado" : "")}`}
                            style={{
                              backgroundColor:
                                linha.ocupado === -1 ? "transparent" : "",
                              color: linha.ocupado === -1 ? "transparent" : "",
                              cursor: linha.ocupado === -1 ? "default" : "",
                            }}
                            onClick={() => {
                              handleSelectAssento(i1, i2, i3);
                            }}
                          >
                            {linha.ocupado !== 1 ? (
                              linha.nome
                            ) : (
                              <img src={userIcon} alt="use" />
                            )}
                          </button>
                        ))}
                      </Box>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div
            className="flex-colunm"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="display-flex">
              <div className="assento bloqueado">
                <img src={userIcon} alt="user icon ocupado" />
              </div>
              <span style={{ color: "white" }}>Ocupado</span>
            </div>
            <div className="display-flex">
              <div className="assento selecionado"></div>
              <span style={{ color: "white" }}>Seleccionado</span>
            </div>
          </div>

          <br />
          <div>
            <Button variant="contained" style={{
              width: '150px',
              height: '40px',
              backgroundColor: '#CCCCCC',
              color: '#333333',
              border: '1px solid #CCCCCC',
              margin: '0px 0 20px 10px',
              cursor: 'pointer',
              fontFamily: 'Poppins',
              textTransform: 'capitalize'
            }} startIcon={<ArrowBackIos />} onClick={prevStep}>Volver</Button>
            {selectedInfo.assentos.length ===
              selectedInfo.servico.n_ticket.reduce(
                (previousValue, currentValue) => previousValue + currentValue
              ) && (
                <>
                  <Button variant="contained" style={{
                    width: '150px',
                    height: '40px',
                    backgroundColor: '#00C455',
                    border: '1px solid #00C455',
                    margin: '0px 0 20px 10px',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize'
                  }} endIcon={<NavigateNext />} onClick={nextStep}>Continuar</Button>
                </>
              )}
          </div>

        </div>
      ) : (
        <Box sx={styleboxfilme}>
          <div style={{ marginTop: '4%' }}>
            <h4 style={{ color: "white", fontFamily: "Poppins" }}>
              Selección de asientos: {selectedInfo.assentos.length} de{" "}
              {selectedInfo.servico.n_ticket.reduce(
                (previousValue, currentValue) => previousValue + currentValue
              )}
            </h4>
          </div>
          <p className="subtitle-header" style={{ color: "white" }}>Pantalla</p>
          <hr
            style={{
              width: "90%",
              border: "6px solid var(--color-dark-grreen)",
              borderRadius: "10px",
            }}
          />

          <div className="view-butacas">
            <div className="sala" style={{ marginLeft: "2%" }}>
              {blocos.map((bloco, i1) => (
                <div className="bloco">
                  {bloco.map((linhas, i2) => (
                    <div className="linha">
                      {linhas.map((linha, i3) => (
                        <button
                          className={`assento 
                      ${assentos.length < 200 && "x15"} 
                      ${linha.ocupado === 2 ? "selecionado" : (linha.ocupado === 1 ? "bloqueado" : "")}`}
                          style={{
                            backgroundColor:
                              linha.ocupado === -1 ? "transparent" : "",
                            color: linha.ocupado === -1 ? "transparent" : "",
                            cursor: linha.ocupado === -1 ? "default" : "",
                          }}
                          onClick={() => {
                            handleSelectAssento(i1, i2, i3);
                          }}
                        >
                          {linha.ocupado !== 1 ? (
                            linha.nome
                          ) : (
                            <img src={userIcon} alt="use" />
                          )}
                        </button>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <Box>
            <div className="flex-colunm">
              <div className="display-flex">
                <div className="assento bloqueado">
                  <img src={userIcon} alt="user icon ocupado" />
                </div>
                <span style={{ color: "white" }}>Ocupado</span>
              </div>
              <div className="display-flex" style={{ marginLeft: "4%" }}>
                <div className="assento selecionado"></div>
                <span style={{ color: "white" }}>Seleccionado</span>
              </div>
            </div>
            <div className="footer-button" style={{ marginLeft: '10px', paddingBottom: "4%" }}>
              <Button variant="contained" style={{
                width: '100%',
                height: '40px',
                backgroundColor: '#CCCCCC',
                color: '#333333',
                border: '1px solid #CCCCCC',
                marginTop: '2%',
                cursor: 'pointer',
                fontFamily: 'Poppins',
                textTransform: 'capitalize'
              }} startIcon={<ArrowBackIos />} onClick={prevStep}>Volver</Button>
              {selectedInfo.assentos.length ===
                selectedInfo.servico.n_ticket.reduce(
                  (previousValue, currentValue) => previousValue + currentValue
                ) && (
                  <>
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '40px', 
                      backgroundColor: '#00C455',
                      border: '1px solid #00C455',
                      marginTop: '2%',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize'
                    }} endIcon={<NavigateNext />} onClick={nextStep}>Pagar</Button>
                  </>
                )}
            </div>
          </Box>

        </Box>
      )}
    </>

  );
};
