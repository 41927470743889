import React, { useState, useEffect } from "react";

import { HeaderSemCarrousel, Footer, Body, BodyT } from "../../components/HomeCompo/HomeCompo";
import { BodyPec, BodyPecT, CarrouselEstrenos, BodyRegistro, BodyCupom, BodyCupomT, BodyRegistroT, BodyUserData } from "../../components/HomeCompo/pec";

import { api } from "../../services/api";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";



import background from "../../assets/background.png";

import './styles.css'

import {
  // HeaderCar,
  // Footer,
  // CupomFooter,
  HeaderT,
  ParceirosFooterTSemback,
  FooterT,

} from "../../components/HomeCompo/HomeCompo";





import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


import Button from '@mui/material/Button';

import { BrowserView, MobileView } from 'react-device-detect';

export const Pcines = () => {
  const [filmes, setFilmes] = useState([]);

  async function getFilmes() {
    const response = await api.get("/filme/pesquisar");

    setFilmes(response.data.data);
  }

  useEffect(() => {
    getFilmes();
  }, []);

  return (
    <>

      <BrowserView >
        <Box sx={{ backgroundImage: `url(${background})`, height: "100%" }}>
          <HeaderSemCarrousel />

          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "orange", marginBottom: "2%" }}>
              Peliculas en cines
            </h4>
            {filmes?.map((item) => (
              <BodyPec filme={item} />
            ))}
          </Box>
          <Footer />
        </Box>

      </BrowserView >

      <MobileView>
        <Box sx={{ backgroundImage: `url(${background})`, height: "100%" }}>
          <HeaderT />
          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "orange", marginBottom: "2%" }}>
              Peliculas en cines
            </h4>
            {filmes?.map((item) => (
              <BodyPecT filme={item} />
            ))}
          </Box>
          <ParceirosFooterTSemback />
          <FooterT />
        </Box>
      </MobileView >
    </>
  );
};

export const Listpeliculas = () => {
  const [filmes, setFilmes] = useState([]);

  async function getFilmes() {

    const response = await api.get("/filme/pesquisar");

    setFilmes(response.data.data);
  }

  useEffect(() => {
    getFilmes();
  }, []);

  return (
    <>
      <BrowserView >
        <Box sx={{ backgroundImage: `url(${background})`, height: "100%" }}>
          <HeaderSemCarrousel />

          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "white", marginBottom: "2%" }}>
              ESTRENOS / CARTELERA SEMANAL
            </h4>

            <CarrouselEstrenos />
          </Box>

          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "white", marginBottom: "2%" }}>EM BREVE</h4>

            <CarrouselEstrenos />
          </Box>

          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "white", marginBottom: "2%" }}>
              ESTRENOS / CARTELERA SEMANAL
            </h4>

            <CarrouselEstrenos />
          </Box>
          <Footer />
        </Box>
      </BrowserView >

      <MobileView>
        <Box sx={{ backgroundImage: `url(${background})`, height: "100%" }}>
          <HeaderT />

          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "white", marginBottom: "2%" }}>
              ESTRENOS / CARTELERA SEMANAL
            </h4>

            <CarrouselEstrenos />
          </Box>

          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "white", marginBottom: "2%" }}>EM BREVE</h4>

            <CarrouselEstrenos />
          </Box>

          <Box
            sx={{
              marginTop: "5%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "white", marginBottom: "2%" }}>
              ESTRENOS / CARTELERA SEMANAL
            </h4>

            <CarrouselEstrenos />
          </Box>
          <ParceirosFooterTSemback />
          <FooterT />
        </Box>
      </MobileView>
    </>

  );
};

export const Registrar = () => {
  return (<>
    <BrowserView >
      <Box sx={{ backgroundImage: `url(${background})`, height: "100%" }}>
        <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
          <HeaderSemCarrousel />


          <BodyRegistro />

          <Box id='carrousel-from-home'
            sx={{
              backgroundColor: '#F26522'
            }}>
            <Body />
          </Box>
          <Footer />

        </Box>
      </Box>

    </BrowserView >

    <MobileView>


      <Box sx={{ backgroundImage: `url(${background})` }}>
        <HeaderT />

        <BodyRegistroT />

        <ParceirosFooterTSemback />
        <FooterT />
      </Box>
    </MobileView ></>

  );
};

export const Cupons = () => {
  return (<>


    <BrowserView >
      <Box>
        <HeaderSemCarrousel />

        <BodyCupom />

        <Box id='carrousel-from-home'
          sx={{
            backgroundColor: '#F26522'
          }}>
          <Body />
        </Box>

        <Footer />

      </Box>

    </BrowserView >

    <MobileView>


      <Box sx={{ backgroundImage: `url(${background})` }}>
        <HeaderT />
        <BodyCupomT />
        <Box id='carrousel-from-home'
          sx={{
            backgroundColor: '#F26522',


          }}>
          <BodyT />
        </Box>
        <ParceirosFooterTSemback />
        <FooterT />
      </Box>
    </MobileView >
  </>
  )
}

export const UsuarioPerfil = () => {
  return (<>
    <BrowserView >

      <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.52)", backgroundImage: `url(${background})` }}>
        <HeaderSemCarrousel />

        <Box sx={{
          display: "flex",
          flexDirection: "row",

          // backgroundColor: "rgba(0, 0, 0, 0.81)",
          marginTop: '2%',
          marginLeft: '8vw',
          // width: '40%'
        }}>




          <Box sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(0, 0, 0, 0.81)",
            // marginTop: '5%',
            // marginLeft: '8%',
            width: '40vw'
          }}>

            <h1 style={{ fontSize: 25, color: 'orange', textAlign: 'center' }}> Películas assistidas </h1>

            <TableContainer sx={{ backgroundColor: 'transparent' }} component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 20, color: 'orange' }}>Pelicula</TableCell>
                    <TableCell align="right" style={{ fontSize: 20, color: 'orange' }}>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => ( */}
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Avengers - Ultimato
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/19/2020
                    </TableCell>
                  </TableRow>



                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Avengers - Ultimato
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/19/2020
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Avengers - Ultimato
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/19/2020
                    </TableCell>
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(0, 0, 0, 0.81)",
            // marginTop: '5%',
            marginLeft: '2vw',
            width: '40vw',

          }}>

            <h1 style={{ fontSize: 25, color: 'orange', textAlign: 'center' }}> Meus cupons </h1>

            <TableContainer sx={{ backgroundColor: 'transparent' }} component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 20, color: 'orange' }}>Pelicula</TableCell>
                    <TableCell align="right" style={{ fontSize: 20, color: 'orange' }}>Validad</TableCell>
                    <TableCell align="right" style={{ fontSize: 20, color: 'orange' }}>Código</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => ( */}
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      2 por 1
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/09/2022
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      <Button variant="contained" color="success">
                        Copiar código
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      30% de descuonto
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/09/2022
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      <Button variant="contained" color="success">
                        Copiar código
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Black Friday
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/09/2022
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      <Button variant="contained" color="success">
                        Copiar código
                      </Button>
                    </TableCell>
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

        </Box>

        <Box sx={{
          // display: "flex",
          // flexDirection: "column",
          backgroundColor: "rgba(0, 0, 0, 0.81)",
          marginTop: '3%',
          marginLeft: '29vw',
          width: '40vw'
        }}>
          <BodyUserData />
        </Box>
        <Footer />

      </Box>
    </BrowserView >

    <MobileView>
      <Box sx={{ backgroundImage: `url(${background})` }}>
        <HeaderT />

        <Box sx={{
          display: "flex",
          flexDirection: "column",
          // backgroundColor: "rgba(0, 0, 0, 0.81)",
          marginTop: '2%',
          marginLeft: '8vw',
          // width: '40%'
        }}>




          <Box sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(0, 0, 0, 0.81)",
            // marginTop: '5%',
            // marginLeft: '8%',
            width: '85vw'
          }}>

            <h1 style={{ fontSize: 25, color: 'orange', textAlign: 'center' }}> Películas assistidas </h1>

            <TableContainer sx={{ backgroundColor: 'transparent' }} component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 20, color: 'orange' }}>Pelicula</TableCell>
                    <TableCell align="right" style={{ fontSize: 20, color: 'orange' }}>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => ( */}
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Avengers - Ultimato
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/19/2020
                    </TableCell>
                  </TableRow>



                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Avengers - Ultimato
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/19/2020
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Avengers - Ultimato
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/19/2020
                    </TableCell>
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(0, 0, 0, 0.81)",
            marginTop: '5%',
            width: '85vw',

          }}>

            <h1 style={{ fontSize: 25, color: 'orange', textAlign: 'center' }}> Meus cupons </h1>

            <TableContainer sx={{ backgroundColor: 'transparent' }} component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: 20, color: 'orange' }}>Pelicula</TableCell>
                    <TableCell align="right" style={{ fontSize: 20, color: 'orange' }}>Validad</TableCell>
                    <TableCell align="right" style={{ fontSize: 20, color: 'orange' }}>Código</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => ( */}
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      2 por 1
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/09/2022
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      <Button variant="contained" color="success">
                        Copiar código
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      30% de descuonto
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/09/2022
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      <Button variant="contained" color="success">
                        Copiar código
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row" style={{ fontSize: 15, color: 'white' }}>
                      Black Friday
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      02/09/2022
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: 15, color: 'white' }}>
                      <Button variant="contained" color="success">
                        Copiar código
                      </Button>
                    </TableCell>
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

        </Box>

        <Box sx={{
          // display: "flex",
          // flexDirection: "column",
          backgroundColor: "rgba(0, 0, 0, 0.81)",
          marginTop: '20%',
          // marginLeft: '29vw',
          // width: '40vw'
        }}>
          <BodyUserData />
        </Box>

        <ParceirosFooterTSemback />
        <FooterT />
      </Box>
    </MobileView>


  </>
  )
}



