import React, { useState, useEffect } from "react";
import { ButtonSeg } from "../../components/ButtonDates/ButtonSeg";
import { ButtonQua } from "../../components/ButtonDates/ButtonQuad";
import { ButtonQui } from "../../components/ButtonDates/ButtonQui";
import { ButtonSex } from "../../components/ButtonDates/ButtonSex";
import { ButtonSab } from "../../components/ButtonDates/ButtonSab";
import { ButtonDom } from "../../components/ButtonDates/ButtonDom";
import { ButtonTer } from "../../components/ButtonDates/ButtonTer";
import { User } from "../../components/User";
import { useHistory } from "react-router-dom";
import "./Wallet.styles.css";
import { AiFillStar } from "react-icons/ai";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { NavigateNext } from '@material-ui/icons';
import Swal from "sweetalert2";

import background from "../../assets/background.png";

import { BrowserView, MobileView } from "react-device-detect";
import Divider from "@mui/material/Divider";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { Box as Bx, Text } from "grommet";
import { ButtonWhatsapp } from "../../components/ButtonWhatsapp/index";

import { api } from "../../services/api";
import Stepper from "../../components/Stepper";
export const Wallet = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 14,
      fontFamily: ["Poppins"].join(","),
      width: "auto",
      padding: "6px 8px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      "::placeholder": {
        fontSize: 14,
        fontFamily: ["Poppins"].join(","),
      },
    },
  }));

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "6px 8px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });
  const [filme, setFilme] = useState({});
  const [datas, setDatas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [qtdInteiras, setQtdInteiras] = useState(1);
  const [qtdMeias, setQtdMeias] = useState(0);
  const [cupom, setCupom] = useState();
  const [cupomInfo, setCupomInfo] = useState();
  const [limiteCupon, setLimiteCupon] = useState(null);

  const history = useHistory();
  const styleboxfilme = {
    display: "flex",
    paddingTop: '4%',
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`
  };


  async function getFilme() {
    let params = new URLSearchParams(window.location.search);

    const response = await api.get("/filme/pesquisar-info/" + params.get("id"));

    setFilme(response.data.data);
  }

  async function getDatas(cb) {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/web/ingresso/pesquisar-datas/" + params.get("id"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setDatas(response.data.data);

    if (typeof cb != "undefined") {
      cb(response.data.data);
    }
  }

  async function getHorarios(horario, cb) {
    setHorarios([]);

    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/web/ingresso/pesquisar-horarios/" +
      params.get("id") +
      "?data=" +
      horario,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setHorarios(response.data.data);

    if (typeof cb != "undefined") {
      cb(response.data.data);
    }
  }


  useEffect(() => {
    getFilme();
    getDatas();
  }, []);


  //funcao Navigation
  const handleNavigation = () => {

    if (qtdInteiras == 0) {
      Swal.fire(
        "Falta información!",
        "Favor digite la cantidad de entradas.",
        "error"
      )
    } else {
      let valorTotal = 0;
      let valorDesconto = null;

      if (limiteCupon == null) {
        if (cupomInfo) {
          valorTotal = cupomInfo.valor_total;
          valorDesconto = cupomInfo.valor_desconto;
        } else {
          valorTotal += selectedHorario.valor * qtdInteiras;
        }
      }

      let request = {
        horario: selectedHorario,
        horario_id: selectedHorario.id,
        cupom: cupomInfo,
        qtd_meias: qtdMeias,
        qtd_inteiras: qtdInteiras,
        qtd_total: parseInt(qtdMeias) + parseInt(qtdInteiras),
        valor_meias: ((selectedHorario.valor / 2) * qtdMeias).toFixed(2),
        valor_inteiras: (selectedHorario.valor * qtdInteiras).toFixed(2),
        valor_total: valorTotal.toFixed(2),
        valor_desconto: valorDesconto ? valorDesconto.toFixed(2) : null,
      };

      let params = new URLSearchParams(window.location.search);
      history.push("/butacas?id=" + params.get("id"), { request });
    }

  };

  const handleCupomInfos = (e) => {
    setCupom(e.target.value);
  };

  const inserirCupom = async () => {
    let token = localStorage.token;

    const response = await api
      .get(
        `/verificar-cupom/${cupom}/${filme.id}/${selectedDate.dia_da_semana}/2`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .catch(() =>
        Swal.fire(
          "Código inválido!",
          "El cupón que dígitó no fue encontrado.",
          "error"
        )
      );

    if (response.data.data) {
      if (response.data.data.saldo !== undefined) {
        if (response.data.data.saldo < qtdInteiras) {
          setQtdInteiras(1);
        }
        setLimiteCupon(response.data.data.saldo);
        Swal.fire("Código aceptado!", "Tienes hasta " + response.data.data.saldo + " entradas con este cupón.", "success");
      } else {
        Swal.fire("Código aceptado!", "Descuento aplicado", "success");
      }
    } else {
      Swal.fire(
        "Código inválido!",
        "El cupón que dígitó no fue encontrado.",
        "error"
      );
    }

    handleValuesTotal(response);
  };

  const handleValuesTotal = (response) => {
    if (response.data.data.saldo === undefined) {
      if (response.data.data.tipo === "quantidade") cupomQuantidade(response);
      else if (response.data.data.tipo === "porcentagem")
        cupomPorcentagem(response.data.data);
      else if (response.data.data.tipo === "valor")
        cupomValorFixo(response.data.data);
    } else {
      setCupomInfo(response.data.data);
    }
  };

  const cupomValorFixo = (cupom) => {
    cupom.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    cupom.valor_desconto = cupom.valor;

    if (cupom.valor_total - cupom.valor_desconto > 0)
      cupom.valor_total = cupom.valor_total - cupom.valor_desconto;
    else cupom.valor_total = 0;

    setCupomInfo(cupom);
  };

  const cupomPorcentagem = (cupom) => {
    cupom.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    cupom.valor_desconto = cupom.valor_total * (cupom.porcentagem / 100);

    cupom.valor_total = cupom.valor_total - cupom.valor_desconto;

    setCupomInfo(cupom);
  };

  const cupomQuantidade = (response) => {
    response.data.data.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    if (qtdInteiras > 0) {
      if (response.data.data.tickets_afetados > 0) {
        if (response.data.data.tickets_afetados < qtdInteiras) {
          response.data.data.valor_total -= parseInt(
            selectedHorario.valor * response.data.data.tickets_afetados
          );
          response.data.data.valor_desconto = parseInt(
            selectedHorario.valor * response.data.data.tickets_afetados
          );
        } else {
          response.data.data.valor_total -= parseInt(
            selectedHorario.valor * qtdInteiras
          );
          response.data.data.valor_desconto = parseInt(
            selectedHorario.valor * qtdInteiras
          );
        }
      }
    } else {
      if (response.data.data.tickets_afetados > 0) {
        if (response.data.data.tickets_afetados <= qtdMeias) {
          response.data.data.valor_total -= parseInt(
            (selectedHorario.valor / 2) * response.data.data.tickets_afetados
          );
          response.data.data.valor_desconto = parseInt(
            (selectedHorario.valor / 2) * response.data.data.tickets_afetados
          );
        } else {
          response.data.data.valor_total -= parseInt(
            (selectedHorario.valor * qtdMeias) / 2
          );
          response.data.data.valor_desconto = parseInt(
            (selectedHorario.valor * qtdMeias) / 2
          );
        }
      }
    }
    setCupomInfo(response.data.data);
  };

  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedHorario, setSelectedHorario] = useState(false);

  const handleSelectedDate = (selected, cb) => {
    setSelectedDate(selected);
    setSelectedHorario(false);
    setQtdMeias(0);
    setQtdInteiras(0);
    getHorarios(selected.hora, cb);
  };

  const handleSelectedHorario = (selected) => {
    setSelectedHorario(selected);
    setQtdMeias(0);
    setQtdInteiras(1);
  };

  return (
    <>
      <BrowserView>
        <ButtonWhatsapp />
        <div
          className="container-capa"
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
            {/* <HeaderCar /> */}
            <div>
              <div className="container-pai">
                <div className="container-pai-filme">
                  <div className="detalhes-filme">
                    <img
                      src={filme.link_img_capa}
                      alt=""
                      className="img-capa"
                    />
                    <div className="infos-filme-carteira">
                      <h2 className="title-sinopse">{filme.titulo}</h2>
                      <p style={{ color: "black" }}>2019 - Ação | Fantasia</p>
                      <p>Diretor: {filme.diretor}</p>
                      <p>
                        {" "}
                        <AiFillStar className="iconStar" />
                        7.5 (85,741)
                      </p>
                      <h6>
                        We all have a superhero inside us, it just takes a bit
                        of magic to bring it out. In Billy Batson's case, by
                        shouting out one word - SHAZAM. - this streetwise
                        fourteen-year-old foster kid can turn into the grown-up
                        superhero Shazam
                      </h6>
                    </div>
                  </div>

                  <div className="informacoes-detalhes">
                    <Stepper active={0} />
                    <div className="calendario-direitaaa">
                      <div className="conainer-selecione-date">
                        <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione fecha</h4>
                        <div className="container-DATAS">
                          {datas.map((item) => {
                            return (
                              <button
                                className={
                                  selectedDate.data == item.data
                                    ? "content-selecione-date-date-Dom-active"
                                    : "content-selecione-date-date-Dom"
                                }
                                onClick={() => handleSelectedDate(item)}
                              >
                                <div className="main-content-date-date-Dom">
                                  <p
                                    className={
                                      selectedDate.data == item.data
                                        ? "first-p-active"
                                        : "first-p"
                                    }
                                  >
                                    {item.dia_da_semana}
                                  </p>
                                  <p
                                    className={
                                      selectedDate.data == item.data
                                        ? "last-p-active"
                                        : "last-p"
                                    }
                                  >
                                    {item.data[0] + "" + item.data[1]}
                                  </p>
                                  <p
                                    className={
                                      selectedDate.data == item.data
                                        ? "tri-p-active"
                                        : "tri-p"
                                    }
                                  >
                                    {item.nome_mes}
                                  </p>
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      <Bx margin={{ left: "2.2%" }}>
                        {selectedDate && (
                          <>
                            <div className="container-seccion-carteira">
                              <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione la función</h4>

                              <div>
                                {/** Final Button Seccicion 01*/}
                                {horarios.map((item) => {
                                  return (
                                    <button
                                      className={
                                        selectedHorario.id == item.id
                                          ? "container-secction-button-selected"
                                          : "container-secction-button"
                                      }
                                      style={{ border: '0px' }}
                                      onClick={() =>
                                        handleSelectedHorario(item)
                                      }
                                    >
                                      <p
                                        className={
                                          selectedHorario.id == item.id
                                            ? "button-first-txt-selected"
                                            : "button-first-txt"
                                        }
                                      >
                                        {item.audio == "leg"
                                          ? "Subtitulada"
                                          : "Doblada"}
                                      </p>
                                      <p
                                        className={
                                          selectedHorario.id == item.id
                                            ? "button-last-txt-selected"
                                            : "button-last-txt"
                                        }
                                      >
                                        {item.horario}
                                      </p>
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}

                        {selectedHorario.id ? (
                          <>
                            <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px", marginBottom: "10px" }}>Cantidad</h4>
                            <div className="container-main-igresso-column01" style={{ marginLeft: "10px" }}>
                              <ButtonGroup>
                                <Button style={{ backgroundColor: "white", color: "black" }}
                                  onClick={() => {
                                    if (qtdInteiras - 1 > 0)
                                      setQtdInteiras(qtdInteiras - 1);
                                  }}
                                >
                                  -
                                </Button>
                                <Button disable="true" variant="text" style={{ color: "white" }}>{qtdInteiras}</Button>
                                <Button style={{ backgroundColor: "white", color: "black" }}
                                  onClick={() => {
                                    if (qtdInteiras + 1 <= 10 && (limiteCupon == null || (qtdInteiras + 1) <= limiteCupon))
                                      setQtdInteiras(qtdInteiras + 1);
                                  }}
                                >
                                  +
                                </Button>
                              </ButtonGroup>
                              <p
                                style={{ color: "white", fontFamily: "Poppins" }}
                                className="description-igresso"
                              >
                                Butaca tradicional ({qtdInteiras}x) <span style={{ fontWeight: "bold", fontSize: "16px" }}>${" "}
                                  {(limiteCupon !== null) ?
                                    ('0 (Tu cupón cubre el 100% de las entradas)')
                                    : (
                                      parseInt(selectedHorario.valor * qtdInteiras).toLocaleString("pt-br", { currency: "CLP" })
                                    )}
                                </span>
                              </p>
                            </div>
                            <br />

                            {filme.estreia == 0 && (
                              <>
                                <hr style={{ width: "75%", marginBottom: "2%" }} />

                                <h4 style={{ color: "white", paddingLeft: "10px" }}>
                                  {" "}
                                  Ingresa aquí tu código promocional{" "}
                                </h4>
                                <p style={{ color: "white", paddingLeft: "10px", fontSize: "0.9rem" }}>
                                  {" "}
                                  Aprovecha tu descuento y ven a disfrutar de
                                  CINESOL !{" "}
                                </p>
                                <br />
                                <Bx direction="row" gap={"small"} style={{ paddingLeft: "10px" }}>
                                  <BootstrapInput
                                    id="bootstrap-input-1"
                                    autoFocus={true}
                                    value={cupom}
                                    placeholder="Digite el código"
                                    onChange={handleCupomInfos}
                                  />

                                  <BootstrapButton
                                    variant="contained"
                                    onClick={() => inserirCupom()}
                                  >
                                    Agregar
                                  </BootstrapButton>
                                  {cupomInfo ? (
                                    <>
                                      <div
                                        style={{
                                          padding: 6,
                                          textAlign: 'center',
                                          background: "var(--orange)",
                                          display: "inline-block",
                                          color: "#ffffff",
                                          width: "20%",
                                          borderRadius: 6
                                        }}
                                      >
                                        <Text>{limiteCupon == null ? cupomInfo.promo.nome : cupomInfo.nome}</Text>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Bx>
                              </>
                            )}

                            <hr style={{ width: "75%", marginTop: "2%" }} />
                            <Button variant="contained" style={{
                              width: '150px',
                              height: '40px',
                              backgroundColor: '#00C455',
                              border: '1px solid #00C455',
                              margin: '20px 0 20px 10px',
                              cursor: 'pointer',
                              fontFamily: 'Poppins',
                              textTransform: 'capitalize'
                            }} endIcon={<NavigateNext />} onClick={handleNavigation}>Continuar</Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </Bx>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
        {/* </div> */}
      </BrowserView>

      <MobileView>
        <ButtonWhatsapp />
          <Box sx={styleboxfilme}>
            <Stepper active={0} />
            <h3 className="title-sinopse" style={{ color: "white", margin: 2, textAlign: 'center' }}>
              {filme.titulo}
            </h3>
            <Box sx={{ width: "85%" }}>
              <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px", marginTop: '4%' }}>
                Seleccione fecha
              </h4>
              <div className="container-DATAS">
                {datas.map((item) => {
                  return (
                    <button
                      className={
                        selectedDate.data == item.data
                          ? "content-selecione-date-date-Dom-active"
                          : "content-selecione-date-date-Dom"
                      }
                      onClick={() => handleSelectedDate(item)}
                    >
                      <div className="main-content-date-date-Dom">
                        <p
                          className={
                            selectedDate.data == item.data
                              ? "first-p-active"
                              : "first-p"
                          }
                        >
                          {item.dia_da_semana}
                        </p>
                        <p
                          className={
                            selectedDate.data == item.data
                              ? "last-p-active"
                              : "last-p"
                          }
                        >
                          {item.data[0] + "" + item.data[1]}
                        </p>
                        <p
                          className={
                            selectedDate.data == item.data
                              ? "tri-p-active"
                              : "tri-p"
                          }
                        >
                          {item.nome_mes}
                        </p>
                      </div>
                    </button>
                  );
                })}
              </div>

              {selectedDate && (
                <>
                  <div className="container-seccion-carteira">
                    <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                      Seleccione la función
                    </h4>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 0 10px 0",
                        maxWidth: "100%",
                      }}
                    >
                      {horarios.map((item) => {
                        return (
                          <button
                            className={
                              selectedHorario.id == item.id
                                ? "container-secction-button-selected"
                                : "container-secction-button"
                            }
                            onClick={() => handleSelectedHorario(item)}
                          >
                            <p
                              className={
                                selectedHorario.id == item.id
                                  ? "button-first-txt-selected"
                                  : "button-first-txt"
                              }
                            >
                              {item.audio == "leg" ? "Sub" : "Doblada"}
                            </p>
                            <p
                              className={
                                selectedHorario.id == item.id
                                  ? "button-last-txt-selected"
                                  : "button-last-txt"
                              }
                            >
                              {item.horario}
                            </p>
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {selectedHorario.id ? (
                <>
                  <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                    Cantidad
                  </h4>
                  <div className="container-main-igresso-column01" style={{ marginLeft: '10px', marginBottom: '2%' }}>
                    <ButtonGroup>
                      <Button style={{ backgroundColor: "white", color: "black" }}
                        onClick={() => {
                          if (qtdInteiras - 1 > 0)
                            setQtdInteiras(qtdInteiras - 1);
                        }}
                      >
                        -
                      </Button>
                      <Button disable="true" variant="text" style={{ color: "white" }}>{qtdInteiras}</Button>
                      <Button style={{ backgroundColor: "white", color: "black" }}
                        onClick={() => {
                          if (qtdInteiras + 1 <= 10 && (limiteCupon == null || (qtdInteiras + 1) <= limiteCupon))
                            setQtdInteiras(qtdInteiras + 1)
                        }}>
                        +
                      </Button>
                    </ButtonGroup>
                    <p style={{ color: "white", fontFamily: "Poppins" }}>
                      {qtdInteiras}x Butaca Tradicional: <br /> ${" "}
                      {(limiteCupon !== null) ?
                        ('0 (Tu cupón cubre el 100% de las entradas)')
                        : (
                          parseInt(selectedHorario.valor * qtdInteiras).toLocaleString(
                            "pt-br",
                            { currency: "CLP" }
                          ))
                      }
                    </p>
                  </div>
                  {filme.estreia == 0 && (
                    <>
                      <hr style={{ width: "100%", marginBottom: "2%", marginLeft: '10px' }} />

                      <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: '10px' }}>
                        Ingresa aquí tu código promocional
                      </h4>
                      <p style={{ color: "white", fontSize: "0.9rem", marginLeft: '10px', marginBottom: '2%' }}>
                        Aprovecha tu descuento y ven a disfrutar de CINESOL !{" "}
                      </p>
                      <Bx direction="row" style={{ marginLeft: '10px', width: '100%' }}>
                        <BootstrapInput
                          style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                          id="bootstrap-input"
                          value={cupom}
                          placeholder="Digite el código"
                          onChange={handleCupomInfos}
                        />
                        <BootstrapButton
                          variant="contained"
                          onClick={() => inserirCupom()}
                        >
                          Agregar
                        </BootstrapButton>
                      </Bx>
                    </>
                  )}
                  {cupomInfo && (
                    <div style={{
                      marginTop: '1.5%',
                      marginLeft: '10px',
                      paddingBottom: 6,
                      paddingTop: 6,
                      textAlign: 'center',
                      background: "var(--orange)",
                      display: "inline-block",
                      color: "#ffffff",
                      width: "100%",
                      borderRadius: 6
                    }}>
                      <Text>{limiteCupon == null ? cupomInfo.promo.nome : cupomInfo.nome}</Text>
                    </div>
                  )}
                  <hr style={{ width: "100%", marginTop: "4%", marginLeft: '10px' }} />
                  <div className="footer-button" style={{ marginLeft: '10px', paddingBottom: "4%" }}>
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: '#00C455',
                      border: '1px solid #00C455',
                      marginTop: '2%',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize'
                    }} endIcon={<NavigateNext />} onClick={handleNavigation}>Continuar</Button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
      </MobileView>
    </>
  );
};
