import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { useHistory, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import Carousel from "react-elastic-carousel";
import Item from "./Carrousel/ItemEstrenos";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";

// import "./styles.css";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

export const BodyPec = (filmePai) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filme, setFilme] = useState({});
  const [datas, setDatas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [qtdInteiras, setQtdInteiras] = useState(0);
  const [qtdMeias, setQtdMeias] = useState(0);
  const history = useHistory();

  async function getFilme() {
    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      //   "/v1/filme/pesquisar-info/" + params.get("id"),
      "/filme/pesquisar-info/" + filmePai.filme.id
    );

    setFilme(response.data.data);
  }

  async function getDatas() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      //   "/v1/ingresso/pesquisar-datas/" + params.get("id"),
      "/ingresso/pesquisar-datas/" + filmePai.filme.id
    );

    setDatas(response.data.data);
  }

  async function getHorarios(horario) {
    setHorarios([]);

    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      //   "/v1/ingresso/pesquisar-horarios/" +
      //     params.get("id") +
      //     "?data=" +
      //     horario,
      "/v1/ingresso/pesquisar-horarios/" +
        filmePai.filme.id +
        "?data=" +
        horario,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setHorarios(response.data.data);
  }

  useEffect(() => {
    getFilme();
    getDatas();
  }, []);

  useEffect(() => {
    // //(filme);
  }, [filme]);

  //funcao Navigation
  const handleNavigation = () => {
    localStorage.ingresso = JSON.stringify({
      horario: selectedHorario,
      horario_id: selectedHorario.id,
      qtd_meias: qtdMeias,
      qtd_inteiras: qtdInteiras,
      qtd_total: parseInt(qtdMeias) + parseInt(qtdInteiras),
      valor_meias: ((selectedHorario.valor / 2) * qtdMeias).toFixed(2),
      valor_inteiras: (selectedHorario.valor * qtdInteiras).toFixed(2),
      valor_total: (
        (selectedHorario.valor / 2) * qtdMeias +
        selectedHorario.valor * qtdInteiras
      ).toFixed(2),
    });

    let params = new URLSearchParams(window.location.search);
    // history.push("/butacas?id=" + params.get("id"));
    history.push("/butacas?id=50");
  };
  const handleCupon = () => {
    setIsModalVisible(true);
  };

  const handleQtdMeias = (e) => {
    e.preventDefault();
    setQtdMeias(e.target.value);
  };

  const handleQtdInteiras = (e) => {
    e.preventDefault();
    setQtdInteiras(e.target.value);
  };

  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedHorario, setSelectedHorario] = useState(false);

  const handleSelectedDate = (selected) => {
    setSelectedDate(selected);
    setSelectedHorario(false);
    setQtdMeias(0);
    setQtdInteiras(0);
    getHorarios(selected.hora);
  };

  const handleSelectedHorario = (selected) => {
    setSelectedHorario(selected);
    setQtdMeias(0);
    setQtdInteiras(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "5%",
      }}
    >
      <img
        style={{
          width: "18%",
          height: "25%",
          borderRadius: "5%",
        }}
        src={filme.link_img_capa}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "5%",
        }}
      >
        <p style={{ color: "white" }}>Seleccione Fecha</p>
        <div className="container-DATAS">
          {datas.map((item) => {
            return (
              <button
                className={
                  selectedDate.data == item.data
                    ? "content-selecione-date-date-Dom-active"
                    : "content-selecione-date-date-Dom"
                }
                onClick={() => handleSelectedDate(item)}
              >
                <div className="main-content-date-date-Dom">
                  <p
                    className={
                      selectedDate.data == item.data
                        ? "first-p-active"
                        : "first-p"
                    }
                  >
                    {item.dia_da_semana}
                  </p>
                  <p
                    className={
                      selectedDate.data == item.data
                        ? "last-p-active"
                        : "last-p"
                    }
                  >
                    {item.data[0] + "" + item.data[1]}
                  </p>
                  <p
                    className={
                      selectedDate.data == item.data ? "tri-p-active" : "tri-p"
                    }
                  >
                    {item.nome_mes}
                  </p>
                </div>
              </button>
            );
          })}
        </div>

        {selectedDate && (
          <>
            <div className="container-seccion-carteira">
              <h6 style={{ color: "white" }}>Hora / Tipo</h6>

              <div className="container-main-buuton">
                {/** Final Button Seccicion 01*/}
                {horarios.map((item) => {
                  return (
                    <button
                      className={
                        selectedHorario.id == item.id
                          ? "container-secction-button-selected"
                          : "container-secction-button"
                      }
                      onClick={() => handleSelectedHorario(item)}
                    >
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-first-txt-selected"
                            : "button-first-txt"
                        }
                      >
                        {item.audio == "leg" ? "Subtitulada" : "Doblada"}
                      </p>
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-last-txt-selected"
                            : "button-last-txt"
                        }
                      >
                        {item.horario}
                      </p>
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-tri-txt-selected"
                            : "button-tri-txt"
                        }
                      >
                        {item.qualidade}
                      </p>
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-tri-txt-selected"
                            : "button-tri-txt"
                        }
                      >
                        {item.sala.nome}
                      </p>
                    </button>
                  );
                })}
              </div>
            </div>
          </>
        )}

        {selectedHorario.id ? (
          <>
            <h1 style={{ color: "white" }} className="title-ingresso">
              Entrada
            </h1>
            <div className="container-main-igresso">
              <div className="container-main-igresso-column01">
                <input
                  type="number"
                  className="container-main-button-entrantes"
                  min="0"
                  onChange={handleQtdMeias}
                />
                <p style={{ color: "white" }} className="description-igresso">
                  Media Entrada
                </p>
              </div>
            </div>
            <br />
            <div className="container-main-igresso-column01">
              <input
                type="number"
                className="container-main-button-entrantes"
                min="0"
                onChange={handleQtdInteiras}
              />
              <p style={{ color: "white" }} className="description-igresso">
                BUTACA TRADICIONAL
              </p>
            </div>
            <br />
            <div className="footer-button">
              <p style={{ color: "white" }} className="button-first-text">
                {qtdMeias}x Media Entrada:{" "}
                {parseInt((selectedHorario.valor / 2) * qtdMeias)}
              </p>
              <p style={{ color: "white" }} className="button-last-text">
                {qtdInteiras}x BUTACA TRADICIONAL:{" "}
                {parseInt(selectedHorario.valor * qtdInteiras)}
              </p>
              <p style={{ color: "white" }} className="button-tri-text">
                Total:{" "}
                {parseInt(
                  (selectedHorario.valor / 2) * qtdMeias +
                    selectedHorario.valor * qtdInteiras
                )}
              </p>

              {/* <button onClick={handleNavigation} className="button-continue">
                <span>Continuar</span>
              </button> */}
            </div>
          </>
        ) : (
          <></>
        )}
      </Box>

      {selectedHorario.id ? (
        <>
          <Box
            sx={{
              marginLeft: "19%",
              marginTop: "1%",
            }}
          >
            <button onClick={handleNavigation} className="button-continue">
              <span>Continuar</span>
            </button>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export const CarrouselEstrenos = () => {
  const history = useHistory();

  // const usuario = JSON.parse(localStorage.usuario);
  // const [userAdmin, setUserAdmin] = useState(usuario.papel_id === 1);
  const [filmes, setFilmes] = useState([]);

  async function getFilmes() {
    const response = await api.get("filme/pesquisar");

    setFilmes(response.data.data);
  }

  useEffect(() => {
    getFilmes();
    // if (JSON.parse(localStorage.usuario).papel_id == 3) {
    //   history.push("/autenticacion");
    // }
  }, []);

  useEffect(() => {
    // //(filmes);
  }, [filmes]);

  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    // //(width, height);
  }, []);

  //(filmes);
  return (
    <div
      className="App"
      style={{
        height: "10%",
      }}
    >
      <div className="carousel-wrapper">
        <Carousel breakPoints={breakPoints}>
          {filmes?.map((item) => (
            <Link to={"/pelicula-link?id=" + item.id}>
              <Item>
                <div className="container-main-image-listagem">
                  <img
                    src={item.link_img_capa}
                    alt="percy=jackson"
                    className="image-listagem-filmes"
                  />
                  <div
                    className={
                      item.lancamento === 1
                        ? "container-fotter-filmes lancamento"
                        : "container-fotter-filmes"
                    }
                  >
                    <h3 className="container-title-filme">
                      {item.lancamento === 1 ? "Lanzamiento" : "En los cines"}
                    </h3>
                    <div className="horarios">
                      {item.proximos_horarios ? (
                        item.proximos_horarios.map((horario, key) => (
                          <>
                            <p>{horario.horario}</p>
                            {key === 0 && item.proximos_horarios.length > 1 ? (
                              <div className="pipe"></div>
                            ) : (
                              <></>
                            )}
                          </>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Item>
            </Link>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export const BodyRegistro = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {},
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "auto",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const snumber = { color: "orange", fontSize: 13 };
  const s2number = { color: "yellow", fontSize: 13 };

  const srow = {
    display: "flex",
    flexDirection: "row",
    marginBottom: "2%",
  };

  const scolumn = {
    display: "flex",
    flexDirection: "column",
    marginLeft: "2%",
  };

  const [anos, setAnos] = useState([]);
  const [dias, setDias] = useState([]);
  const getDatas = () => {
    const years = [];
    const dia = [];

    for (let i = 0; i < 100; i++) {
      years.push(new Date().getFullYear() - i);
    }

    for (let i = 1; i <= 31; i++) {
      dia.push(i);
    }

    setAnos(years);
    setDias(dia);
  };

  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);
  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    getDatas();
    //(width, height);
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const boxcontainer = {
    display: "flex",
    flexDirection: "row",
    marginLeft: "7%",
  };

  const updateNome = () => {
    let select = document.querySelector("#genero");
    var generovalue = select.options[select.selectedIndex].value;

    let nomeq = document.querySelector("#nome");
    let apelidoq = document.querySelector("#apelido");
    // let generoq = document.querySelector("#genero");
    let rutq = document.querySelector("#rut");
    let diaq = document.querySelector("#dia");
    let mesq = document.querySelector("#mes");
    let anoq = document.querySelector("#ano");
    let celularq = document.querySelector("#celular");
    let emailq = document.querySelector("#email");
    let emailcq = document.querySelector("#emailc");
    let senhaq = document.querySelector("#senha");
    let senhacq = document.querySelector("#senhac");
    let checkemail = document.querySelector("#checkemail");
    let checktermos = document.querySelector("#checktermos");

    let dados = {
      nome: nomeq.value,
      sobrenome: apelidoq.value,
      // genero: generoq.value,
      genero: generovalue,
      rut: rutq.value,
      dia: diaq.value,
      mes: mesq.value,
      ano: anoq.value,
      celular: celularq.value,
      email: emailq.value,
      emailc: emailcq.value,
      password: senhaq.value,
      passwordc: senhacq.value,
      checkemail: checkemail.checked,
      checktermos: checktermos.checked,
      papel_id: 10,
    };

    if (dados.nome) {
      if (dados.sobrenome) {
        if (dados.genero) {
          if (dados.rut) {
            if (dados.dia !== 40) {
              if (dados.mes !== 40) {
                if (dados.ano !== 40) {
                  if (dados.celular) {
                    if (dados.email) {
                      if (dados.emailc && dados.email === dados.emailc) {
                        if (dados.password) {
                          if (
                            dados.passwordc &&
                            dados.password === dados.passwordc
                          ) {
                            if (dados.checktermos === true) {
                              api
                                .post("registrar", dados)
                                .then((response) => {
                                  if (response.data.sucesso === true) {
                                    localStorage.setItem(
                                      "token",
                                      response.data.token
                                    );
                                    localStorage.setItem(
                                      "usuario",
                                      JSON.stringify(response.data.usuario)
                                    );
                                    alert("Conta criada com sucesso");
                                    window.location.reload();
                                  }
                                  if (response.data.sucesso === false) {
                                    alert("Email já em uso, tente outro!");
                                  }
                                })
                                .catch((error) => {
                                  alert("Revise os dados!");
                                });
                            } else {
                              alert(
                                "Por favor acepte los términos de uso para continuar"
                              );
                            }
                          } else {
                            alert("As senhas não sao iguais");
                          }
                        } else {
                          alert("Revise a senha");
                        }
                      } else {
                        alert("Os emails não sao iguais");
                      }
                    } else {
                      alert("Revise o email");
                    }
                  } else {
                    alert("Revise o celular");
                  }
                } else {
                  alert("Revise o nascimento");
                }
              } else {
                alert("Revise o nascimento");
              }
            } else {
              alert("Revise o nascimento");
            }
          } else {
            alert("Revise o rut");
          }
        } else {
          alert("Revise o genero");
        }
      } else {
        alert("Revise o sobrenome");
      }
    } else {
      alert("Revise o nome");
    }
  };

  return (
    <Box sx={{ marginTop: "4%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <h1 style={snumber, { fontSize: 25, color: 'orange', textAlign: 'center', alignItems: 'center' }}> Registrarse </h1> */}
      </div>
      <Box sx={boxcontainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={srow}>
            <h3 style={snumber}> 01. </h3>{" "}
            <h3 style={s2number}> Información personal</h3>
          </Box>

          <Box>
            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>Nombre</h4>
                <BootstrapInput
                  id={"nome"}
                />
              </Box>
              <Box sx={scolumn}>
                <h4 style={snumber}>Apellido</h4>
                <BootstrapInput id={"apelido"} />
              </Box>
            </Box>

            <Box sx={srow}>
              <Box sx={[scolumn, { width: "100%" }]}>
                <h4 style={snumber}>Género</h4>
                {/* <BootstrapInput id={'genero'} /> */}

                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    height: "60%",
                    width: "auto",
                  }}
                >
                  {/* <Select
                  // labelId="demo-simple-select-standard-label"
                  id="genero"
                  // value={age}
                  // onChange={handlese}
                  fullWidth={true}
                >
                  <MenuItem value="">
                    Género
                  </MenuItem>
                  <MenuItem value={'Masculino'}>M</MenuItem>
                  <MenuItem value={'Feminino'}>F</MenuItem>
                </Select> */}

                  <select
                    id={"genero"}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <option value="" selected>
                      Género
                    </option>
                    <option value="Masculino">M</option>
                    <option value="Feminino">F</option>
                  </select>
                </div>
              </Box>

              <Box sx={scolumn}>
                <h4 style={snumber}>RUT</h4>
                <BootstrapInput id={"rut"} />
              </Box>
            </Box>

            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>Fecha de nacimiento</h4>

                <Box sx={srow}>
                  <select id={"dia"}>
                    <option value={40} disabled selected>
                      Dia
                    </option>
                    {dias.map((m) => (
                      <option value={m}>{m}</option>
                    ))}
                  </select>

                  <select style={{ marginLeft: "2%" }} id={"mes"}>
                    <option value={40} disabled selected>
                      Mes
                    </option>
                    <option value="Enero">Enero</option>
                    <option value="Febrero">Febrero</option>
                    <option value="Marzo">Marzo</option>
                    <option value="Abril">Abril</option>
                    <option value="Mayo">Mayo</option>
                    <option value="Junio">Junio</option>
                    <option value="Julio">Julio</option>
                    <option value="Agosto">Agosto</option>
                    <option value="Septiembre">Septiembre</option>
                    <option value="Octubre">Octubre</option>
                    <option value="Novembre">Novembre</option>
                    <option value="Decembre">Decembre</option>
                  </select>

                  <select style={{ marginLeft: "2%" }} id={"ano"}>
                    <option value={40} disabled selected>
                      Año
                    </option>
                    {anos.map((m) => (
                      <option value={m}>{m}</option>
                    ))}
                  </select>
                </Box>
              </Box>
            </Box>

            <Box sx={scolumn}>
              <h4 style={snumber}>Celular</h4>
              <BootstrapInput type={"number"} id={"celular"} />
            </Box>
          </Box>
          <Box sx={{ marginTop: "7%" }}>
            <Box sx={srow}>
              <h3 style={snumber}> 03. </h3>{" "}
              <h3 style={s2number}> Confirmación</h3>
            </Box>

            {/* Li e aceito os termos de Uso da plataforma */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  type="checkbox"
                  id={"checkemail"}
                  // defaultChecked={pcheck}
                  // onChange={() => setPcheck(!pcheck)}
                />
                <h2
                  style={{
                    marginLeft: "1.5%",
                    color: "orange",
                    fontSize: 13,
                    position: "absolute",
                  }}
                >
                  {" "}
                  ¿Te gustaría recibir información de promociones en tu e-mail ?{" "}
                </h2>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "3%",
                }}
              >
                <input
                  type="checkbox"
                  id={"checktermos"}
                  // defaultChecked={scheck}
                  // onChange={() => setScheck(!scheck)}
                />
                <h2
                  style={{
                    marginLeft: "1.5%",
                    color: "orange",
                    fontSize: 13,
                    position: "absolute",
                  }}
                >
                  {" "}
                  Leí y acepto los términos de uso de la plataforma{" "}
                </h2>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "30%",
          }}
        >
          <Box sx={srow}>
            <h3 style={snumber}> 02. </h3>{" "}
            <h3 style={s2number}> E-mail de acesso</h3>
          </Box>

          <Box>
            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>E-mail de contacto</h4>
                <BootstrapInput type={"email"} id={"email"} />
              </Box>
              <Box sx={scolumn}>
                <h4 style={snumber}>Confirmar e-mail</h4>
                <BootstrapInput type={"email"} id={"emailc"} />
              </Box>
            </Box>

            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>Contraseña</h4>
                <BootstrapInput type={"password"} id={"senha"} />
              </Box>
              <Box sx={scolumn}>
                <h4 style={snumber}>Confirmar contraseña</h4>
                <BootstrapInput type={"password"} id={"senhac"} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <Button sx={{ marginBottom: '5%', marginTop: '5%', color: 'white' }} variant="contained" color="success"
        onClick={() => { updateNome() }}>
        Registrarse
      </Button> */}
      </div>
    </Box>
  );
};

export const BodyRegistroT = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {},
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "80%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const BootstrapInputCel = styled(InputBase)(({ theme }) => ({
    "label + &": {},
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "90%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const snumber = { color: "orange", fontSize: 11 };
  const s2number = { color: "yellow", fontSize: 11 };

  const srow = {
    display: "flex",
    flexDirection: "row",
    marginBottom: "2%",
  };

  const scolumn = {
    display: "flex",
    flexDirection: "column",
    marginLeft: "2%",
  };

  const [anos, setAnos] = useState([]);
  const [dias, setDias] = useState([]);
  const getDatas = () => {
    const years = [];
    const dia = [];

    for (let i = 0; i < 100; i++) {
      years.push(new Date().getFullYear() - i);
    }

    for (let i = 1; i <= 31; i++) {
      dia.push(i);
    }

    setAnos(years);
    setDias(dia);
  };

  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);
  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    getDatas();
    //(width, height);
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const boxcontainer = {
    display: "flex",
    flexDirection: "row",
    marginLeft: "3%",
    marginRigtt: "3%",
    alignContent: "center",
    alignItems: "center",
  };

  const updateNome = () => {
    let select = document.querySelector("#genero");
    var generovalue = select.options[select.selectedIndex].value;

    let nomeq = document.querySelector("#nome");
    let apelidoq = document.querySelector("#apelido");
    // let generoq = document.querySelector("#genero");
    let rutq = document.querySelector("#rut");
    let diaq = document.querySelector("#dia");
    let mesq = document.querySelector("#mes");
    let anoq = document.querySelector("#ano");
    let celularq = document.querySelector("#celular");
    let emailq = document.querySelector("#email");
    let emailcq = document.querySelector("#emailc");
    let senhaq = document.querySelector("#senha");
    let senhacq = document.querySelector("#senhac");
    let checkemail = document.querySelector("#checkemail");
    let checktermos = document.querySelector("#checktermos");

    let dados = {
      nome: nomeq.value,
      sobrenome: apelidoq.value,
      // genero: generoq.value,
      genero: generovalue,
      rut: rutq.value,
      dia: diaq.value,
      mes: mesq.value,
      ano: anoq.value,
      celular: celularq.value,
      email: emailq.value,
      emailc: emailcq.value,
      password: senhaq.value,
      passwordc: senhacq.value,
      checkemail: checkemail.checked,
      checktermos: checktermos.checked,
      papel_id: 10,
    };

    api
      .post("registrar", dados)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("usuario", JSON.stringify(response.data.usuario));
      })
      .catch((error) => {
        alert("Revise os dados!");
      });
  };

  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <h1 style={snumber, { fontSize: 25, color: 'orange' }}> Registrarse </h1> */}
      </div>
      <Box sx={boxcontainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={srow}>
            <h3 style={snumber}> 01. </h3>{" "}
            <h3 style={s2number}> Información personal</h3>
          </Box>

          <Box>
            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>Nombre</h4>
                <BootstrapInput id={"nome"} />
              </Box>
              <Box sx={scolumn}>
                <h4 style={snumber}>Apellido</h4>
                <BootstrapInput id={"apelido"} />
              </Box>
            </Box>

            <Box sx={srow}>
              <Box sx={[scolumn, { width: "100%" }]}>
                <h4 style={snumber}>Género</h4>
                {/* <BootstrapInput id={'genero'} /> */}

                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    height: "60%",
                    width: "auto",
                  }}
                >
                  {/* <Select
                  // labelId="demo-simple-select-standard-label"
                  id="genero"
                  // value={age}
                  // onChange={handlese}
                  fullWidth={true}
                >
                  <MenuItem value="">
                    Género
                  </MenuItem>
                  <MenuItem value={'Masculino'}>M</MenuItem>
                  <MenuItem value={'Feminino'}>F</MenuItem>
                </Select> */}

                  <select
                    id={"genero"}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <option value="" selected>
                      Género
                    </option>
                    <option value="Masculino">M</option>
                    <option value="Feminino">F</option>
                  </select>
                </div>
              </Box>
              <Box sx={scolumn}>
                <h4 style={snumber}>RUT</h4>
                <BootstrapInput id={"rut"} />
              </Box>
            </Box>

            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>Fecha de nacimiento</h4>

                <Box sx={srow}>
                  <select id={"dia"}>
                    <option value={40} disabled selected>
                      Dia
                    </option>
                    {dias.map((m) => (
                      <option value={m}>{m}</option>
                    ))}
                  </select>

                  <select style={{ marginLeft: "2%" }} id={"mes"}>
                    <option value={40} disabled selected>
                      Mes
                    </option>
                    <option value="Enero">Enero</option>
                    <option value="Febrero">Febrero</option>
                    <option value="Marzo">Marzo</option>
                    <option value="Abril">Abril</option>
                    <option value="Mayo">Mayo</option>
                    <option value="Junio">Junio</option>
                    <option value="Julio">Julio</option>
                    <option value="Agosto">Agosto</option>
                    <option value="Septiembre">Septiembre</option>
                    <option value="Octubre">Octubre</option>
                    <option value="Novembre">Novembre</option>
                    <option value="Decembre">Decembre</option>
                  </select>

                  <select style={{ marginLeft: "2%" }} id={"ano"}>
                    <option value={40} disabled selected>
                      Año
                    </option>
                    {anos.map((m) => (
                      <option value={m}>{m}</option>
                    ))}
                  </select>
                </Box>
              </Box>
            </Box>

            <Box sx={scolumn}>
              <h4 style={snumber}>Celular</h4>
              <BootstrapInputCel type={"number"} id={"celular"} />
            </Box>
          </Box>

          <Box sx={srow} mt={2}>
            <h3 style={snumber}> 02. </h3>{" "}
            <h3 style={s2number}> E-mail de acesso</h3>
          </Box>

          <Box>
            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>E-mail de contacto</h4>
                <BootstrapInput type={"email"} id={"email"} />
              </Box>
              <Box sx={scolumn}>
                <h4 style={snumber}>Confirmar E-mail</h4>
                <BootstrapInput type={"email"} id={"emailc"} />
              </Box>
            </Box>

            <Box sx={srow}>
              <Box sx={scolumn}>
                <h4 style={snumber}>Contraseña</h4>
                <BootstrapInput type={"password"} id={"senha"} />
              </Box>
              <Box sx={scolumn}>
                <h4 style={snumber}>Confirmar contraseña</h4>
                <BootstrapInput type={"password"} id={"senhac"} />
              </Box>
            </Box>
          </Box>

          <Box sx={{ marginTop: "7%" }}>
            <Box sx={srow}>
              <h3 style={snumber}> 03. </h3>{" "}
              <h3 style={s2number}> Confirmación</h3>
            </Box>

            {/* Li e aceito os termos de Uso da plataforma */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  type="checkbox"
                  id={"checkemail"}
                  // defaultChecked={pcheck}
                  // onChange={() => setPcheck(!pcheck)}
                />
                <h2
                  style={{
                    marginLeft: "4%",
                    color: "orange",
                    fontSize: 11,
                    position: "absolute",
                  }}
                >
                  {" "}
                  ¿Te gustaría recibir información de promociones en tu e-mail ?{" "}
                </h2>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "3%",
                }}
              >
                <input
                  type="checkbox"
                  id={"checktermos"}
                  // defaultChecked={scheck}
                  // onChange={() => setScheck(!scheck)}
                />
                <h2
                  style={{
                    marginLeft: "4%",
                    color: "orange",
                    fontSize: 11,
                    position: "absolute",
                  }}
                >
                  {" "}
                  Leí y acepto los términos de uso de la plataforma{" "}
                </h2>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Button sx={{ marginLeft: larg * 0.34 + 'px', marginBottom: '5%', marginTop: '5%', color: 'white' }} variant="contained" color="success"
        onClick={() => { updateNome() }}>
        Registrarse
      </Button> */}
    </Box>
  );
};

export const BodyCupom = () => {
  return (
    <Box mb={5}>
      <h1
        style={{
          color: "white",
          fontSize: 16,
          marginLeft: "15%",
          marginTop: "2%",
        }}
      >
        {" "}
        Cupons Disponíveis{" "}
      </h1>

      <Box ml={50} mt={5} sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box>
            <div className="d-flex justify-content-center align-items-center container">
              <div className="d-flex card text-center">
                <Box>
                  <div className="image">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <div className="image2">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <h1>20% OFF</h1>
                  <span className="d-block">Cupons black Friday </span>
                  {/* <span class="d-block">Today</span> */}
                  <div className="mt-4">
                    <small>Codigo : s1d1</small>
                  </div>
                </Box>
              </div>
            </div>
          </Box>
          <Box ml={10}>
            <div className="d-flex justify-content-center align-items-center container">
              <div className="d-flex card text-center">
                <Box>
                  <div className="image">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <div className="image2">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <h1>15% OFF</h1>
                  <span className="d-block">Cupons black Friday </span>
                  {/* <span class="d-block">Today</span> */}
                  <div className="mt-4">
                    <small>Codigo : s1d1</small>
                  </div>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
        <Box mt={5} sx={{ display: "flex", flexDirection: "row" }}>
          <Box>
            <div className="d-flex justify-content-center align-items-center container">
              <div className="d-flex card text-center">
                <Box>
                  <div className="image">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <div className="image2">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <h1>10% OFF</h1>
                  <span className="d-block">Cupons black Friday </span>
                  {/* <span class="d-block">Today</span> */}
                  <div className="mt-4">
                    <small>Codigo : s1d1</small>
                  </div>
                </Box>
              </div>
            </div>
          </Box>
          <Box ml={10}>
            <div className="d-flex justify-content-center align-items-center container">
              <div className="d-flex card text-center">
                <Box>
                  <div className="image">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <div className="image2">
                    <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                  </div>
                  <h1>5% OFF</h1>
                  <span className="d-block">Cupons black Friday </span>
                  {/* <span class="d-block">Today</span> */}
                  <div className="mt-4">
                    <small>Codigo : s1d1</small>
                  </div>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const BodyCupomT = () => {
  return (
    <Box mb={5}>
      <h1
        style={{
          color: "white",
          fontSize: 16,
          marginLeft: "15%",
          marginTop: "2%",
        }}
      >
        {" "}
        Cupons Disponíveis{" "}
      </h1>

      <Box
        mt={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box mt={2}>
          <div className="d-flex justify-content-center align-items-center container">
            <div className="d-flex card text-center">
              <Box>
                <div className="image">
                  <img src="https://i.imgur.com/DC94rZe.png" width="50" />
                </div>
                <div className="image2">
                  <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                </div>
                <h1>20% OFF</h1>
                <span className="d-block">Cupons black Friday </span>
                {/* <span class="d-block">Today</span> */}
                <div className="mt-4">
                  <small>Codigo : s1d1</small>
                </div>
              </Box>
            </div>
          </div>
        </Box>
        <Box mt={2}>
          <div className="d-flex justify-content-center align-items-center container">
            <div className="d-flex card text-center">
              <Box>
                <div className="image">
                  <img src="https://i.imgur.com/DC94rZe.png" width="50" />
                </div>
                <div className="image2">
                  <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                </div>
                <h1>20% OFF</h1>
                <span className="d-block">Cupons black Friday </span>
                {/* <span class="d-block">Today</span> */}
                <div className="mt-4">
                  <small>Codigo : s1d1</small>
                </div>
              </Box>
            </div>
          </div>
        </Box>

        <Box mt={2}>
          <div className="d-flex justify-content-center align-items-center container">
            <div className="d-flex card text-center">
              <Box>
                <div className="image">
                  <img src="https://i.imgur.com/DC94rZe.png" width="50" />
                </div>
                <div className="image2">
                  <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                </div>
                <h1>20% OFF</h1>
                <span className="d-block">Cupons black Friday </span>
                {/* <span class="d-block">Today</span> */}
                <div className="mt-4">
                  <small>Codigo : s1d1</small>
                </div>
              </Box>
            </div>
          </div>
        </Box>

        <Box mt={2}>
          <div className="d-flex justify-content-center align-items-center container">
            <div className="d-flex card text-center">
              <Box>
                <div className="image">
                  <img src="https://i.imgur.com/DC94rZe.png" width="50" />
                </div>
                <div className="image2">
                  <img src="https://i.imgur.com/DC94rZe.png" width="150" />
                </div>
                <h1>20% OFF</h1>
                <span className="d-block">Cupons black Friday </span>
                {/* <span class="d-block">Today</span> */}
                <div className="mt-4">
                  <small>Codigo : s1d1</small>
                </div>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
export const BodyPecT = (filmePai) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filme, setFilme] = useState({});
  const [datas, setDatas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [qtdInteiras, setQtdInteiras] = useState(0);
  const [qtdMeias, setQtdMeias] = useState(0);
  const history = useHistory();

  async function getFilme() {
    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      //   "/v1/filme/pesquisar-info/" + params.get("id"),
      "/filme/pesquisar-info/" + filmePai.filme.id
    );

    setFilme(response.data.data);
  }

  async function getDatas() {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      //   "/v1/ingresso/pesquisar-datas/" + params.get("id"),
      "/ingresso/pesquisar-datas/" + filmePai.filme.id
    );

    setDatas(response.data.data);
  }

  async function getHorarios(horario) {
    setHorarios([]);

    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      //   "/v1/ingresso/pesquisar-horarios/" +
      //     params.get("id") +
      //     "?data=" +
      //     horario,
      "/v1/ingresso/pesquisar-horarios/" +
        filmePai.filme.id +
        "?data=" +
        horario,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setHorarios(response.data.data);
  }

  useEffect(() => {
    getFilme();
    getDatas();
  }, []);

  useEffect(() => {
    // //(filme);
  }, [filme]);

  //funcao Navigation
  const handleNavigation = () => {
    localStorage.ingresso = JSON.stringify({
      horario: selectedHorario,
      horario_id: selectedHorario.id,
      qtd_meias: qtdMeias,
      qtd_inteiras: qtdInteiras,
      qtd_total: parseInt(qtdMeias) + parseInt(qtdInteiras),
      valor_meias: ((selectedHorario.valor / 2) * qtdMeias).toFixed(2),
      valor_inteiras: (selectedHorario.valor * qtdInteiras).toFixed(2),
      valor_total: (
        (selectedHorario.valor / 2) * qtdMeias +
        selectedHorario.valor * qtdInteiras
      ).toFixed(2),
    });

    let params = new URLSearchParams(window.location.search);
    // history.push("/butacas?id=" + params.get("id"));
    history.push("/butacas?id=50");
  };
  const handleCupon = () => {
    setIsModalVisible(true);
  };

  const handleQtdMeias = (e) => {
    e.preventDefault();
    setQtdMeias(e.target.value);
  };

  const handleQtdInteiras = (e) => {
    e.preventDefault();
    setQtdInteiras(e.target.value);
  };

  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedHorario, setSelectedHorario] = useState(false);

  const handleSelectedDate = (selected) => {
    setSelectedDate(selected);
    setSelectedHorario(false);
    setQtdMeias(0);
    setQtdInteiras(0);
    getHorarios(selected.hora);
  };

  const handleSelectedHorario = (selected) => {
    setSelectedHorario(selected);
    setQtdMeias(0);
    setQtdInteiras(0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "5%",
      }}
    >
      <img
        style={{
          width: "40%",
          height: "25%",
          borderRadius: "5%",
        }}
        src={filme.link_img_capa}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // marginLeft: "5%",
        }}
      >
        <p style={{ color: "white" }}>Seleccione Fecha</p>
        <div className="container-DATAS">
          {datas.map((item) => {
            return (
              <button
                className={
                  selectedDate.data == item.data
                    ? "content-selecione-date-date-Dom-active"
                    : "content-selecione-date-date-Dom"
                }
                onClick={() => handleSelectedDate(item)}
              >
                <div className="main-content-date-date-Dom">
                  <p
                    className={
                      selectedDate.data == item.data
                        ? "first-p-active"
                        : "first-p"
                    }
                  >
                    {item.dia_da_semana}
                  </p>
                  <p
                    className={
                      selectedDate.data == item.data
                        ? "last-p-active"
                        : "last-p"
                    }
                  >
                    {item.data[0] + "" + item.data[1]}
                  </p>
                  <p
                    className={
                      selectedDate.data == item.data ? "tri-p-active" : "tri-p"
                    }
                  >
                    {item.nome_mes}
                  </p>
                </div>
              </button>
            );
          })}
        </div>

        {selectedDate && (
          <>
            <div className="container-seccion-carteira">
              <h6 style={{ color: "white" }}>Hora / Tipo</h6>

              <div>
                {/** Final Button Seccicion 01*/}
                {horarios.map((item) => {
                  return (
                    <button
                      className={
                        selectedHorario.id == item.id
                          ? "container-secction-button-selected"
                          : "container-secction-button"
                      }
                      onClick={() => handleSelectedHorario(item)}
                    >
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-first-txt-selected"
                            : "button-first-txt"
                        }
                      >
                        {item.audio == "leg" ? "Subtitulada" : "Doblada"}
                      </p>
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-last-txt-selected"
                            : "button-last-txt"
                        }
                      >
                        {item.horario}
                      </p>
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-tri-txt-selected"
                            : "button-tri-txt"
                        }
                      >
                        {item.qualidade}
                      </p>
                      <p
                        className={
                          selectedHorario.id == item.id
                            ? "button-tri-txt-selected"
                            : "button-tri-txt"
                        }
                      >
                        {item.sala.nome}
                      </p>
                    </button>
                  );
                })}
              </div>
            </div>
          </>
        )}

        {selectedHorario.id ? (
          <>
            <h1 style={{ color: "white" }} className="title-ingresso">
              Entrada
            </h1>

            <div className="container-main-igresso">
              <div
                style={{
                  // width: '400px',
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  type="number"
                  className="container-main-button-entrantes"
                  min="0"
                  onChange={handleQtdMeias}
                />
                <p style={{ color: "transparent" }}> a </p>
                <p style={{ color: "white" }}>Media Entrada</p>
              </div>
            </div>

            <br />

            <div
              style={{
                // width: '400px',
                display: "flex",
                flexDirection: "row",
              }}
            >
              <input
                type="number"
                className="container-main-button-entrantes"
                min="0"
                onChange={handleQtdInteiras}
              />
              <p style={{ color: "transparent" }}> a </p>
              <p style={{ color: "white" }}> Entrada Tradicional</p>
            </div>
            <br />

            <div>
              <p
                style={{ color: "white", fontFamily: "Poppins" }}
                className="button-first-text"
              >
                {qtdMeias}x Media Entrada:{" "}
                {parseInt((selectedHorario.valor / 2) * qtdMeias)}
              </p>
              <p
                style={{ color: "white", fontFamily: "Poppins" }}
                className="button-last-text"
              >
                {qtdInteiras}x Entrada Tradicional:{" "}
                {parseInt(selectedHorario.valor * qtdInteiras)}
              </p>
              <p style={{ color: "white" }} className="button-tri-text">
                Total:{" "}
                {parseInt(
                  (selectedHorario.valor / 2) * qtdMeias +
                    selectedHorario.valor * qtdInteiras
                )}
              </p>
              <button
                onClick={handleNavigation}
                style={{
                  width: "50vw",
                  height: "60px",
                  backgroundColor: "#00c455",
                  border: "1px solid #00c455",
                  // margin: 20px 0 0 0,
                  cursor: "pointer",
                }}
              >
                <h3
                  style={{
                    color: "white",
                    fontFamily: "Poppins",
                    fontSize: "12",
                  }}
                >
                  {" "}
                  Continuar{" "}
                </h3>
              </button>
            </div>
          </>
        ) : (
          <></>
        )}
      </Box>

      {/* {selectedHorario.id ? (
        <>
          <Box
            sx={{
              marginLeft: "19%",
              marginTop: "1%",
            }}
          >
            <button onClick={handleNavigation} className="button-continue">
              <span>Continuar</span>
            </button>
          </Box>
        </>
      ) : (
        <></>
      )} */}
    </Box>
  );
};

export const BodyUserData = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {},
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "auto",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

  const snumber = { color: "orange", fontSize: 13 };
  const s2number = { color: "yellow", fontSize: 13 };

  const srow = {
    display: "flex",
    flexDirection: "row",
    marginBottom: "2%",
  };

  const scolumn = {
    display: "flex",
    flexDirection: "column",
    // marginLeft: "2%",
  };

  const [anos, setAnos] = useState([]);
  const [dias, setDias] = useState([]);
  const getDatas = () => {
    const years = [];
    const dia = [];

    for (let i = 0; i < 100; i++) {
      years.push(new Date().getFullYear() - i);
    }

    for (let i = 1; i <= 31; i++) {
      dia.push(i);
    }

    setAnos(years);
    setDias(dia);
  };

  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);
  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    getDatas();
    //(width, height);
  }, []);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const boxcontainer = {
    display: "flex",
    flexDirection: "column",
    // marginLeft: "7%",
    alignContent: "center",
    alignItems: "center",
  };

  const updateNome = () => {
    let nomeq = document.querySelector("#nome");
    let apelidoq = document.querySelector("#apelido");
    let generoq = document.querySelector("#genero");
    let rutq = document.querySelector("#rut");
    let diaq = document.querySelector("#dia");
    let mesq = document.querySelector("#mes");
    let anoq = document.querySelector("#ano");
    let celularq = document.querySelector("#celular");
    let emailq = document.querySelector("#email");
    let emailcq = document.querySelector("#emailc");
    let senhaq = document.querySelector("#senha");
    let senhacq = document.querySelector("#senhac");
    let checkemail = document.querySelector("#checkemail");
    let checktermos = document.querySelector("#checktermos");

    let dados = {
      nome: nomeq.value,
      sobrenome: apelidoq.value,
      genero: generoq.value,
      rut: rutq.value,
      dia: diaq.value,
      mes: mesq.value,
      ano: anoq.value,
      celular: celularq.value,
      email: emailcq.value,
      emailc: emailq.value,
      senha: senhaq.value,
      senhac: senhacq.value,
      checkemail: checkemail.checked,
      checktermos: checktermos.checked,
    };

  };

  return (
    <Box>
      <h1 style={{ fontSize: 25, color: "orange", textAlign: "center" }}>
        Alterar informações
      </h1>

      <Box sx={boxcontainer}>
        <Box sx={srow}>
          <Box sx={scolumn}>
            <h4 style={snumber}>Nombre</h4>
            <BootstrapInput id={"nome"} />
          </Box>
        </Box>

        <Box sx={srow}>
          <Box sx={scolumn}>
            <h4 style={snumber}>E-mail de contacto</h4>
            <BootstrapInput type={"email"} id={"email"} />
          </Box>
        </Box>

        <Box sx={scolumn}>
          <h4 style={snumber}>Contraseña</h4>
          <BootstrapInput type={"password"} id={"senhac"} />
        </Box>

        <Box sx={srow}>
          <Box sx={scolumn}>
            <h4 style={snumber}>Fecha de nacimiento</h4>

            <Box sx={srow}>
              <select id={"dia"}>
                <option value={40} disabled selected>
                  Dia
                </option>
                {dias.map((m) => (
                  <option value={m}>{m}</option>
                ))}
              </select>

              <select style={{ marginLeft: "2%" }} id={"mes"}>
                <option value={40} disabled selected>
                  Mes
                </option>
                <option value="Enero">Enero</option>
                <option value="Febrero">Febrero</option>
                <option value="Marzo">Marzo</option>
                <option value="Abril">Abril</option>
                <option value="Mayo">Mayo</option>
                <option value="Junio">Junio</option>
                <option value="Julio">Julio</option>
                <option value="Agosto">Agosto</option>
                <option value="Septiembre">Septiembre</option>
                <option value="Octubre">Octubre</option>
                <option value="Novembre">Novembre</option>
                <option value="Decembre">Decembre</option>
              </select>

              <select style={{ marginLeft: "2%" }} id={"ano"}>
                <option value={40} disabled selected>
                  Año
                </option>
                {anos.map((m) => (
                  <option value={m}>{m}</option>
                ))}
              </select>
            </Box>
          </Box>
        </Box>

        <Box sx={scolumn}>
          <h4 style={snumber}>Região</h4>
          <BootstrapInput id={"regiao"} />
        </Box>

        {/* <Button sx={{ marginBottom: '5%', marginTop: '5%', color: 'white' }} variant="contained" color="success"
        onClick={() => { updateNome() }}>
        Registrarse
      </Button> */}
      </Box>
    </Box>
  );
};
