import React, { useEffect, useState } from "react";

import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "./styles.css";
import { api } from "../../../services/api";
import { Link, useHistory } from "react-router-dom";
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Bcar = () => {

  const [filmes, setFilmes] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [cupones, setCupones] = useState([]);

  async function getFilmes() {
    const response = await api.get("/filme/pesquisar");
    setFilmes(response.data.data.filme);
    setEventos(response.data.data.eventos);
    setCupones(response.data.data.cupones);
  }

  useEffect(() => {
    getFilmes();
  }, []);

  useEffect(() => {
  }, [filmes]);

  const [altu, setAlt] = useState([]);

  useEffect(() => {
    const { innerHeight: height } = window;
    setAlt(height);
  }, []);
  return (
    <div
      className="App"
      style={{
        height: "30%",
        paddingBottom: '2vh'
      }}
    >
      <p
        style={{
          color: "white",
          fontWeight: 'bold',
          fontSize: "25px",
          marginTop: '2vh',
          marginLeft: "5%",
          fontFamily: 'Poppins'
        }}
      >
        Cartelera
      </p>
      <div className="carousel-wrapper">
        <Carousel breakPoints={breakPoints}>
          {filmes.map((item) => (
            <Link to={"/pelicula?id=" + item.id}>
              <Item key={item} style={{ backgroundColor: 'transparent' }}>
                <img
                  style={{
                    height: altu * 0.65 + "px",
                    width: '68vw',
                    position: "relative",
                    borderRadius: '15px'
                  }}
                  src={item.link_img_capa}
                />
                <div className="tlan">
                  <p style={{ fontSize: '25px', fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'center' }}>
                    {item.lancamento === 1 ? "Lanzamiento" : "En los cines"}
                  </p>

                  <div className="horariosCar">
                    {item.proximos_horarios ? (
                      item.proximos_horarios.map((horario, key) => (
                        <>
                          <p style={{ fontSize: '25px', fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'center', padding: '0px', margin: '0px 10px 0px 10px' }}>{horario.horario}</p>
                          {key === 0 && item.proximos_horarios.length > 1 ? (
                            <div className="pipe"></div>
                          ) : (
                            <></>
                          )}
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Item>
            </Link>
          ))}
        </Carousel>
      </div>

      {eventos.length > 0 ? (
        <div className="carousel-wrapper">
          <Carousel breakPoints={breakPoints}>
            {eventos.map((item) => (
              <Link to={"/eventos/" + item.id}>
                <Item style={{ height: "100%", backgroundColor: 'transparent' }}>
                  <img src={api.defaults.baseURL.slice(0, -4) + "/assets/eventos/" + item.capa}
                    style={{
                      height: altu * 0.65 + "px",
                      width: '68vw',
                      position: "relative",
                      borderRadius: '15px'
                    }}
                  />
                  <div className="tlan">
                    <p style={{ fontSize: '25px', fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'center' }}>
                      {item.titulo}
                    </p>
                  </div>
                </Item>
              </Link>
            ))}
          </Carousel>
        </div>
      ) : (
          <></>
        )}

      {cupones.length > 0 ? (
        <div className="carousel-wrapper">
          <Carousel breakPoints={breakPoints}>
            {cupones.map((item) => (
              <Link to={"/cupon/" + item.id}>
                <Item style={{ height: "100%", backgroundColor: 'transparent' }}>
                  <img src={ api.defaults.baseURL.slice(0, -4) + "/assets/cupones/cupon.png" }
                    style={{
                      height: altu * 0.5 + "px",
                      width: '68vw',
                      position: "relative",
                      borderRadius: '15px'
                    }}
                  />
                  <div className="tlan">
                    <p style={{ fontSize: '25px', fontFamily: 'Poppins', fontWeight: 'bold', textAlign: 'center' }}>
                      {item.nome}
                    </p>
                  </div>
                </Item>
              </Link>
            ))}
          </Carousel>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Bcar;
