import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import "./Stepper.styles.css";


const steps = [
  'Selección',
  'Butacas',
  'Pago',
  'Ticket',
  'Finalizado',
];

export default function StepperCmp({active}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={active} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} style={{ color: 'red' }}>
            <StepLabel><span style={{
                fontFamily: 'Poppins',
                color: 'white'
            }}>{label}</span></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}