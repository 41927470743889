import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Finally.styles.css";
import { api } from "../../services/api";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import background from "../../assets/background.png";
import { Box as Bx } from "grommet";
import { HeaderSemCarrousel } from "../../components/HomeCompo/HomeCompo";
import Button from "@mui/material/Button";
import { ArrowDownward, ArrowBackIos } from '@material-ui/icons';

export const Finally = () => {

  const urlApi = "https://api-cinesol.busticket.cl/";

  const [ticketUrl, setTicketUrl] = useState("");

  const [data, setData] = useState([]);
  const history = useHistory();

  async function getFilme() {

    let params = new URLSearchParams(window.location.search);

    let token = params.get('token_ws');

    api
      .get("/webpay-transbank-confirm/" + token)
      .then((res) => {
        if (res.data.status === 1) {

          setData(res.data.dados);
          setTicketUrl(
            urlApi + "ingresso/" + res.data.venda.vid
          );

          let trans = res.data.dados;

          // confirma pagamento
          api
            .post("webpay-transbank-confirmar-pagamento/" + res.data.venda.venda_id)
            .then((response) => {

            })
            .catch(({ error }) => console.error(error));
          // envia e-mail

          let dataMail = {
            link: urlApi + "ingresso/",
            venda_id: res.data.venda.id,
            transbank: trans,
          };

          api
            .post("/web/mail-send-ticket", dataMail, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .catch(({ error }) => console.error(error));

          swal("PAGO APROBADO", "Click en OK para continuar", "success").then(
            () => {
              let url =
                api.defaults.baseURL.slice(0, -4) +
                "/ingresso/" +
                res.data.venda.vid;

              window.open(url, "_blank");

              setLoading(false);
            }
          );
        } else {
          swal(
            "PAGO NO APROBADO",
            "Lo sentimos su pago no fue aprobado por Transbank. " +
            res.data.motivo ? res.data.motivo : '',
            "error"
          ).then(() => {
            setLoading(false);
          });
        }
      })
      .catch((error) => {
        console.error(error)
        let tittle = 'PAGO NO APROBADO';
        let description = "Lo sentimos su pago no fue aprobado por Transbank. " + error;
        if (error.response.data.message !== undefined && error.response.data.message.search('aborted') !== -1) {
          tittle = 'TRANSACCIÓN ABORTADA';
          description = "Lo sentimos su pago no fue completado."
        }
        swal(
          tittle,
          description,
          "error"
        ).then(() => {
          history.push("/");
        });
      });
  }

  useEffect(() => {
    Swal.fire({
      title: "Verificando Pago:",
      html: "Esperando respuesta de Transbank.",
      timer: 5000,
      timerProgressBar: true,

      didOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {

    });

    getFilme();
  }, []);

  const [loading, setLoading] = useState(true);

  return (
    <Box sx={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
        <HeaderSemCarrousel />

        <Box>
          {loading === false ? (
            <div>
              <div style={{ flexWrap: "wrap" }}>
                <div className="container-pai">
                  <div className="container-pai-filme">
                    <div className="informacoes-detalhes">
                      <div className="content-header">
                        <Bx direction="row" align="center">
                          <h4 style={{ color: "white", fontFamily: 'Poppins' }}>
                            Detalle de su compra
                          </h4>
                        </Bx>

                        <Bx direction="row">
                          <div>
                            <div className="description-first">
                              <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Estado: <span style={{ fontWeight: 'normal' }}>{data.status}</span>
                              </h4>
                            </div>

                            <div className="description-first">
                              <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Valor: <span style={{ fontWeight: 'normal' }}>{data.valor}</span>
                              </h4>
                            </div>

                            <div className="description-first">
                              <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Código de autorización: <span style={{ fontWeight: 'normal' }}>{data.codigo_auth}</span>
                              </h4>
                            </div>

                            <div className="description-first">
                              <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                N° orden de compra: <span style={{ fontWeight: 'normal' }}>{data.buyOrder}</span>
                              </h4>
                            </div>

                            <div className="description-first">
                              <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                Fecha de autorización: <span style={{ fontWeight: 'normal' }}>{data.tdata}</span>
                              </h4>
                            </div>

                            {data.status ===
                              "Aprobado, compra realizada con éxito." ? (
                              <>
                                <div className="description-first">
                                  <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                    Número de tarjeta: <span style={{ fontWeight: 'normal' }}>**** **** **** {data.cardNumber}</span>
                                  </h4>
                                </div>
                                <div className="description-first">
                                  <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                    N° cuotas: <span style={{ fontWeight: 'normal' }}>{data.parcelas}</span>
                                  </h4>
                                </div>{" "}
                              </>
                            ) : (
                              <> </>
                            )}
                          </div>
                        </Bx>
                        <div>
                          {data.codigo_auth !== "000000" && (
                            <a href={ticketUrl} target="_blank">
                              <Button variant="contained" style={{
                                width: '200px',
                                height: '40px',
                                backgroundColor: '#00C455',
                                border: '1px solid #00C455',
                                margin: '20px 0 20px 2px',
                                cursor: 'pointer',
                                fontFamily: 'Poppins',
                                textTransform: 'capitalize'
                              }} endIcon={<ArrowDownward />}>Descargar Ticket</Button>
                            </a>
                          )}
                          <Button variant="contained" style={{
                            width: '200px',
                            height: '40px',
                            backgroundColor: '#CCCCCC',
                            color: '#333333',
                            border: '1px solid #CCCCCC',
                            margin: '20px 0 20px 10px',
                            cursor: 'pointer',
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize'
                          }} startIcon={<ArrowBackIos />} onClick={() => history.push("/")}>Ir a cartelera</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <> </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
