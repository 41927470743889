import React from "react";
export const TimerGlobal = (props) => {

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = React.useRef(null);

    // The state for our timer
    const [timer, setTimer] = React.useState('00:00');


    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        // const hours = Math.floor((total / 1000 * 60 * 60) % 24);
        return {
            total,
            //  hours,
            minutes,
            seconds
        };
    }


    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the begining of the variable
            setTimer(
                // (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }


    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('15:00');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time

        let minutos = 15;
        deadline.setSeconds(deadline.getSeconds() + (minutos * 60));
        return deadline;
    }

    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible

    // We put empty array to act as componentDid
    // mount only
    React.useEffect(() => {
        clearTimer(getDeadTime());
    }, [props.start]);

    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    return (
        <div className="App">

            <h2 style={{ color: 'white', textAlign: 'center' }}>{timer}</h2>
            <p style={{ fontSize: '0.5em'}}> Tiempo restante para completar la compra </p>
            {/* <button onClick={onClickReset}>Reset</button> */}
        </div>
    )
}