import React, { useState } from "react";
import  "./Navbar.styles.css";
import { useLocation, Link } from "react-router-dom";

// componentes
import { InputSearch } from "../Forms/InputSearch";

export  const   Navbar  = () =>{

    const location = useLocation();
    const [pathName,setPathName] = useState(location.pathname);

    const usuario = JSON.parse(localStorage.getItem('usuario'));

    const [papelId, setPapelId] = useState(usuario.papel_id);
    return (
        <header className="container-header">
            <nav className="container-navigation">
                <ul className={papelId ? "contianer-ul" : "container-ul-client"}>
                  {
                      papelId == 1 ?   <>
                      <li className={pathName == '/' || pathName == '/pelicula' || pathName == '/butacas' || pathName == '/pagamento' || pathName == '/billete' || pathName == '/finalizado' ? 'contianer-li active' : 'contianer-li'}> <Link to="/">Cartelera</Link></li>
                      <li className={pathName == '/calendario' ? 'contianer-li active' : 'contianer-li'}> <Link to="/calendario">Calendario</Link></li>
                      <li className={pathName == '/salas' ? 'contianer-li active' : 'contianer-li'}> <Link to="/salas">Salas</Link></li> 
                      <li className={pathName == '/informacoes' ? 'contianer-li active' : 'contianer-li'}> <Link to="/informacoes">Informes</Link></li> 
                      <li className={pathName == '/clientes' ? 'contianer-li active' : 'contianer-li'}> <Link to="/clientes">Clientes</Link></li> 
                      <li className={pathName == '/cupon' ? 'contianer-li active' : 'contianer-li'}> <Link to="/cupon">Promociones</Link></li> 
                      </>
                      :  papelId == 2 ? <>
                      <li className={pathName == '"/"' || pathName == '/pelicula' || pathName == '/butacas' || pathName == '/pagamento' || pathName == '/billete' || pathName == '/finalizado' ? 'contianer-li active' : 'contianer-li'}> <Link to="/">Cartelera</Link></li>
                      <li className={pathName == '/calendario' ? 'contianer-li active' : 'contianer-li'}> <Link to="/calendario">Calendario</Link></li>
                      <li className={pathName == '/informacoes' ? 'contianer-li active' : 'contianer-li'}> <Link to="/informacoes">Informes</Link></li> 
                      <li className={pathName == '/clientes' ? 'contianer-li active' : 'contianer-li'}> <Link to="/clientes">Clientes</Link></li> 
                      </>
                      : <>
                        <li className={pathName == '/autenticacion' ? 'contianer-li active' : 'contianer-li'}> <Link to="/autenticacion">Autenticación</Link></li>
                      </>
                  }

                </ul>
            </nav>
        </header>
    );
};