import React from 'react';
import { useHistory } from 'react-router-dom';
import './Button.styles.css';


export const ButtonSignIn = ({ title }) => {
  const history = useHistory();

  const  handleNavigation=()=> {
      // history.push(""/"");
    }
    return(
        <div className="container-input">
          <button className="container-button-signin" onClick={handleNavigation} style={ {color: 'white'}}>
            {title}
          </button>
        </div>
    )
};