
import { Header, Body } from "../../components/HomeCompo/HomeCompo";

import { HeaderT, BodyT } from "../../components/HomeCompo/HomeCompo";

import { ButtonWhatsapp } from "../../components/ButtonWhatsapp/index";

import "./Home.styles.css";

import {
  BrowserView,
  MobileView,
} from "react-device-detect";

export const Home = () => {

  return (
    <>
      <BrowserView>
        <ButtonWhatsapp />
        <div className="container-home">
          <Header />
          <div className="container-category-filme">
            <Body />
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <ButtonWhatsapp />
        <HeaderT />
        <div className="container-category-filme">
          <BodyT />
        </div>
      </MobileView>
    </>
  );
};
