import { Component } from "react";
import { MobileView } from "react-device-detect";
import { api } from "../../services/api";

export default class FinallyInscriptionApp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    let paramsString = this.props.location.search;
    let paramsCut = paramsString.split('?');
    paramsString = paramsCut[paramsCut.length - 1];

    const regexParams = /([^&=]+)=([^&]*)/g;
    let match;
    const params = {};

    while ((match = regexParams.exec(paramsString)) !== null) {
      params[match[1]] = decodeURIComponent(match[2].replace(/\+/g, ' '));
    }
    this.finishInscription(params);
  }

  finishInscription(data) {
    api.post("/webpay-transbank-inscription-oneclick/finish", data).then((res) => {
      console.log(res);
      this.setState({ loading: false })
    }).catch((error) => {
      console.error(error)
    });
  }

  setUrl() {
    let url = this.urlApp + this.props.location.search;
    this.setState({ url, loading: false })
  }

  redirectApp() {
    window.open(this.state.url);
  }

  render() {
    return (
      <MobileView style={{ height: '100%' }}>
        <center>
          <div style={{
            width: '60%',
            justifyContent: 'center',
            marginTop: '50%',
            fontFamily: 'Poppins',
          }}>
            <h4 style={{ color: 'white' }}>Ahora debes volver a la aplicación de Cinesol</h4>
          </div>
        </center>
      </MobileView>
    );
  }
}
