import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ButtonCalendary.styles.css';
import { ModalAgregarHr } from '../../ModalAgregarHr';

export const ButtonCalendary = ({ title, filmeid }) => {
  const history = useHistory();

  const [ isModalVisible, setIsModalVisible] = useState(false);
  
  const handleCupon = () => {
    
    setIsModalVisible(true)
  };
  
  return(
      <>
      { isModalVisible && < ModalAgregarHr filmeid={filmeid} />}
        <div className="container-input">
          <button className="container-button" onClick={handleCupon}>
            {title}
          </button>
        </div>
      </>
  )
};