import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stepper from "../../components/Stepper";
import { NavigateNext } from '@material-ui/icons';
import { Box as Bx, Layer, Spinner, Text } from "grommet";

import background from "../../assets/background.png";

import { BrowserView, MobileView } from "react-device-detect";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";

import { api } from "../../services/api";
import moment from "moment";
import { EventoAssent } from "../../components/eventos/EventoAssento";
import EventoPagando from "../../components/eventos/EventoPagando";

export const Evento = () => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 14,
      fontFamily: ["Poppins"].join(","),
      width: "auto",
      padding: "6px 8px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
      "::placeholder": {
        fontSize: 14,
        fontFamily: ["Poppins"].join(","),
      },
    },
  }));

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "6px 8px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });
  const [filme, setFilme] = useState({});
  const [datas, setDatas] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [qtdInteiras, setQtdInteiras] = useState(1);
  const [qtdMeias, setQtdMeias] = useState(0);
  const [cupomInfo, setCupomInfo] = useState();
  const [loadingPay, setLP] = useState(false);
  const [url_wp1, setUrl_wp1] = useState("");
  const [token_wp1, setToken_wp1] = useState("");

  const history = useHistory();
  const styleboxfilme = {
    display: "flex",
    marginTop: '4%',
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`,
    height: "110%",
  };

  function Filme(filme) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={
              api.defaults.baseURL.slice(0, -4) +
              "/assets/eventos/" +
              evento?.capa
            }
            style={{ marginTop: "4vh" }}
          />
          <div>
            <h2 className="title-sinopse" style={{ color: "white" }}>
              {evento?.titulo}
            </h2>
          </div>
        </Box>
      </>
    );
  }

  async function getFilme() {
    let params = new URLSearchParams(window.location.search);

    const response = await api.get("/filme/pesquisar-info/" + params.get("id"));

    setFilme(response.data.data);
  }

  async function getDatas(cb) {
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/web/ingresso/pesquisar-datas/" + params.get("id"),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setDatas(response.data.data);

    if (typeof cb != "undefined") {
      cb(response.data.data);
    }
  }

  async function getHorarios(horario, cb) {
    setHorarios([]);

    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);

    const response = await api.get(
      "/web/ingresso/pesquisar-horarios/" +
      params.get("id") +
      "?data=" +
      horario,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    setHorarios(response.data.data);

    if (typeof cb != "undefined") {
      cb(response.data.data);
    }
  }

  async function getDataSelectedLocal(datas) {
    if (localStorage.ingresso) {
      let ingresso = JSON.parse(localStorage.ingresso);

      datas.map((item) => {
        if (item.id == ingresso.horario_id) {
          handleSelectedDate(item, getHorarioSelectedLocal);
        }
      });
    }
  }

  async function getHorarioSelectedLocal(horarios) {
    let ingresso = JSON.parse(localStorage.ingresso);

    horarios.map((item) => {
      if (item.id == ingresso.horario_id) {
        handleSelectedHorario(item);
      }
    });

    let ingressoL = JSON.parse(localStorage.ingresso);
    setQtdInteiras(ingressoL.qtd_inteiras);
    setQtdMeias(ingressoL.qtd_meias);
  }

  useEffect(() => {
    getFilme();
    getDatas(function (datas) {
      getDataSelectedLocal(datas);
    });
  }, []);

  useEffect(() => {
    //(filme);
  }, [filme]);

  //funcao Navigation
  const handleNavigation = () => {
    let valorTotal = 0;
    let valorDesconto = null;
    if (cupomInfo) {
      valorTotal = cupomInfo.valor_total;
      valorDesconto = cupomInfo.valor_desconto;
    } else {
      valorTotal += selectedHorario.valor * qtdInteiras;
    }

    localStorage.ingresso = JSON.stringify({
      horario: selectedHorario,
      horario_id: selectedHorario.id,
      cupom: cupomInfo,
      qtd_meias: qtdMeias,
      qtd_inteiras: qtdInteiras,
      qtd_total: parseInt(qtdMeias) + parseInt(qtdInteiras),
      valor_meias: ((selectedHorario.valor / 2) * qtdMeias).toFixed(2),
      valor_inteiras: (selectedHorario.valor * qtdInteiras).toFixed(2),
      valor_total: valorTotal.toFixed(2),
      valor_desconto: valorDesconto ? valorDesconto.toFixed(2) : null,
    });

    let params = new URLSearchParams(window.location.search);
    history.push("/butacas?id=" + params.get("id"));
  };

  const cupomValorFixo = (cupom) => {
    cupom.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    cupom.valor_desconto = cupom.valor;

    if (cupom.valor_total - cupom.valor_desconto > 0)
      cupom.valor_total = cupom.valor_total - cupom.valor_desconto;
    else cupom.valor_total = 0;

    setCupomInfo(cupom);
  };

  const cupomPorcentagem = (cupom) => {
    cupom.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    cupom.valor_desconto = cupom.valor_total * (cupom.porcentagem / 100);

    cupom.valor_total = cupom.valor_total - cupom.valor_desconto;

    setCupomInfo(cupom);
  };

  const cupomQuantidade = (response) => {
    response.data.data.valor_total =
      parseInt((selectedHorario.valor / 2) * qtdMeias) +
      parseInt(selectedHorario.valor * qtdInteiras);

    if (qtdInteiras > 0) {
      if (response.data.data.tickets_afetados > 0) {
        if (response.data.data.tickets_afetados < qtdInteiras) {
          response.data.data.valor_total -= parseInt(
            selectedHorario.valor * response.data.data.tickets_afetados
          );
          response.data.data.valor_desconto = parseInt(
            selectedHorario.valor * response.data.data.tickets_afetados
          );
        } else {
          response.data.data.valor_total -= parseInt(
            selectedHorario.valor * qtdInteiras
          );
          response.data.data.valor_desconto = parseInt(
            selectedHorario.valor * qtdInteiras
          );
        }
      }
    } else {
      if (response.data.data.tickets_afetados > 0) {
        if (response.data.data.tickets_afetados <= qtdMeias) {
          response.data.data.valor_total -= parseInt(
            (selectedHorario.valor / 2) * response.data.data.tickets_afetados
          );
          response.data.data.valor_desconto = parseInt(
            (selectedHorario.valor / 2) * response.data.data.tickets_afetados
          );
        } else {
          response.data.data.valor_total -= parseInt(
            (selectedHorario.valor * qtdMeias) / 2
          );
          response.data.data.valor_desconto = parseInt(
            (selectedHorario.valor * qtdMeias) / 2
          );
        }
      }
    }
    setCupomInfo(response.data.data);
  };
  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedHorario, setSelectedHorario] = useState(false);

  const handleSelectedDate = (selected, cb) => {
    setSelectedDate(selected);
    setSelectedHorario(false);
    setQtdMeias(0);
    setQtdInteiras(0);
    getHorarios(selected.hora, cb);
  };

  const handleSelectedHorario = (selected) => {
    setSelectedHorario(selected);
    setQtdMeias(0);
    setQtdInteiras(1);
  };

  const [evento, setEvento] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedInfo, setSelcted] = React.useState(null);
  const [loadingTck, setLoadingTck] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState([]);
  const [paying, setPaying] = React.useState(false);
  const [selectSeat, setSelectSeat] = React.useState(false);
  const [opcionNav, setOpcionNav] = React.useState(0);
  const [pasaAssent, setPasaAssent] = React.useState(false);

  const { eventoId } = useParams();

  const getEvento = async () => {
    await setLoading(true);
    api.get("/get/eventos/" + eventoId).then(({ data }) => {
      const unique = [...new Set(data.servicos.map((item) => item.data))];
      let dt = data;
      dt.ListDays = unique;
      setEvento(dt);
      setLoading(false);
    });
  };

  const handleSelectedItem = (item) => {
    setSelcted({ ...selectedInfo, date: item, servico: null });
    // setSelectedService(item);
  };

  const handleSelectedService = (item) => {
    let dt = item;
    dt.n_ticket = [];
    item.precos.forEach((element, index) => {
      dt.n_ticket[index] = 0;
    });

    setSelcted({
      ...selectedInfo,
      servico: dt,
      assentos: [],
    });
  };

  const handleNumbTicket = async (key, add) => {
    await setLoadingTck(true);
    let serv = selectedInfo;
    if (!serv.servico.total) {
      serv.servico.total = 0;
    }

    if (add) {
      serv.servico.n_ticket[key]++;
      serv.servico.total +=
        serv.servico.precos[key].valor * serv.servico.n_ticket[key] -
        serv.servico.precos[key].valor * (serv.servico.n_ticket[key] - 1);
    } else if (serv.servico.n_ticket[key] - 1 >= 0) {
      serv.servico.n_ticket[key]--;
      serv.servico.total +=
        serv.servico.precos[key].valor * serv.servico.n_ticket[key] -
        serv.servico.precos[key].valor * (serv.servico.n_ticket[key] + 1);
    }

    setSelcted(serv);
    setLoadingTck(false);
  };

  const TabOption = () => {
    if (selectSeat === false && paying === false) {
      return (
        <>

          <div className="calendario-direitaaa">
            <div className="conainer-selecione-date">
              <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione fecha</h4>
              <div className="container-DATAS">
                {evento?.ListDays.map((item) => {
                  return (
                    <button
                      className={
                        selectedInfo?.date === item
                          ? "content-selecione-date-date-Dom-active"
                          : "content-selecione-date-date-Dom"
                      }
                      onClick={() => handleSelectedItem(item)}
                    >
                      <div className="main-content-date-date-Dom">
                        <p
                          className={
                            selectedInfo?.date === item
                              ? "first-p-active"
                              : "first-p"
                          }
                        >
                          {moment(item).locale("es").format("ddd")}
                        </p>
                        <p
                          className={
                            selectedInfo?.date === item
                              ? "last-p-active"
                              : "last-p"
                          }
                        >
                          {moment(item).locale("es").format("DD")}
                        </p>
                        <p
                          className={
                            selectedInfo?.date === item
                              ? "tri-p-active"
                              : "tri-p"
                          }
                        >
                          {moment(item).locale("es").format("MMM")}
                        </p>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
            <Bx margin={{ left: "2.5%" }}>
              {selectedInfo !== null && (
                <>
                  <div className="container-seccion-carteira">
                    <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Seleccione la función</h4>

                    <div>
                      {evento.servicos
                        .filter((i) => i.data === selectedInfo.date)
                        .map((item) => {
                          return (
                            <button
                              className={
                                selectedInfo.servico?.id === item.id
                                  ? "container-secction-button-selected"
                                  : "container-secction-button"
                              }
                              style={{ border: '0px' }}
                              onClick={() => {
                                handleSelectedService(item);
                              }}
                            >
                              <p
                                className={
                                  selectedInfo.servico?.id ===
                                    item.id
                                    ? "button-first-txt-selected"
                                    : "button-first-txt"
                                }
                              >
                                {item.sala}
                              </p>

                              <p
                                className={
                                  selectedInfo.servico?.id ===
                                    item.id
                                    ? "button-last-txt-selected"
                                    : "button-last-txt"
                                }
                              >
                                {item.hora}
                              </p>
                            </button>
                          );
                        })}
                    </div>
                  </div>

                  {selectedInfo?.servico !== null && (
                    <>
                      <h4 style={{ color: "white", fontFamily: "Poppins", marginTop: "10px", marginLeft: "10px" }}>Cantidad</h4>

                      <Box gap="small" style={{ marginTop: 5 }}>
                        {selectedInfo.servico.precos.map(
                          (item, index) => {
                            return (
                              <div className="container-main-igresso-column01" style={{ marginLeft: "10px", marginTop: '4px' }}>
                                <ButtonGroup>
                                  <Button style={{ backgroundColor: "white", color: "black" }}
                                    onClick={() => handleNumbTicket(index, false)} >
                                    -
                                  </Button>
                                  <Button disable="true" variant="text" style={{ color: "white" }}>
                                    {loadingTck ? (
                                      <></>
                                    ) : (
                                      selectedInfo.servico.n_ticket[
                                      index
                                      ]
                                    )}
                                  </Button>
                                  <Button style={{ backgroundColor: "white", color: "black" }}
                                    onClick={() =>
                                      handleNumbTicket(index, true)
                                    }
                                  >
                                    +
                                  </Button>
                                </ButtonGroup>
                                <p
                                  style={{ color: "white", fontFamily: "Poppins" }}
                                  className="description-igresso"
                                >
                                  {item.tipo}{" ("}{selectedInfo.servico.n_ticket[index]}
                                  {"x) $" +
                                    (
                                      item.valor *
                                      selectedInfo.servico.n_ticket[
                                      index
                                      ]
                                    ).toLocaleString("pt-br", {
                                      currency: "CLP",
                                    })}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </Box>
                      <br />

                      <hr style={{ width: "75%", marginBottom: "2%" }} />
                      <Button variant="contained" style={{
                        width: '150px',
                        height: '40px',
                        backgroundColor: '#00C455',
                        border: '1px solid #00C455',
                        margin: '20px 0 20px 10px',
                        cursor: 'pointer',
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize'
                      }} endIcon={<NavigateNext />}
                        onClick={() => {
                          if (selectedInfo.servico.tipo === "asiento") {
                            setPasaAssent(true);
                            setSelectSeat(true);
                            setOpcionNav(1);
                          } else {
                            setPaying(true);
                            setOpcionNav(2);
                          }
                        }}>Continuar</Button>
                    </>
                  )}
                </>
              )}
            </Bx>
          </div>
        </>
      );
    } else if (selectSeat === true && paying === false) {
      return (
        <>
          <EventoAssent
            assentos={selectedInfo.servico.assentos}
            key={eventoId}
            setSelected={setSelcted}
            selectedInfo={selectedInfo}
            nextStep={() => {
              setSelectSeat(false);
              setPaying(true);
              setOpcionNav(2);
            }}
            prevStep={() => {
              setSelectSeat(false);
              setOpcionNav(0);
              setPasaAssent(false);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <EventoPagando
            key={eventoId}
            evento={evento}
            selectedInfo={selectedInfo}
            eventoId={eventoId}
            origen={pasaAssent ? 1 : 0}
            handleCancelar={(origen) => {
              setOpcionNav(origen);
              setPaying(false);
              if (origen === 1) {
                setSelectSeat(true);
              }
            }}
            handlePagar={(pagando, url_wp1 = null, token_wp1 = null) => {
              setLP(pagando !== 0);
              if (pagando === 2) {
                setUrl_wp1(url_wp1)
                setToken_wp1(token_wp1)
              }
            }}
          />
        </>
      );
    }
  }

  const TabOptionMovil = () => {
    if (selectSeat === false && paying === false) {
      return (
        <Box sx={{ width: "85%" }}>
          <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
            Seleccione fecha
          </h4>
          <div className="container-DATAS">
            {evento?.ListDays.map((item) => {
              return (
                <button
                  className={
                    selectedInfo?.date === item
                      ? "content-selecione-date-date-Dom-active"
                      : "content-selecione-date-date-Dom"
                  }
                  onClick={() => handleSelectedItem(item)}
                >
                  <div className="main-content-date-date-Dom">
                    <p
                      className={
                        selectedInfo?.date === item
                          ? "first-p-active"
                          : "first-p"
                      }
                    >
                      {moment(item).locale("es").format("ddd")}
                    </p>
                    <p
                      className={
                        selectedInfo?.date === item
                          ? "last-p-active"
                          : "last-p"
                      }
                    >
                      {moment(item).locale("es").format("DD")}
                    </p>
                    <p
                      className={
                        selectedInfo?.date === item
                          ? "tri-p-active"
                          : "tri-p"
                      }
                    >
                      {moment(item).locale("es").format("MMM")}
                    </p>
                  </div>
                </button>
              );
            })}
          </div>
          {selectedInfo && (
            <>
              <div className="container-seccion-carteira">
                <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                  Seleccione la función
                </h4>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0 0 10px 0",
                    maxWidth: "100%",
                  }}
                >
                  {evento.servicos
                    .filter((i) => i.data === selectedInfo.date)
                    .map((item) => {
                      return (
                        <button
                          className={
                            selectedInfo.servico?.id === item.id
                              ? "container-secction-button-selected"
                              : "container-secction-button"
                          }
                          style={{ border: '0px' }}
                          onClick={() => handleSelectedService(item)}
                        >
                          <p
                            className={
                              selectedInfo.servico?.id === item.id
                                ? "button-first-txt-selected"
                                : "button-first-txt"
                            }
                          >
                            {item.sala}
                          </p>
                          <p
                            className={
                              selectedInfo.servico?.id === item.id
                                ? "button-last-txt-selected"
                                : "button-last-txt"
                            }
                          >
                            {item.hora}
                          </p>
                        </button>
                      );
                    })}
                </div>
              </div>

              {selectedInfo?.servico !== null && (
                <>
                  <h4 style={{ fontFamily: "Poppins", color: "white", marginLeft: "10px" }}>
                    Cantidad
                  </h4>
                  <Box gap="small" style={{ marginTop: '2%' }}>
                    {selectedInfo.servico.precos.map(
                      (item, index) => {
                        return (
                          <div className="container-main-igresso-column01" style={{ marginLeft: '10px', marginBottom: '2%' }}>
                            <ButtonGroup>
                              <Button style={{ backgroundColor: "white", color: "black" }}
                                onClick={() => { handleNumbTicket(index, false) }} >
                                -
                              </Button>
                              <Button disable="true" variant="text" style={{ color: "white" }}>
                                {loadingTck ? (
                                  <></>
                                ) : (
                                  selectedInfo.servico.n_ticket[
                                  index
                                  ]
                                )}
                              </Button>
                              <Button style={{ backgroundColor: "white", color: "black" }} onClick={() => handleNumbTicket(index, true)}>
                                +
                              </Button>
                            </ButtonGroup>
                            <p style={{ color: "white", fontFamily: "Poppins" }}>
                              {item.tipo}{" ("}{selectedInfo.servico.n_ticket[index]}
                              {"x) $" +
                                (
                                  item.valor *
                                  selectedInfo.servico.n_ticket[
                                  index
                                  ]
                                ).toLocaleString("pt-br", {
                                  currency: "CLP",
                                })}
                            </p>
                          </div>
                        );
                      }
                    )}
                  </Box>
                  <hr style={{ width: "100%", marginBottom: "2%", marginLeft: '10px' }} />

                  <div className="footer-button" style={{ marginLeft: '10px', paddingBottom: "4%" }}>
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: '#00C455',
                      border: '1px solid #00C455',
                      marginTop: '2%',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize'
                    }} endIcon={<NavigateNext />} onClick={() => {
                      if (selectedInfo.servico.tipo === "asiento") {
                        setPasaAssent(true);
                        setSelectSeat(true);
                        setOpcionNav(1);
                      } else {
                        setPaying(true);
                        setOpcionNav(2);
                      }
                    }}>Continuar</Button>
                  </div>
                </>
              )}
            </>
          )}

        </Box>
      );
    } else if (selectSeat === true && paying === false) {
      return (
        <>
          <EventoAssent
            assentos={selectedInfo.servico.assentos}
            key={eventoId}
            setSelected={setSelcted}
            selectedInfo={selectedInfo}
            movil={true}
            nextStep={() => {
              setSelectSeat(false);
              setPaying(true);
              setOpcionNav(2);
            }}
            prevStep={() => {
              setSelectSeat(false);
              setOpcionNav(0);
              setPasaAssent(false);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <EventoPagando
            key={eventoId}
            evento={evento}
            selectedInfo={selectedInfo}
            eventoId={eventoId}
            origen={pasaAssent ? 1 : 0}
            movil={true}
            handleCancelar={(origen) => {
              setOpcionNav(origen);
              setPaying(false);
              if (origen === 1) {
                setSelectSeat(true);
              }
            }}
            handlePagar={(pagando, url_wp1 = null, token_wp1 = null) => {
              setLP(pagando !== 0);
              if (pagando === 2) {
                setUrl_wp1(url_wp1)
                setToken_wp1(token_wp1)
              }
            }}
          />
        </>
      );
    }
  }

  useEffect(() => {
    getEvento();
  }, []);

  React.useEffect(() => {
    if (url_wp1 && token_wp1) document.formulario.submit();
  }, [url_wp1, token_wp1]);

  if (loading) return <></>;
  return (
    <>
      {loadingPay && (
        <Layer responsive={false} pad="large" background={"transparent"}>
          <Bx
            align="center"
            justify="center"
            pad={"xlarge"}
            background="white"
            style={{ borderRadius: "15px" }}
            gap="large"
          >
            <Text style={{ maxWidth: "400px", textAlign: "center" }}>
              Aguarde, estamos redirecionando a Transbank, favor no actualice la
              página .
            </Text>
            <Spinner size="large" />
          </Bx>
        </Layer>
      )}
      <form method="post" action={url_wp1} name="formulario">
        <input type="hidden" name="token_ws" value={token_wp1} />
      </form>
      <BrowserView>
        <div className="container-capa" style={{ backgroundImage: `url(${background})` }}>
          <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
            <div>
              <div className="container-pai">
                <div className="container-pai-filme">
                  {opcionNav === 0 ? (
                    <div className="detalhes-filme">
                      <img
                        src={
                          api.defaults.baseURL.slice(0, -4) +
                          "/assets/eventos/" +
                          evento?.capa
                        }
                        alt=""
                        className="img-capa"
                      />
                      <div className="infos-filme-carteira">
                        <h2 className="title-sinopse">{evento?.titulo}</h2>
                        <p style={{ color: "black" }}>2019 - Ação | Fantasia</p>
                        {/* <p>Diretor: {.diretor}</p> */}
                        <p>
                          {" "}
                          <AiFillStar className="iconStar" />
                          7.5 (85,741)
                        </p>
                        <h6>
                          We all have a superhero inside us, it just takes a bit
                          of magic to bring it out. In Billy Batson's case, by
                          shouting out one word - SHAZAM. - this streetwise
                          fourteen-year-old foster kid can turn into the grown-up
                          superhero Shazam
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="informacoes-detalhes">
                    <Stepper active={opcionNav} />
                    <TabOption />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div >
      </BrowserView >

      <MobileView>

        <Box sx={styleboxfilme}>
          <Stepper active={opcionNav} />
          {opcionNav === 0 ? (
            <div className="detalhes-filme">
              <Filme filme={filme} />
            </div>
          ) : (
            <></>
          )}
          <TabOptionMovil />
        </Box>
      </MobileView>
    </>
  );
};
