import React, { useEffect, useState, useRef } from "react";
import { Navbar } from '../../components/Navbar';
import Photocapa from "../../assets/photo-capa.png";
import { FaAddressBook } from 'react-icons/fa';
import { BsPencilSquare } from 'react-icons/bs';
import { MdDateRange } from 'react-icons/md';
import { BiRadioCircleMarked} from 'react-icons/bi';
import { User } from "../../components/User";
import { AiTwotoneFlag } from 'react-icons/ai';
import { AiFillEye } from 'react-icons/ai';
import './Autenticacion.styles.css';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';
import Item from "../../screens/Home/item";
import Carousel from "react-elastic-carousel";

import { api } from '../../services/api';

import { ButtonLogout } from "../../components/Forms/ButtonLogout";

const breakPoints = [
    { width: 2, itemsToShow: 1,  }
  ];

export const Autenticacion = () => {

    const [result,setResult] = useState();
    const [horario,setHorario] = useState(false);
    const [status,setStatus] = useState(0);
    const [filmes, setFilmes] = useState([]);
    const [filme, setFilme] = useState(false);
    const [ datas, setDatas] = useState([]);
    const [ horarios, setHorarios] = useState([]);
    const [ ing, setIng] = useState({});
    const [ resMessage, setResMessage] = useState("");
    const [ selectedDate, setSelectedDate ] = useState(false);
    const [ selectedHorario, setSelectedHorario ] = useState(false);

    const history = useHistory();

    let params = new URLSearchParams(window.location.search);

    async  function getDatas(filme_id){
         
        const response = await api.get('/ingresso/pesquisar-datas/' + filme_id)

       setDatas(response.data.data)
    }

    async  function getHorarios(horario){

        setHorarios([]);

        let token = localStorage.token;

        let params = new URLSearchParams(window.location.search);
        
        const response = await api.get('/v1/ingresso/pesquisar-horarios/' + filme.id + '?data=' + horario , {
           headers:{
                Authorization: "Bearer " + token
           }
       })

       setHorarios(response.data.data)
    }

    const handleSelectedDate = (selected) => {
        setSelectedDate(selected);
        setSelectedHorario(false);
        getHorarios(selected.hora)
    }

    const handleSelectedHorario = (selected) => {
        setSelectedHorario(selected);
    }

    async function getFilme(filme_id)
    {

        let token = localStorage.token;

        const response = await api.get('v1/filme/pesquisar-info/' + filme_id, {
           headers:{
                Authorization: "Bearer " + token
           }
        })

       setFilme(response.data.data)
       getDatas(response.data.data.id)
    }

    async  function getFilmes(){

        let token = localStorage.token;       
        const response = await api.get('/filme/pesquisar', {
           headers:{
                Authorization: "Bearer " + token
           }
       })
 
       setFilmes(response.data.data)
    }

    const startQrcode = e => {

        e.preventDefault();
        setHorario(true);
    }

    async function handleScan(data) {

        if (data) {            
            
            setStatus(4);
            let token = localStorage.token;       
            const response = await api.get('v1/ingresso/ler/' + data, {
                headers:{
                     Authorization: "Bearer " + token
                }
            })

            let res = response.data.data;

            setResMessage(res.message);
            setIng(res.ingresso);
      
            if (res.status) {
                setStatus(2);
            } else {
                setStatus(3);
            }
        }
    }

    useEffect( ()=> {
        getFilmes()
 
        if (params.get('id')) {
            getFilme(params.get('id'));
        }
    },  []);

    const handleError = err => {
        console.error(err)
    }

    const lerQrcode = e => {

        e.preventDefault();
        setStatus(1);
        setIng({});
    }


    return (
        <div className="container-home">


            <div className="container-capa">
                <img src={Photocapa} alt="photo-capa" className="photo-capa" />
                <ButtonLogout title="SALIR"/>
                <User />
            </div>
            <Navbar />

           <div className="container-main-pai">
               <h1 className="title-informations" style={{marginBottom: 30}}>Validación</h1>
           </div> 

            {  
                status == 0 ?
                <div className="buttons-autenticar">
                    <button className="btn-autenticar" onClick={lerQrcode}>
                        Leer QR
                    </button>
                </div>
                :
                status == 1 ?
                <div className="qr-area-read">
                    <QrReader
                    className="qr-image-wrapper"
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                    />
                </div>
                :
                status == 2 ?
                <div className="message-autenticar success">
                    <img src="https://api-cinesol.busticket.cl/images/check.png" style={{width: "120px"}} alt="" />
                    <h1>{resMessage}</h1>
                    <button className="btn-autenticar" onClick={lerQrcode}>
                        Leer QR
                    </button>
                    <table className="tbl-ingresso">
                        <tr>
                            <th>Película</th>
                            <td>{ing.horario.filme.titulo}</td>
                        </tr>
                        <tr>
                            <th>Sala</th>
                            <td>{ing.horario.sala.nome}</td>
                        </tr>
                        <tr>
                            <th>Fecha</th>
                            <td>{ing.horario.data}</td>
                        </tr>
                        <tr>
                            <th>Hora</th>
                            <td>{ing.horario.horario}</td>
                        </tr>
                        <tr>
                            <th>Asiento</th>
                            <td>{ing.assento_nome}</td>
                        </tr>
                    </table>
                </div>
                :
                status == 3 ?
                <div className="message-autenticar error">
                    <img src="https://api-cinesol.busticket.cl/images/error.png" style={{width: "120px"}} alt="" />
                    <h1>{resMessage}</h1>
                    <button className="btn-autenticar" onClick={lerQrcode}>
                        Leer QR
                    </button>
                    {
                        ing.id ? 
                        <table className="tbl-ingresso">
                            <tr>
                                <th>Película</th>
                                <td>{ing.horario.filme.titulo}</td>
                            </tr>
                            <tr>
                                <th>Sala</th>
                                <td>{ing.horario.sala.nome}</td>
                            </tr>
                            <tr>
                                <th>Fecha</th>
                                <td>{ing.horario.data}</td>
                            </tr>
                            <tr>
                                <th>Hora</th>
                                <td>{ing.horario.horario}</td>
                            </tr>
                            <tr>
                                <th>Asiento</th>
                                <td>{ing.assento_nome}</td>
                            </tr>
                        </table>
                        :
                        <></>
                    }
                </div>
                :
                <div className="message-autenticar">
                    <h1>Aguarde...</h1>
                </div>
            }
        </div>
    );
};