import React from "react";
import "./ButtonCadastra.styles.css";
import { Link } from "react-router-dom";
export const Buttoncadastro = () => {

  return (<Link className="register-button-cine" to={"/registrar"}>
    <button className="container-button-cadastro" style={{ color: 'white' }}>Registrarse</button>
  </Link>);
};

export const ButtonLogin = () => {
  return (
    <Link  className="register-button-cine" to={"/login"}>
      <button className="container-button-login" style={{ color: 'white' }} >Iniciar sesión</button>
    </Link>
  );
};

export const ButtonIngresso = () => {
  return <button className="container-button-ingresso" style={{ color: 'white' }} >Ingresso</button>;
};

export const ButtonCupom = () => {
  return (
    <Link to={"/cupons-cines"}>
      <button className="container-button-cupom" style={{ color: 'white' }} >VER CUPONS</button>
    </Link>
  );
};

export const ButtonCupomOnly = () => {
  return (
    <Link to={"/cupons-cines"}>
      <button style={{ marginBottom: '2vh', color: 'white' }} className="container-button-cupom-only"   >VER CUPONS</button>
    </Link>
  );
};

export const ButtonLogout = () => {
  return (
    <Link to={"/"}>
      <button onClick={() => { localStorage.clear(); window.location.reload(); }} style={{ color: 'white', marginLeft: '5vw' }} className="container-button-cupom-only"   >Salir</button>
    </Link>
  );
};

export const ButtonLogoutSideBar = () => {
  return (
    <Link to={"/"} >
      <button onClick={() => { localStorage.clear(); window.location.reload(); }} style={{ color: 'white', marginTop: '1vh' }} className="container-button-cupom-only"   >Salir</button>
    </Link>
  );
};
