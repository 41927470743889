/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import rodape from "../../assets/rdp.png";
import backgroundInve from "../../assets/backgroundinverted.png";
import cupom from "../../assets/cupom.svg";
import cupompng from "../../assets/cupompng.png";
import parceiros from "../../assets/parceiros.svg";
import {
  ButtonCupom,
  ButtonLogin,
  Buttoncadastro,
  ButtonIngresso,
  ButtonCupomOnly,
  ButtonLogout,
} from "../Forms/ButtonLogins/index";
import Bcar from "./Carrousel/BodyCarrousel";
import background from "../../assets/background.png";
import logo from "../../assets/logo.svg";
import Carousel from "react-elastic-carousel";
import bannerexample from "../../assets/bannerexample.svg";
import { Link, useHistory } from "react-router-dom";
import "./Home.styles.css";
import { api } from "../../services/api";
import Item from "./item";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import backgroundpng from "../../assets/background.png";
import logopng from "../../assets/logopng.png";

import Avatar from "@mui/material/Avatar";

const breakPoints = [{ width: 1, itemsToShow: 5 }];

function Cf() {
  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Button variant="text" disabled style={{ color: "white" }}>
          CINESOL
        </Button>
        <Button variant="text" style={{ color: "white" }}>
          CINESOL
        </Button>
      </Stack>
    </React.Fragment>
  );
}

export const Footer = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${rodape})`,
        width: "100%",
        height: "380px",
        backgroundPosition: "bottom center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  );
};

export const Header = () => {
  const [banners, setBanners] = useState([]);

  async function getBanners() {
    let token = localStorage.token;
    const response = await api.get("/get-banners", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setBanners(response.data.data);
  }

  useEffect(() => {
    getBanners();
  }, []);

  const [logged, setLogged] = useState(false);
  const [dados, setDados] = useState();


  return (
    <div
      className="container-capa"
      style={{
        backgroundImage: `url(${background})`,
        width: "100%",
        height: "125px",
      }}
    >
      <div>
        <img
          src={logo}
          alt="photo-capa"
          style={{
            height: "50px",
            marginLeft: "100px",
            marginTop: "40px",
          }}
        />
      </div>
    </div>
  );
};

export const HeaderSemCarrousel = () => {

  return (
    <div
      className="container-capa"
      style={{
        width: "100%",
      }}
    >
      <div style={{ flex: 1, flexDirection: "row" }}>
        <img
          src={logo}
          alt="photo-capa"
          style={{
            height: "50px",
            marginLeft: "100px",
            marginTop: "2%",
          }}
        />
      </div>
    </div>
  );
};

export const HeaderCar = () => {
  const [logged, setLogged] = useState(false);
  const [dados, setDados] = useState();

  return (
    <div style={{ flex: 1, flexDirection: "row" }}>
      <Link to={"/"}>
        <img
          src={logo}
          alt="photo-capa"
          style={{
            height: "50px",
            marginLeft: "100px",
            marginTop: "2%",
          }}
        />
      </Link>

      <div style={{ position: "absolute", right: "3%", top: "50px" }}>
        {logged ? (
          <>
            <Stack direction="row" spacing={1}>
              <Avatar>{dados.nome[0]}</Avatar>
              <span style={{ color: "#ffa500", marginTop: "1vh" }}>
                {dados.nome}
              </span>
            </Stack>
            <ButtonLogout />
          </>
        ) : (
          <>
            <Buttoncadastro />
            <ButtonLogin />
          </>
        )}
      </div>
    </div>
  );
};

export const Body = () => {
  const history = useHistory();
  const [filmes, setFilmes] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [cupones, setCupones] = useState([]);

  async function getFilmes() {
    const response = await api.get("/filme/pesquisar/2");
    setFilmes(response.data.data.filme);
    setEventos(response.data.data.eventos);
    setCupones(response.data.data.cupones);
  }

  useEffect(() => {
    getFilmes();
  }, []);
  return (
    <div id="carrosel">
      <br />
      <h1 style={{ color: "white", fontFamily: "Poppins", marginLeft: "3.5%" }}>
        {" "}Cartelera{" "}
      </h1>
      <div className="App">
        <Carousel breakPoints={breakPoints}>
          {filmes?.map((item) => (
            <Link to={"/pelicula?id=" + item.id}>
              <Item>
                <div className="container-main-image-listagem">
                  <img
                    src={item.link_img_capa}
                    alt="percy=jackson"
                    className="image-listagem-filmes"
                  />
                  <div
                    className={
                      item.lancamento === 1
                        ? "container-fotter-filmes lancamento"
                        : "container-fotter-filmes"
                    }
                  >
                    <h3 className="container-title-filme">
                      {item.lancamento === 1 ? "Lanzamiento" : "En los cines"}
                    </h3>
                    <div className="horarios">
                      {item.proximos_horarios ? (
                        item.proximos_horarios.map((horario, key) => (
                          <>
                            <p>{horario.horario}</p>
                            {key === 0 && item.proximos_horarios.length > 1 ? (
                              <div className="pipe"></div>
                            ) : (
                              <></>
                            )}
                          </>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Item>
            </Link>
          ))}
        </Carousel>

        {eventos.length > 0 ? (
          <Carousel breakPoints={breakPoints}>
            {eventos.map((item) => (
              <Link to={"/eventos/" + item.id}>
                <Item>
                  <div className="container-main-image-listagem">
                    <img
                      src={ api.defaults.baseURL.slice(0, -4) + "/assets/eventos/" + item.capa }
                      alt=""
                      className="image-listagem-filmes"
                    />
                    <div className="container-fotter-filmes">
                      <h3 className="container-title-filme">{item.titulo}</h3>
                    </div>
                  </div>
                </Item>
              </Link>
            ))}
          </Carousel>
        ) : (
          <></>
        )}

        {cupones.length > 0 ? (
          <Carousel breakPoints={breakPoints} >
            {cupones.map((item) => (
              <Link to={"/cupon/" + item.id}>
                <Item>
                  <div className="container-main-image-cupon">
                    <img
                      src={ api.defaults.baseURL.slice(0, -4) + "/assets/cupones/cupon.png" }
                      className="image-listagem-filmes"
                    />
                    <div className="container-fotter-filmes">
                      <h3 className="container-title-filme">{item.nome}</h3>
                    </div>
                  </div>
                </Item>
              </Link>
            ))}
          </Carousel>
        ) : (
          <></>
        )}

      </div>
    </div>
  );
};

export const CupomFooter = () => {
  return (
    <div style={{ marginTop: "5%" }}>
      <Stack direction="row">
        <img
          style={{
            height: "10em",
          }}
          src={cupompng}
        />
        <Stack
          style={{ position: "absolute", marginLeft: "13%", marginTop: "2%" }}
          spacing={2}
        >
          <h1
            style={{
              color: "white",

              fontSize: "130%",
            }}
          >
            GARANTA SEUS CUPONS
          </h1>

          <h1
            style={{
              color: "white",

              fontSize: "130%",
            }}
          >
            Conoce todas las promociones y descuontos que tenemos para ti
          </h1>
          <ButtonCupomOnly />
        </Stack>
      </Stack>
    </div>
  );
};

//Celular usando %

function Cft() {
  return (
    <React.Fragment>
      <Stack spacing={0}>
        <Button variant="text" disabled style={{ color: "white" }}>
          CINESOL
        </Button>
        <Button variant="text" style={{ color: "white" }}>
          CINESOL
        </Button>
      </Stack>
    </React.Fragment>
  );
}

function Pft() {
  return (
    <React.Fragment>
      <Stack spacing={0} justifyContent="flex-start" alignItems="flex-start">
        <Button disabled variant="text" style={{ color: "white" }}>
          PROGRAMACION
        </Button>
        <Button variant="text" style={{ color: "white" }}>
          Carteleiras
        </Button>
        <Button variant="text" style={{ color: "white" }}>
          Peliculas
        </Button>
        <Button variant="text" style={{ color: "white" }}>
          Seccion
        </Button>
      </Stack>
    </React.Fragment>
  );
}

export const FooterT = () => {
  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    //(width, height);
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${rodape})`,
        // width: "1000px",
        height: "300px",
        backgroundPosition: "bottom center",
      }}
    >
      <a style={{ color: "transparent" }}>space</a>
    </div>
  );
};

export const ParceirosFooterTSemback = () => {
  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    //(width, height);
  }, []);
  return (
    <div
      className="container-capa"
      style={{
        width: "100%",
        height: "120%",
      }}
    >
      <h1 style={{ fontSize: altu * 0.008 + "px", color: "transparent" }}>a</h1>
      <h1
        style={{
          color: "white",
          marginLeft: larg * 0.05 + "px",
          fontSize: "120%",
          marginTop: larg * 0.05 + "px",
          fontFamily: "Poppins",
        }}
      >
        Parceiros CINESOL
      </h1>

      <img
        style={{
          width: larg * 0.92 + "px",
          marginLeft: larg * 0.05 + "px",
          marginTop: "2vh",
          marginBottom: "2vh",
          // height: "100px",
        }}
        src={parceiros}
      />

      <CupomFooterT />
      <h1 style={{ fontSize: altu * 0.008 + "px", color: "transparent" }}>a</h1>
    </div>
  );
};

export const HeaderT = () => {
  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);

  useEffect(() => {
    getBanners();
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    //(width, height);
  }, []);

  const [banners, setBanners] = useState([]);

  async function getBanners() {
    let token = localStorage.token;
    const response = await api.get("/get-banners", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setBanners(response.data.data);
  }

  return (
    <div
      className="container-capa"
      style={{
        backgroundImage: `url(${backgroundpng})`,
        width: "100%",
        height: "100%",
      }}
    >
      <div>
        <a style={{ color: "transparent" }}> space </a>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <Sidebar /> */}

            <img
              src={logopng}
              alt="photo-capa"
              style={{
                maxWidth: "25vh",
                maxHeight: altu + "px",
                width: "auto",
                height: "auto",
                marginLeft: "5vh",
                marginBottom: "30px",
              }}
            />
          </Box>
        </Box>
      </div>

      {/* <img src={background} alt="photo-capa" className="photo-capa" /> */}
      {/* <User /> */}
    </div>
  );
};

export const HeaderCarT = () => {
  return (
    <div style={{ flex: 1, flexDirection: "row" }}>
      <Link to={"/"}>
        <img
          src={logo}
          alt="photo-capa"
          style={{
            height: "50px",
            marginLeft: "100px",
          }}
        />
      </Link>
      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "white",
          marginTop: "100px",
          marginLeft: "100px",
          fontfamily: "Poppins",
        }}
      // onClick={() => //("oi")}
      >
        CARTELEIRAS
      </button>

      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "white",
          marginTop: "100px",
          marginLeft: "100px",
          fontfamily: "Poppins",
        }}
      // onClick={() => //("oi")}
      >
        PELICULAS
      </button>

      <button
        style={{
          backgroundColor: "transparent",
          border: "none",
          color: "white",
          marginTop: "100px",
          marginLeft: "100px",
          fontfamily: "Poppins",
        }}
      // onClick={() => //("oi")}
      >
        CUPONS
      </button>

      <div style={{ position: "absolute", right: "3%", top: "50px" }}>
        <Buttoncadastro />
        <ButtonLogin />
      </div>
    </div>
  );
};

export const BodyT = () => {
  return <Bcar />;
};

export const CupomFooterT = () => {
  const [larg, setLarg] = useState([]);
  const [altu, setAlt] = useState([]);

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setLarg(width);
    setAlt(height);
    //(width, height);
  }, []);
  return (
    <div style={{ marginTop: "2vh" }}>
      <Stack direction="row">
        <img
          style={{
            height: "3%",
            width: larg * 0.3 + "px",
          }}
          src={cupompng}
        />
        <Stack
          // style={{ position: "absolute", marginLeft: "13%", marginTop: "2%" }}
          spacing={2}
        >
          <h1
            style={{
              color: "white",

              fontSize: "100%",
            }}
          >
            GARANTA SEUS CUPONS
          </h1>

          <h1
            style={{
              color: "white",

              fontSize: "100%",
            }}
          >
            Conoce todas las promociones y descuontos que tenemos para ti
          </h1>
          <ButtonCupomOnly />
        </Stack>
      </Stack>
    </div>
  );
};
