import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import "./Payment.styles.css";
import { api } from "../../services/api";
import Box from "@mui/material/Box";
import background from "../../assets/background.png";
import Switch from "@mui/material/Switch";
import { BrowserView, MobileView } from "react-device-detect";

import { HeaderT } from "../../components/HomeCompo/HomeCompo";
import { Box as Bx, Layer, Spinner, Text } from "grommet";
import Stack from "@mui/material/Stack";
import Stepper from "../../components/Stepper";
import InputBase from "@mui/material/InputBase";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { alpha, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { NavigateNext, ArrowBackIos } from '@material-ui/icons';

// import { validateRUT } from "validar-rut";
// import { validate, format as formatRutVal } from "@validatecl/rut"; ERRO IPHONE
import { ButtonWhatsapp } from "../../components/ButtonWhatsapp/index";

import validator from "validator";
import { Typography } from "@mui/material";

export const Payment = () => {
  const history = useHistory();
  const [filme, setFilme] = useState({});
  const [nome, setNome] = useState("");
  const [apelido, setApelido] = useState("");
  const [email, setEmail] = useState("");
  const [emailC, setEmailC] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("rut");
  const [documento, setDocumento] = useState("");

  const [loadingPay, setLP] = useState(false);

  const [documentoValido, setDocValid] = useState(false);

  const location = useLocation();
  const { request } = location.state || {};

  const IOSSwitch = styled((props) => (
    <Switch
      checked={termos}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const [url_wp, setUrl] = useState("");
  const [token_wp, setToken] = useState("");

  const styleboxfilme = {
    paddingTop: '4%',
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`,
    backgroundColor: 'black',
    height: "110%"
  };

  async function getFilme() {
    let params = new URLSearchParams(window.location.search);

    const response = await api.get("/filme/pesquisar-info/" + params.get("id"));

    setFilme(response.data.data);
  }

  async function handleNavigation(e) {
    e.preventDefault();
    await validarDocumento(documento, false)
    setLP(true);
    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);
    if (!documentoValido) {
      setLP(false);
      alert("Documento inválido.");
    } else if (email !== emailC) {
      setLP(false);
      alert("Email de confirmación no coincide.");
    } else {
      if (request.valor_total > 0 && ((request.cupom == null || request.cupom == undefined) || (request.cupom != null && request.cupom != undefined && request.cupom.tipo_cupon == 1))) {
        let urlapi = "https://webcinesol.busticket.cl/finalizado?id=" + params.get("id") + "/";

        let ingressos = request.ingressos;

        let dataTransbank = {
          amount: parseInt(request.valor_total) + (filme.id !== 253 ? 600 : 0),
          url: urlapi,
          nome: nome,
          email: email,
          sobrenome: apelido,
          documento: documento,
          venda_id: ingressos[0].venda.id,
          venda_valor: ingressos[0].venda.valor,
          filme_id: filme.id
        };

        await api.get("/ingresso/congelar_ingresos/" + dataTransbank.venda_id, {
          headers: {
            Authorization: "Bearer " + token,
          },
        }).then(async (resp) => {
          if (resp.data.success) {
            webpayTransbank(dataTransbank, token)
          } else {
            setLP(false);
            alert(resp.data.message);
            history.push('');
          }
        }).catch((error) => {
          if (!error.response.data.success) {
            setLP(false);
            alert(error.response.data.message);
            history.push('');
          }
          console.error(error)
        })


      } else {

        let dataCliente = {
          nome: nome,
          email: email,
          sobrenome: apelido,
          documento: documento,
          venda_id: request.ingressos[0].venda.id,
          venda_valor: 0,
        };
        await api.post("/web/criar-cliente", dataCliente);

        history.push(`/freeticket/${request.ingressos[0].tid}/${params.get("id")}`);
      }
    }
  }

  async function webpayTransbank(dataTransbank, token) {
    api.post("/webpay-transbank", dataTransbank, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setToken(response.data.token);
        setUrl(response.data.url);
      })
      .catch((error) => {
        alert("Error");
        setLP(false);
      });
  }

  React.useEffect(() => {
    if (url_wp && token_wp) document.formulario.submit();
  }, [url_wp, token_wp]);

  const handleCancelar = (e) => {
    e.preventDefault();

    let ingressosIds = [];

    request.ingressos.map((ingresso) => {
      ingressosIds.push(ingresso.id);
    });

    let token = localStorage.token;

    let params = new URLSearchParams(window.location.search);
    api
      .post(
        "/web/cancelar-ingressos",
        { ingressos: ingressosIds },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        history.push("/pelicula?id=" + params.get("id"));
      })
      .catch(function (response) {
        history.push("/pelicula?id=" + params.get("id"));
      });
  };

  useEffect(() => {
    loadData();
    setLoading(false);
  }, []);


  const loadData = () => {
    if (request) {
      getFilme();
    } else {
      let params = new URLSearchParams(window.location.search);
      history.push("/pelicula?id=" + params.get("id"));
    }
  }

  const [loading, setLoading] = useState(true);

  const [termos, setTermos] = useState(false);

  const TermosCon = () => {
    return (
      <Stack
        direction="row"
        style={{
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <IOSSwitch sx={{ m: 1 }} onChange={() => setTermos(!termos)} />
        <p style={{ color: "white", fontSize: "0.8em" }}>
          Para procesar este pago, declaro que he revisado el DETALLE DE LA
          COMPRA, y que he leído y acepto los Términos y Condiciones, así como
          la Política de Protección de Datos Personales.{" "}
        </p>
      </Stack>
    );
  };

  const srow = {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  };

  const scolumn = {
    display: "flex",
    flexDirection: "column",
    marginRight: "2%",
  };

  const validarDocumento = (e, form = true) => {
    let data = form ? e.target.value : e;
    if (data) {
      if (true
        /* validator.isPassportNumber(e.target.value, "IN") ||
        validateRUT(e.target.value) */
      ) {
        setDocValid(true);
      } else {
        alert("Erro Rut / Pasaporte invalido");
        setDocValid(false);
      }
    } else {
      setDocValid(false);
    }
  };

  return (
    <>
      {loadingPay && (
        <Layer responsive={false} pad="large" background={"transparent"}>
          <Bx
            align="center"
            justify="center"
            pad={"xlarge"}
            background="white"
            style={{ borderRadius: "15px" }}
            gap="large"
          >
            <Text style={{ maxWidth: "400px", textAlign: "center" }}>
              Aguarde, estamos redireccionando a Transbank, favor no actualice la
              página .
            </Text>
            <Spinner size="large" />
          </Bx>
        </Layer>
      )}

      <form method="post" action={url_wp} name="formulario">
        <input type="hidden" name="token_ws" value={token_wp} />
      </form>
      <BrowserView>
        <ButtonWhatsapp />
        <Box sx={{ backgroundImage: `url(${background})` }} >
          {loading === false ? (
            <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }} >
              <div>
                <div style={{ flexWrap: "wrap" }}>
                  <div className="container-pai">
                    <div className="container-pai-filme">
                      <div className="informacoes-detalhes">
                        <Stepper active={2} />

                        <div>
                          <Bx direction="row" align="center">
                            <h4 style={{ color: "white", marginTop: '1rem' }}>
                              Detalle de la compra
                            </h4>
                          </Bx>

                          <Bx direction="row">
                            <div>
                              <div className="description-first">
                                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                  Película: <span style={{ fontWeight: 'normal' }}>{filme.titulo}</span>
                                </h4>
                              </div>
                              <div className="description-first">
                                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                  Fecha función: <span style={{ fontWeight: 'normal' }}>
                                    {request.horario.dia_da_semana}{" "}
                                    {request.horario.data.substr(-20, 2)}{" "}
                                    {request.horario.nome_mes}{" "}
                                    {request.horario.hora.substr(-20, 4)}{" "}
                                    Hora: {request.horario.horario}</span>
                                </h4>
                              </div>

                              <div className="description-first">
                                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                  Entradas: <span style={{ fontWeight: 'normal' }}>
                                    {request.qtd_total}{" "}
                                    {request.qtd_total === 1
                                      ? "entrada"
                                      : "entradas"}{" "}
                                    {request.horario.sala.nome}{" "}
                                    {request.horario.qualidade}
                                  </span>
                                </h4>
                              </div>

                              <div className="description-first">
                                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                  Butacas:
                                </h4>
                                <Stack direction={"row"}>
                                  {request.assentos.map((item) => {
                                    return (
                                      <>
                                        <p
                                          style={{
                                            fontFamily: "Poppins",
                                            color: "white",
                                            padding: "8px",
                                            backgroundColor: "#00C455",
                                            borderRadius: "4px",
                                            width: "auto",
                                            marginRight: "5%",
                                          }}
                                        >
                                          {item.assento_nome}
                                        </p>
                                      </>
                                    );
                                  })}
                                </Stack>
                              </div>
                              {request.cupom !== undefined && request.cupom.tipo_cupon == 1 ? (
                                <div className="description-first">
                                  <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                                    Descuento: <span style={{ fontWeight: 'normal' }}>
                                      ${" "}{request.cupom.valor_desconto}{" (Subtotal $ "}{request.cupom.valor_total}{")"}
                                    </span>
                                  </h4>
                                </div>

                              ) : (
                                <> </>
                              )}
                            </div>

                            <div className="description-first" style={{ marginLeft: 'auto', marginRight: '0px' }}>
                              <Bx
                                border={{
                                  color: "#f26522",
                                  size: "large",
                                  style: "hidden",
                                }}
                                round="medium"
                                background="#f26522"
                                pad="medium"
                                style={{ width: "12vw" }}
                              >
                                <h1
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "22px",
                                  }}
                                >
                                  Total{" "}
                                </h1>

                                <h1
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: "22px",
                                  }}
                                >
                                  ${" "}
                                  {parseInt(request.valor_total) > 0
                                    ? (
                                      parseInt(request.valor_total) + (filme.id !== 253 ? 600 : 0)
                                    ).toLocaleString("pt-br", {
                                      currency: "CLP",
                                    })
                                    : "0"}
                                </h1>

                                {parseInt(request.valor_total) > 0 && filme.id !== 253 && (
                                  <h6
                                    style={{
                                      fontFamily: "Poppins",
                                      color: "white",
                                      textAlign: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Incluye ${" "}
                                    {parseInt(600).toLocaleString("pt-br", {
                                      currency: "CLP",
                                    })}{" "}
                                    de cargo por servicio
                                  </h6>
                                )}
                              </Bx>
                            </div>
                          </Bx>
                          <Bx direction="column" gap="8%" style={{ marginTop: "2%" }} >
                            <h4 style={{ color: "white", fontFamily: 'Poppins' }} >Método de pago disponible{" "}</h4>
                            <Bx height="small">
                              <img
                                src="https://www.transbankdevelopers.cl/public/library/img/svg/logo_webpay_plus.svg"
                                alt=""
                                style={{ width: "20%" }}
                              />
                              <hr size={2} style={{ marginTop: "1%" }} />
                              <h4 style={{ color: "white", fontFamily: 'Poppins' }}> Acepta los términos y condiciones{" "}</h4>
                              <TermosCon />

                              <hr size={2} style={{ marginTop: "1%", marginBottom: "1%" }} />

                              {termos ? (
                                <>
                                  <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
                                    Completa tus datos para continuar{" "}
                                  </h4>
                                  <div>
                                    <Box>
                                      <Box sx={srow}>
                                        <Box sx={scolumn}>
                                          <input
                                            required
                                            id={"nome"}
                                            key={"nome"}
                                            value={nome}
                                            placeholder="Nombre *"
                                            onChange={(e) => setNome(e.target.value.toUpperCase())}
                                          />
                                        </Box>
                                        <Box sx={scolumn}>
                                          <input
                                            required
                                            key={"apelido"}
                                            id={"apelido"}
                                            placeholder="Apellido"
                                            value={apelido}
                                            onChange={(e) => setApelido(e.target.value.toUpperCase())}
                                          />
                                        </Box>
                                      </Box>
                                      <Box sx={srow}>
                                        <Box sx={scolumn}>
                                          <input
                                            required
                                            id={"email"}
                                            type="email"
                                            placeholder="Email *"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value.toUpperCase())}
                                          />
                                        </Box>
                                        <Box sx={scolumn}>
                                          <input
                                            required
                                            id={"email_c"}
                                            type="email"
                                            placeholder="Email confirmación *"
                                            value={emailC}
                                            onChange={(e) => setEmailC(e.target.value.toUpperCase())}
                                          />
                                        </Box>
                                      </Box>

                                      <Box sx={srow}>
                                        <Box sx={{ marginRight: '1.9%' }}>
                                          <RadioGroup row value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)}>
                                            <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Rut</Typography>} style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }} value="rut" control={<Radio style={{ color: 'white' }} />} />
                                            <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Pasaporte</Typography>} value="pasaporte" control={<Radio style={{ color: 'white' }} />} />
                                          </RadioGroup>
                                        </Box>
                                        <Box sx={scolumn}>
                                          <input
                                            required
                                            key={"documento"}
                                            id={"documento"}
                                            placeholder="Documento *"
                                            onBlur={validarDocumento}
                                            value={documento}
                                            onChange={(e) => setDocumento(e.target.value.toUpperCase())}
                                          />
                                        </Box>
                                      </Box>

                                    </Box>
                                  </div>
                                </>
                              ) : (
                                <> </>
                              )}
                              <div>
                                <Button variant="contained" style={{
                                  width: '150px',
                                  height: '40px',
                                  backgroundColor: '#CCCCCC',
                                  color: '#333333',
                                  border: '1px solid #CCCCCC',
                                  margin: '20px 0 20px 2px',
                                  cursor: 'pointer',
                                  fontFamily: 'Poppins',
                                  textTransform: 'capitalize'
                                }} startIcon={<ArrowBackIos />} onClick={handleCancelar}>Volver</Button>
                                {termos ? (
                                  <Button variant="contained" style={{
                                    width: '150px',
                                    height: '40px',
                                    backgroundColor: '#00C455',
                                    border: '1px solid #00C455',
                                    margin: '20px 0 20px 10px',
                                    cursor: 'pointer',
                                    fontFamily: 'Poppins',
                                    textTransform: 'capitalize'
                                  }} endIcon={<NavigateNext />} onClick={handleNavigation}>Pagar</Button>
                                ) : (
                                  <> </>
                                )}
                              </div>
                            </Bx>
                          </Bx>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          ) : (
            <> </>
          )}
        </Box>
      </BrowserView >
      <MobileView>
        <ButtonWhatsapp />
        <Box sx={styleboxfilme}>
          <Stepper active={2} />

          {loading === false ? (
            <Box sx={{ width: '85%', marginTop: '3%' }}>
              <h4 style={{ color: "white", fontFamily: "Poppins" }}>
                Detalle de la compra
              </h4>
              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Película: <span style={{ fontWeight: 'normal' }}>{filme.titulo}</span>
                </h4>
              </div>
              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Fecha función: <span style={{ fontWeight: 'normal' }}>
                    {request.horario.dia_da_semana}{" "}
                    {request.horario.data.substr(-20, 2)}{" "}
                    {request.horario.nome_mes}{" "}
                    {request.horario.hora.substr(-20, 4)}{" "}
                    Hora: {request.horario.horario}</span>
                </h4>
              </div>
              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Entradas: <span style={{ fontWeight: 'normal' }}>
                    {request.qtd_total}{" "}
                    {request.qtd_total === 1
                      ? "entrada"
                      : "entradas"}{" "}
                    {request.horario.sala.nome}{" "}
                    {request.horario.qualidade}
                  </span>
                </h4>
              </div>
              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Butacas:
                </h4>
                <Stack direction={"row"}>
                  {request.assentos.map((item) => {
                    return (
                      <>
                        <p
                          style={{
                            fontFamily: "Poppins",
                            color: "white",
                            padding: "8px",
                            backgroundColor: "#00C455",
                            borderRadius: "4px",
                            width: "auto",
                            marginRight: "5%",
                          }}
                        >
                          {item.assento_nome}
                        </p>
                      </>
                    );
                  })}
                </Stack>
              </div>
              {
                request.cupom !== undefined  && request.cupom.tipo_cupon == 1 ? (
                  <div className="description-first">
                    <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                      Descuento: <span style={{ fontWeight: 'normal' }}>
                        ${" "}{request.cupom.valor_desconto}{" (Subtotal $ "}{request.cupom.valor_total}{")"}
                      </span>
                    </h4>
                  </div>
                ) : (
                  <> </>
                )
              }
              <div className="description-first">
                <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                  Total: <span
                    style={{ fontWeight: 'normal' }}>
                    ${" "}{parseInt(request.valor_total) > 0
                      ? (
                        parseInt(request.valor_total) + (filme.id !== 253 ? 600 : 0)
                      ).toLocaleString("pt-br", {
                        currency: "CLP",
                      })
                      : "0"}

                  </span>
                  {parseInt(request.valor_total) > 0 && filme.id !== 253 && (
                    <span style={{ fontWeight: 'normal' }} >
                      {" "}(Incluye ${" "}
                      {parseInt(600).toLocaleString("pt-br", {
                        currency: "CLP",
                      })}{" "}
                      de cargo por servicio)
                    </span>
                  )}
                </h4>
              </div>
              <Bx direction="column" style={{ marginTop: "2%" }}>
                <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
                  Método de pago disponible{" "}
                </h4>
                <Bx height="small">
                  <img src="https://www.transbankdevelopers.cl/public/library/img/svg/logo_webpay_plus.svg"
                    style={{ width: "50%" }} />
                  <hr size={2} style={{ marginTop: "1%" }} />
                  <h4 style={{ color: "white", fontFamily: "Poppins" }} >
                    Acepta los Términos y condiciones{" "}
                  </h4>
                  <TermosCon />
                  <hr size={2} style={{ marginTop: "1%" }} />
                  {termos ? (
                    <>
                      <h4 style={{ color: "white", fontFamily: 'Poppins' }} >
                        Completa tus datos para continuar{" "}
                      </h4>
                      <input
                        style={{ marginTop: '2%' }}
                        required
                        id={"nome"}
                        key={"nome"}
                        value={nome}
                        placeholder="Nombre *"
                        onChange={(e) => setNome(e.target.value.toUpperCase())}
                      />
                      <input
                        style={{ marginTop: '2%' }}
                        required
                        key={"apelido"}
                        id={"apelido"}
                        placeholder="Apellido"
                        value={apelido}
                        onChange={(e) => setApelido(e.target.value.toUpperCase())}
                      />
                      <input
                        style={{ marginTop: '2%' }}
                        required
                        id={"email"}
                        type="email"
                        placeholder="Email *"
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toUpperCase())}
                      />
                      <input
                        style={{ marginTop: '2%' }}
                        required
                        id={"email_c"}
                        type="email"
                        placeholder="Email confirmación *"
                        value={emailC}
                        onChange={(e) => setEmailC(e.target.value.toUpperCase())}
                      />
                      <RadioGroup row style={{ marginTop: '2%' }} value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)}>
                        <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Rut</Typography>} style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }} value="rut" control={<Radio style={{ color: 'white' }} />} />
                        <FormControlLabel label={<Typography style={{ color: 'white', fontSize: '14px', fontFamily: 'Poppins' }}>Pasaporte</Typography>} value="pasaporte" control={<Radio style={{ color: 'white' }} />} />
                      </RadioGroup>
                      <input
                        style={{ marginTop: '2%' }}
                        required
                        key={"documento"}
                        id={"documento"}
                        placeholder="Documento *"
                        onBlur={validarDocumento}
                        value={documento}
                        onChange={(e) => setDocumento(e.target.value.toUpperCase())}
                      />
                      <hr size={2} style={{ marginTop: "4%", marginBottom: '2%' }} />
                    </>

                  ) : (
                    <> </>
                  )}
                  <div className="footer-button" style={{ paddingBottom: '4%' }}>
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: '#CCCCCC',
                      color: '#333333',
                      border: '1px solid #CCCCCC',
                      marginTop: '2%',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize'
                    }} startIcon={<ArrowBackIos />} onClick={handleCancelar}>Volver</Button>
                    {termos ? (
                      <Button variant="contained" style={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: '#00C455',
                        border: '1px solid #00C455',
                        marginTop: '2%',
                        cursor: 'pointer',
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize'
                      }} endIcon={<NavigateNext />} onClick={handleNavigation}>Pagar</Button>
                    ) : (
                      <> </>
                    )}
                  </div>
                </Bx>
              </Bx>
            </Box>
          ) : (
            <> </>
          )}

        </Box>
      </MobileView>
    </>
  );
};
