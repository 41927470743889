import React, { useState, useEffect } from "react";
import "./Finally.styles.css";
import { api } from "../../services/api";
import swal from "sweetalert";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import background from "../../assets/background.png";
import { Box as Bx } from "grommet";
import { HeaderSemCarrousel } from "../../components/HomeCompo/HomeCompo";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { ArrowDownward, ArrowBackIos } from '@material-ui/icons';

export const CuponFinal = () => {

  const [compra, setCompra] = React.useState([]);
  const history = useHistory();

  const handleVerify = async () => {

    let params = new URLSearchParams(window.location.search);
    let token_ws = params.get('token_ws');

    api.get(`/webpay-transbank-confirmar-pagamento-cupon/${token_ws}`).then(({ data }) => {
      if (data.webpay.status === 1) {
        api.get(`/web/mail-send-cupon/${token_ws}`).catch(({ error }) =>
            swal("CORREO NO ENVIADO", "No se puede enviar o enviar un correo electrónico, contáctenos para obtener más información.", "error"));

        swal("PAGO APROBADO", "", "success").then(
          () => { setLoading(false); });
        setCompra(data);
      } else {
        console.log(data)
        swal( "PAGO NO APROBADO", "Lo sentimos su pago no fue aprobado por Transbank. " + data.webpay.motivo, "error"
        ).then(() => {
          setLoading(false);
          history.push("/");
        });
      }
      setLoading(false);
    })
      .catch((error) => {
        console.log(error)
        let tittle = 'PAGO NO APROBADO';
        let description = "Lo sentimos su pago no fue aprobado por Transbank. ";

        if (error.response.data.message !== undefined && error.response.data.message.search('aborted') !== -1) {
          tittle = 'TRANSACCIÓN ABORTADA';
          description = "Lo sentimos su pago no fue completado."
        } else if (error.response.data.webpay.motivo !== undefined) {
          description = error.response.data.webpay.motivo;
        }
        swal( tittle, description, "error").then(() => {
          history.push("");
        });
      });
  };

  useEffect(() => {
    Swal.fire({
      title: "Verificando Pago:",
      html: "Esperando respuesta de Transbank.",
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
    });

    handleVerify();
  }, []);

  const [loading, setLoading] = useState(true);

  if (loading) return <></>;
  return (
    <Box sx={{ backgroundImage: `url(${background})` }}>
      <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
        <HeaderSemCarrousel />
        <div>
          <div style={{ flexWrap: "wrap" }}>
            <div className="container-pai">
              <div className="container-pai-filme" style={{ marginBottom: 0 }}>
                <div className="informacoes-detalhes">
                  <div className="content-header">
                    <Bx direction="row" align="center">
                      <h4 style={{ color: "white", fontFamily: 'Poppins' }}>
                        Detalle de su compra
                      </h4>
                    </Bx>

                    <Bx direction="row">
                      <div>
                        <div className="description-first">
                          <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                            Estado: <span style={{ fontWeight: 'normal' }}>{compra?.webpay.dados.status}</span>
                          </h4>
                        </div>
                        <div className="description-first">
                          <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                            Valor: <span style={{ fontWeight: 'normal' }}>{compra?.webpay.dados.valor}</span>
                          </h4>
                        </div>
                        <div className="description-first">
                          <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                            Código de autorización: <span style={{ fontWeight: 'normal' }}>{compra?.webpay.dados.codigo_auth}</span>
                          </h4>
                        </div>
                        <div className="description-first">
                          <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                            N° orden de compra: <span style={{ fontWeight: 'normal' }}>{compra?.webpay.dados.buyOrder}</span>
                          </h4>
                        </div>
                        <div className="description-first">
                          <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                            Fecha de autorización: <span style={{ fontWeight: 'normal' }}>{compra?.webpay.dados.tdata}</span>
                          </h4>
                        </div>
                        <div className="description-first">
                          <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                            Número de tarjeta: <span style={{ fontWeight: 'normal' }}>**** **** **** {compra?.webpay.dados.cardNumber}</span>
                          </h4>
                        </div>
                        <div className="description-first">
                          <h4 style={{ fontFamily: "Poppins", color: "white" }}>
                            N° cuotas: <span style={{ fontWeight: 'normal' }}>{compra?.webpay.dados.parcelas}</span>
                          </h4>
                        </div>{" "}
                      </div>
                      <div className="description-first" style={{ marginLeft: 'auto', marginRight: '0px' }}>
                        <Bx border={{ color: "#f26522", size: "large", style: "hidden", }}
                            round="medium" background="#f26522" pad="medium" style={{ width: "18vw" }} >
                            <h3 style={{ fontFamily: "Poppins", color: "white", textAlign: "center", fontSize: "22px", }} >
                                Guarde sus códigos como respaldo{" "}
                            </h3>
                            <br />
                            {compra?.cupones?.map(e => (
                              <div className="text-center m-1">
                                <h3 style={{ color: "white" }}>{e.codigo}</h3>
                              </div>
                            ))}
                            <br />
                            <p style={{ fontFamily: "Poppins", color: "white", textAlign: "center", fontSize: "15px", }} >
                                Sus códigos también han sido enviados al email ingresado en su compra.{" "}
                            </p>
                        </Bx>
                    </div>
                    </Bx>
                    <div>
                      <Button variant="contained" style={{
                        width: '200px',
                        height: '40px',
                        backgroundColor: '#CCCCCC',
                        color: '#333333',
                        border: '1px solid #CCCCCC',
                        margin: '20px 0 20px 10px',
                        cursor: 'pointer',
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize'
                      }} startIcon={<ArrowBackIos />} onClick={() => history.push("/")}>Volver</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};
