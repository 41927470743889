import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Butacas.styles.css";
import userIcon from "../../assets/user-icon.png";
import { api } from "../../services/api";
import Box from "@mui/material/Box";
import background from "../../assets/background.png";
import {
  LoginModalTelefone,
} from "../../screens/SignIn/indexAsModalPc";
import { ButtonWhatsapp } from "../../components/ButtonWhatsapp/index";
import { BrowserView, MobileView } from "react-device-detect";
import Stepper from "../../components/Stepper";

import { HeaderT, FooterT } from "../../components/HomeCompo/HomeCompo";
import { Sidebar } from "primereact/sidebar";
import Button from "@mui/material/Button";
import { NavigateNext, ArrowBackIos } from '@material-ui/icons';

import { TimerGlobal } from "../../components/Timer/timer";
export const Butacas = () => {
  const [visibleFullScreenCelular, setVisibleFullScreenCelular] =
    useState(false);
  const [qtdBlocos, setQtdBlocos] = useState(0);
  const [sala, setSala] = useState({});
  const [filme, setFilme] = useState({});
  const [salaFinal, setSalaFinal] = useState([]);
  const [ingressosSelecionados, setIngressosSelecionados] = useState([]);
  const [letras, setLetras] = useState([
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "y",
    "x",
    "z",
    "a1",
    "b1",
    "c1",
    "d1",
    "e1",
    "f1",
    "j1",
    "k1",
    "l1",
    "m1",
    "n1",
    "o1",
    "p1",
    "q1",
    "r1",
    "s1",
    "t1",
    "u1",
    "v1",
    "w1",
    "y1",
    "x1",
    "z1",
  ]);
  const [reservados, setReservados] = useState([]);
  const history = useHistory();

  const location = useLocation();
  const { request } = location.state || {};

  const styleboxfilme = {
    paddingTop: '4%',
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    backgroundImage: `url(${background})`,
    height: "110%",
  };

  async function getFilme() {
    let params = new URLSearchParams(window.location.search);
    const response = await api.get("/filme/pesquisar-info/" + params.get("id"));
    setFilme(response.data.data);
  }

  const reservarAssentos = function () {
    request.assentos = ingressosSelecionados;
    request['plataforma_id'] = 2;
    api.post("/web/ingresso/reservar-assentos", request)
      .then((response) => {
        let params = new URLSearchParams(window.location.search);

        if (!response.data.data) {
          alert(
            response.data.mensagem
          );
          window.location.reload();
        } else {
          request.ingressos = response.data.data
          history.push("/pago?id=" + params.get("id"), { request });
        }
      })
      .catch(function (response) {
      });
  };

  const cancelarCompra = function () {
    let params = new URLSearchParams(window.location.search);
    history.push("/pelicula?id=" + params.get("id"));
  };

  async function getAssentos() {

    const response = await api.get(
      "/web/ingresso/pesquisar-assentos/" + request.horario_id
    );

    let sala = response.data.data.horario.sala;
    let reservados = response.data.data.reservados;
    setSala(sala);
    setReservados(reservados);
    setQtdBlocos(sala.blocos.length);

    let final = [];

    sala.blocos.map((bloco, k) => {
      final[bloco.id] = [];
      bloco.assentos.map((assento, key) => {
        assento.letra = letras[assento.linha];

        ingressosSelecionados.map((ing) => {
          if (
            ing.linha === assento.linha &&
            ing.coluna === assento.coluna &&
            ing.bloco_id === assento.bloco_id
          ) {
            assento.selecionado = 1;
          }
        });

        if (reservados.indexOf(assento.id) !== -1) {
          assento.ocupado = 1;
        }

        if (typeof final[bloco.id][assento.coluna] == "undefined") {
          final[bloco.id][assento.coluna] = {
            [assento.linha]: assento,
          };
        } else {
          final[bloco.id][assento.coluna][assento.linha] = assento;
        }
      });
    });

    setSalaFinal(final);

  }

  const arrayRemove = function (arr, value) {
    return arr.filter(function (geeks) {
      return geeks != value;
    });
  };

  var ingSelected = [];

  const verificaSelecionado = function (assento) {
    let verify = false;
    ingressosSelecionados.map((ing) => {
      if (
        ing.linha === assento.linha &&
        ing.coluna === assento.coluna &&
        ing.bloco_id === assento.bloco_id
      ) {
        verify = true;
      }
    });
    return verify;
  };

  const selecionarAssento = function (assento, typemodal, force) {
    let qtdIngressos = parseInt(request.qtd_total);
    if (ingressosSelecionados.length < qtdIngressos && assento.ocupado != 1) {
      if (!verificaSelecionado(assento)) {
        assento.selecionado = 1;
        setIngressosSelecionados((prevArray) => [...prevArray, assento]);
      } else {
        assento.selecionado = 0;

        setIngressosSelecionados(arrayRemove(ingressosSelecionados, assento));
      }
    } else if (assento.selecionado == 1) {
      assento.selecionado = 0;

      setIngressosSelecionados(arrayRemove(ingressosSelecionados, assento));
    } else if (typeof force != "undefined" && force) {
      assento.selecionado = 1;
      assento.ocupado = 0;
      ingSelected.push(assento);
    }

    if (force) {
      setIngressosSelecionados(ingSelected);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    if (request) {
      getAssentos();
      getFilme();
    } else {
      cancelarCompra()
    }
  }

  return (
    <>
      <BrowserView>
        <ButtonWhatsapp />
        <Box sx={{ backgroundImage: `url(${background})` }}>
          <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.61)" }}>
            <div>
              <div style={{ flexWrap: "wrap" }}>
                <div className="container-pai">
                  <div className="container-pai-filme">
                    <div className="informacoes-detalhes">
                      <Stepper active={1} />
                      <div className="content-payment">
                        <div className="infos-compra">
                          <h4 className="title-selecione-header">
                            Selección de butacas: {ingressosSelecionados.length}{" "}
                            de {parseInt(request.qtd_total)}{" "}
                          </h4>
                          <h4 className="title-selecione-header">
                            {" "}
                            <TimerGlobal start={true} />{" "}
                          </h4>
                        </div>
                        <p
                          className="subtitle-header"
                          style={{ color: "white" }}
                        >
                          Pantalla
                        </p>
                        <div className="pantalla"></div>
                        <div className="sala">
                          {salaFinal.map((bloco, k) => (
                            <div className="bloco">
                              {bloco.map((linha, key) => (
                                <div className="linha">
                                  {Object.keys(linha).map((assento) => (
                                    <>
                                      {linha[assento].selecionado === 1 && linha[assento].ignorar == 0 ? (
                                        <button
                                          className={
                                            "assento selecionado " +
                                            (qtdBlocos <= 2 ? "x15" : "")
                                          }
                                          onClick={() => {
                                            selecionarAssento(linha[assento], "pc");
                                          }}
                                        >
                                          <p>
                                            {letras[linha[assento].linha] +
                                              linha[assento].assento_nome.substring(1, 3)}
                                          </p>
                                        </button>
                                      ) : linha[assento].ignorar == 0 ? (
                                        <button
                                          className={
                                            linha[assento].ocupado === 1
                                              ? "assento bloqueado " +
                                              (qtdBlocos <= 2 ? "x15" : "")
                                              : "assento " +
                                              (qtdBlocos <= 2 ? "x15" : "")
                                          }
                                          onClick={() => {
                                            selecionarAssento(
                                              linha[assento],
                                              "pc"
                                            );
                                          }}
                                          style={{ fontSize: 8 }}
                                        >
                                          {linha[assento].ocupado === 1 ? (
                                            <img src={userIcon} alt="use" />
                                          ) : (
                                            <p>
                                              {letras[linha[assento].linha] +
                                                linha[
                                                  assento
                                                ].assento_nome.substring(1, 3)}
                                            </p>
                                          )}
                                        </button>
                                      ) : (
                                        <button
                                          className={
                                            "assento" +
                                            (qtdBlocos <= 2 ? "x15" : "")
                                          }
                                          style={{
                                            opacity: 0,
                                            cursor: "default",
                                          }}
                                        >
                                        </button>
                                      )}
                                    </>
                                  ))}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>

                        <div
                          className="flex-colunm"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="display-flex">
                            <div className="assento bloqueado">
                              <img src={userIcon} alt="user icon ocupado" />
                            </div>
                            <span style={{ color: "white", fontSize: '14px' }}>Ocupado</span>
                          </div>
                          <div className="display-flex">
                            <div className="assento selecionado"></div>
                            <span style={{ color: "white", fontSize: '14px' }}>Seleccionado</span>
                          </div>
                        </div>

                        <br />
                        <Button variant="contained" style={{
                          width: '150px',
                          height: '40px',
                          backgroundColor: '#CCCCCC',
                          color: '#333333',
                          border: '1px solid #CCCCCC',
                          margin: '0px 0 20px 10px',
                          cursor: 'pointer',
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize'
                        }} startIcon={<ArrowBackIos />} onClick={cancelarCompra}>Volver</Button>
                        {ingressosSelecionados.length ===
                          parseInt(request.qtd_total) ? (
                          <Button variant="contained" style={{
                            width: '150px',
                            height: '40px',
                            backgroundColor: '#00C455',
                            border: '1px solid #00C455',
                            margin: '0px 0 20px 10px',
                            cursor: 'pointer',
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize'
                          }} endIcon={<NavigateNext />} onClick={reservarAssentos}>Pagar</Button>
                        ) : (
                          <></>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </BrowserView>

      <MobileView>
        <ButtonWhatsapp />
        {visibleFullScreenCelular === true ? (
          <Sidebar
            style={{ backgroundImage: `url(${background})`, height: "100%" }}
            visible={visibleFullScreenCelular}
            fullScreen
            onHide={() => setVisibleFullScreenCelular(false)}
          >
            <LoginModalTelefone
              turnModal={() => setVisibleFullScreenCelular(false)}
            />
          </Sidebar>
        ) : (
          <>
            <Box sx={styleboxfilme}>
              <Stepper active={1} />
              <div style={{ marginTop: '4%' }}>
                <h4 style={{ color: "white", fontFamily: "Poppins" }}>
                  Selección de butacas: {ingressosSelecionados.length} de{" "}
                  {parseInt(request.qtd_total)}
                </h4>
              </div>
              <p className="subtitle-header" style={{ color: "white" }}>Pantalla</p>
              <hr
                style={{
                  width: "90%",
                  border: "6px solid var(--color-dark-grreen)",
                  borderRadius: "10px",
                }}
              />

              <div className="view-butacas">
                <div className="sala" style={{ marginLeft: "2%" }}>
                  {salaFinal.map((bloco, k) => (
                    <div className="bloco">
                      {bloco.map((linha, key) => (
                        <div className="linha">
                          {Object.keys(linha).map((assento) => (
                            <>
                              {linha[assento].selecionado === 1 && linha[assento].ignorar == 0 ? (
                                <button
                                  className={
                                    "assento selecionado " +
                                    (qtdBlocos <= 2 ? "x15" : "")
                                  }
                                  onClick={() => {
                                    selecionarAssento(linha[assento], "pc");
                                  }}
                                >
                                  <p>
                                    {letras[linha[assento].linha] +
                                      linha[assento].assento_nome.substring(1, 3)}
                                  </p>
                                </button>
                              ) : linha[assento].ignorar == 0 ? (
                                <button
                                  className={
                                    linha[assento].ocupado === 1
                                      ? "assento bloqueado " +
                                      (qtdBlocos <= 2 ? "x15" : "")
                                      : "assento " +
                                      (qtdBlocos <= 2 ? "x15" : "")
                                  }
                                  onClick={() => {
                                    selecionarAssento(linha[assento], "pc");
                                  }}
                                  style={{ fontSize: 8 }}
                                >
                                  {linha[assento].ocupado === 1 ? (
                                    <img src={userIcon} alt="use" />
                                  ) : (
                                    <p>
                                      {letras[linha[assento].linha] +
                                        linha[assento].assento_nome.substring(1, 3)}
                                    </p>
                                  )}
                                </button>
                              ) : (
                                <button
                                  className={
                                    "assento " + (qtdBlocos <= 2 ? "x15" : "")
                                  }
                                  style={{ opacity: 0, cursor: "default" }}
                                >
                                </button>
                              )}
                            </>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <Box>
                <div className="flex-colunm">
                  <div className="display-flex">
                    <div className="assento bloqueado">
                      <img src={userIcon} alt="user icon ocupado" />
                    </div>
                    <span style={{ color: "white" }}>Ocupado</span>
                  </div>
                  <div className="display-flex" style={{ marginLeft: "4%" }}>
                    <div className="assento selecionado"></div>
                    <span style={{ color: "white" }}>Seleccionado</span>
                  </div>
                </div>
                <div className="footer-button" style={{ marginLeft: '10px', paddingBottom: "4%" }}>
                  <Button variant="contained" style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: '#CCCCCC',
                    color: '#333333',
                    border: '1px solid #CCCCCC',
                    marginTop: '2%',
                    cursor: 'pointer',
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize'
                  }} startIcon={<ArrowBackIos />} onClick={cancelarCompra}>Volver</Button>
                  {ingressosSelecionados.length ===
                    parseInt(request.qtd_total) ? (
                    <Button variant="contained" style={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: '#00C455',
                      border: '1px solid #00C455',
                      marginTop: '2%',
                      cursor: 'pointer',
                      fontFamily: 'Poppins',
                      textTransform: 'capitalize'
                    }} endIcon={<NavigateNext />} onClick={reservarAssentos}>Pagar</Button>
                  ) : (
                    <></>
                  )}
                </div>
              </Box>

            </Box>
          </>
        )}
      </MobileView>
    </>
  );
};
