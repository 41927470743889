import { useState, useEffect } from "react";
import { HeaderSemCarrousel } from "../../components/HomeCompo/HomeCompo";
import { api } from "../../services/api";
import Box from "@mui/material/Box";
import background from "../../assets/background.png";
import './styles.css'
import { HeaderT } from "../../components/HomeCompo/HomeCompo";
import { BrowserView, MobileView } from 'react-device-detect';
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";

export const RestorePass = () => {

  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "6px 8px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#f26522",
    borderColor: "#f26522",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [passRepeat, setPassRepeat] = useState('');

  async function loadData() {
    let params = new URLSearchParams(window.location.search);
    await setCode(params.get('code'));
    await setEmail(params.get('email'));
  }

  useEffect(() => {
    loadData()
  }, []);

  async function restore() {
    if (pass !== passRepeat) {
      alert('Las contraseñas no coinciden');
      return;
    } else {
      const data = { email, code, pass }
      const resp = await api.post("/restablecer_contrasena", data);

      if (resp.data.success) {
        alert('Contraseña actualizada exitosamente!')
      } else {
        alert(resp.data.msj)
      }
    }

  }

  return (
    <>

      <BrowserView >
        <Box sx={{ backgroundImage: `url(${background})`, height: "100%" }}>
          <HeaderSemCarrousel />

          <Box
            style={{ textAlign: 'center' }}
            sx={{
              marginTop: "2%",
              marginLeft: "7%",
            }}
          >
            <h4 style={{ color: "orange", marginBottom: "2%" }}>Recuperar contraseña</h4>

            <input
              required
              id={"pass"}
              type="password"
              placeholder="Nueva contraseña"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <br />
            <br />
            <input
              required
              id={"passRepeat"}
              type="password"
              placeholder="Repita la contraseña"
              value={passRepeat}
              onChange={(e) => setPassRepeat(e.target.value)}
            />
            <br />
            <br />
            <BootstrapButton
              variant="contained"
              onClick={() => restore()}
            >
              Guardar
            </BootstrapButton>
          </Box>

        </Box>

      </BrowserView >

      <MobileView>
        <Box sx={{ backgroundImage: `url(${background})`, height: "100%" }}>
          <HeaderT />
          <h4 style={{ color: "orange", marginBottom: "2%" }}>Recuperar contraseña</h4>
          <br />
          <br />
          <input
            required
            id={"pass"}
            type="password"
            placeholder="Nueva contraseña"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
          <br />
          <br />
          <input
            required
            id={"passRepeat"}
            type="password"
            placeholder="Repita la contraseña"
            value={passRepeat}
            onChange={(e) => setPassRepeat(e.target.value)}
          />
          <br />
          <br />
          <BootstrapButton
            variant="contained"
            onClick={() => restore()}
          >
            Guardar
          </BootstrapButton>
        </Box>
      </MobileView >
    </>
  );
};




