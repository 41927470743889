import React, { useEffect, useState } from "react";
import { MdMarkunread } from 'react-icons/md';
import { FaAsterisk } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import './InputForm.styles.css';


export const InputForm = () => {
    
    
    const [userData, setUserData] = useState({});

    const handleLogin = (e) => {
        e.preventDefault();

        setUserData({...userData, [e.target.name]: e.target.value})
    }

    return(
        <div className="container-input">
            <input   type="text" name="email" placeholder=" Login" className="Input-forms" onChange={handleLogin} />
            <MdMarkunread  color="#CECECE" className="icon-message"/>
            <input type="password" name="password" placeholder=" Senha" className="Input-forms" onChange={handleLogin} />
            <FaAsterisk  color="#CECECE" className="icon-asteriks"/>
        </div>
    )
};