import React from "react";
import { Navbar } from "../../components/Navbar";
import Photocapa from "../../assets/photo-capa.png";
import { AiFillStar } from "react-icons/ai";
import { ImCheckmark } from "react-icons/im";
import { User } from "../../components/User";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { Box, Button, Text } from "grommet";
function FreeTicket(props) {
  const { tid, filmid } = useParams();
  const [ingresso, setIngresso] = React.useState();
  const [filme, setFilme] = React.useState();
  const [loading, setLoading] = React.useState(true);

  async function getFilme() {

    const response = await api.get("/filme/pesquisar-info/" + filmid);
    setFilme(response.data.data);
  }

  const handleUseEffect = async () => {
    api
      .get(`webpay-transbank-confirmar-pagamento-gratuito/${tid}`)
      .then(({ data }) => {
        setIngresso(data);
        setLoading(false)
      });
  };
  React.useEffect(() => {
    getFilme();
    handleUseEffect();
  }, []);

  return (
    <div style={{ paddingTop: "5%" }}>
      <div
        className="container-pai"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
          backgroundColor: "white", borderRadius: "15px", maxWidth: "100vw", overflow: "hidden", padding: "0 2% 0 2%",
        }}
      >
        {ingresso && !loading && (
          <div className="container-pai-filme">
            <div className="detalhes-filme">
              <img src={filme.link_img_capa} alt="" className="img-capa" />
              <div className="infos-filme-carteira">
                <h2 className="title-sinopse">{filme.titulo}</h2>
                <p>2019 - Ação | Fantasia</p>
                <p>Diretor:</p>
                <p>
                  <AiFillStar className="iconStar" />
                  7.5 (85,741)
                </p>
                <h6>
                  We all have a superhero inside us, it just takes a bit of
                  magic to bring it out. In Billy Batson's case, by shouting out
                  one word - SHAZAM. - this streetwise fourteen-year-old foster
                  kid can turn into the grown-up superhero Shazam
                </h6>
              </div>
            </div>
            <div className="informacoes-detalhes">
              <div className="container-finaly-main">
                <div className="container-finaly">
                  <ImCheckmark size={28} color="#FFFF" />
                </div>
              </div>
              <div className="container-title-finnaly">
                <h1 className="title-finnaly">Compra completada con éxito</h1>
                <div
                  className="tickets-list"
                  style={{ flexDirection: "column" }}
                >
                  <a
                    href={
                      "https://api-cinesol.busticket.cl/ingresso/" +
                      ingresso.venda.vid
                    }
                    target="_blank"
                    without
                    rel="noreferrer"
                  >
                    Imprimir tickets
                  </a>
                </div>

                <Box align="center" margin={{ top: "5%" }}>
                  <Button
                    style={{
                      backgroundColor: "#00c455",
                      padding: "1% 3% 1% 3%",
                      borderRadius: "15px",
                    }}
                    href={"https://webcinesol.busticket.cl/"}
                  >
                    <Text color={"white"} size="1.5rem">
                      Inicio
                    </Text>
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FreeTicket;
